// External Dependencies
import { NumericFormat } from 'react-number-format';
import { forwardRef } from 'react';

// Local Typings
interface Props {
  name: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
}

// Component Definition
const MoneyInput = forwardRef<HTMLInputElement, Props>(
  ({ name, onChange, ...other }: Props, ref): JSX.Element => {
    return (
      <NumericFormat
        {...other}
        decimalScale={2}
        fixedDecimalScale
        // Forward the ref to the input element
        getInputRef={ref}
        onValueChange={(values: any) => {
          onChange({
            target: {
              name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
      />
    );
  },
);

export default MoneyInput;
