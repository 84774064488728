// External Dependencies
import { useMemo } from 'react';
import type {
  DataGridProProps,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';

// Internal Dependencies
import { TableDataGrid } from 'components/shared/index';
import { useGetAllDirectors, useGetAllMembers } from 'gql/queries';
import type { NavigateSearchFn } from 'utils/lib/navigate_search';
import type { PeoplePickerTableResource } from 'state/table/actions';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  canSeeAllMembers?: boolean;
  hideCheckAll?: boolean;
  isRowSelectable?: DataGridProProps['isRowSelectable'];
  onSetSelectedMemberIds: (updatedMemberIds: GridRowSelectionModel) => void;
  onUpdateParams?: NavigateSearchFn;
  params?: string; // Some "picker" tables send their own params
  selectedMemberIds: GridRowSelectionModel;
  tableResource: PeoplePickerTableResource;
}

// Component Definition
const PeoplePickerTable = ({
  canSeeAllMembers = true,
  hideCheckAll,
  isRowSelectable,
  onSetSelectedMemberIds,
  onUpdateParams,
  params,
  selectedMemberIds,
  tableResource,
}: Props): JSX.Element => {
  const {
    data: allMembersData,
    loading: isLoadingMembers,
  } = useGetAllMembers({
    skip: !canSeeAllMembers,
  });

  const {
    data: allDirectorsData,
    loading: isLoadingDirectors,
  } = useGetAllDirectors();

  const columns = useColumns(canSeeAllMembers);

  const isLoading = isLoadingDirectors || isLoadingMembers;

  const rows = useMemo(() => {
    const dataRows = canSeeAllMembers ? allMembersData?.allMembers : allDirectorsData?.allDirectors;

    return dataRows ?? [];
  }, [
    allDirectorsData?.allDirectors,
    allMembersData?.allMembers,
    canSeeAllMembers,
  ]);

  return (
    <TableDataGrid
      checkboxSelection
      columns={columns}
      hideCheckAll={hideCheckAll}
      hideExport
      isRowSelectable={isRowSelectable}
      loading={isLoading}
      onSelectionModelChange={onSetSelectedMemberIds}
      onUpdateParams={onUpdateParams}
      params={params}
      rows={rows}
      selectionModel={selectedMemberIds}
      shouldNavigateOnSearch={false}
      tableResource={tableResource}
      withSearch
    />
  );
};

export default PeoplePickerTable;
