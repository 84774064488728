// External Dependencies
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useApplyAllFinancialCredits = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback(() => {
    return apiClient.v1.financialCredit.applyAll();
  }, []);

  return useTanstackMutation({
    mutationFn,
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};
