// External Dependencies
import { useSelector } from 'react-redux';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import CellphoneWirelessIcon from 'mdi-material-ui/CellphoneWireless';

// Internal Dependencies
import {
  DynamicFormFields,
  EnhancedContainer,
  History,
  Page,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { getFullName } from 'utils';
import { hasFeatureFlag, hasPermission, selfId as selectSelfId } from 'state/self/selectors';
import { tableQueryParams } from 'state/table/selectors';
import { useGetAdult, useIsFamilyMember } from 'gql/queries';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import ActivityCard from '../../shared/ActivityCard';
import BouncedEmailAlert from '../../shared/BouncedEmailAlert';
import FeesCard from '../../shared/FeesCard';
import GroupsCard from '../../shared/GroupsCard';
import PermissionsCard from '../../shared/PermissionsCard';
import RelationshipsCard from '../../shared/RelationshipsCard';
import ShowAdultProfileData from '../../shared/ShowAdultProfileData';
import ShowUserContactData from '../../shared/ShowUserContactData';

// Component Definition
const ParentsShow = (): JSX.Element => {
  const { id } = useParamsWithId();

  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const parentsParams = useSelector(tableQueryParams('parents'));
  const selfId = useSelector(selectSelfId);
  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));

  const isViewingSelf = selfId === id;

  const isFamilyMember = useIsFamilyMember(id ?? '');

  const canEditFamilyMemberOrSelf = isFamilyMember || isViewingSelf;

  const { data, error, loading } = useGetAdult(id);

  const hasBouncedEmail = Boolean(data?.user?.bouncedEmail?.id);

  return (
    <Page
      backButtonProps={{
        label: canEditUsers ? 'Parents' : 'Profile',
        path: canEditUsers ? `/${PATHS.PARENTS}${parentsParams}` : `/${PATHS.PROFILE}`,
      }}
      editButtonPath={(canEditUsers || canEditFamilyMemberOrSelf)
        ? `/${PATHS.PARENTS}/${id}/edit`
        : ''}
      error={error}
      isLoading={loading}
      notFound={!data?.user}
    >

      <EnhancedContainer>
        {hasBouncedEmail && <BouncedEmailAlert bouncedEmailData={data?.user.bouncedEmail} />}

        {data?.user && (
          <>
            <Box marginBottom={2}>
              <ShowCard
                canEdit={canEditUsers || canEditFamilyMemberOrSelf}
                icon={AccountCircleIcon}
                readOnly
                title="Profile Info"
              >
                <ShowAdultProfileData userData={data.user} />
              </ShowCard>
            </Box>

            <Box marginBottom={2}>
              <ShowCard
                canEdit={canEditUsers || canEditFamilyMemberOrSelf}
                icon={CellphoneWirelessIcon}
                readOnly
                title="Contact Info"
              >
                <ShowUserContactData userData={data.user} />
              </ShowCard>
            </Box>
          </>
        )}

        {data?.user && (
          <DynamicFormFields
            isAdmin={false}
            item={data?.user}
            showCardProps={{
              canEdit: canEditUsers,
              readOnly: true,
            }}
            showSimpleeZeroState={isViewingSelf}
            tableRef="members"
          />
        )}

        {hasFinancesFeatureFlag && canReadFinances && data?.user && (
          <FeesCard
            hasMemberOrgData={Boolean(data?.user.userOrgData)}
            memberEmail={data.user.email}
            userId={data.user.id}
            userName={getFullName(data.user)}
          />
        )}

        {data?.user.groups && (
          <GroupsCard
            groups={data?.user.groups}
            name={getFullName(data.user)}
            userId={data?.user.id}
          />
        )}

        {id && data?.user.role && (
          <RelationshipsCard
            memberId={id}
            memberRoleId={data.user.role.id}
          />
        )}

        {!isViewingSelf && data?.user?.userOrgData && (
          <ActivityCard
            authUserEmail={data.user.authUserEmail}
            authUserId={data.user.authUserId}
            loggedInAt={data.user.loggedInAt}
            memberEmail={data.user.email}
            name={getFullName(data.user)}
            receivedWelcomeEmailAt={data.user.userOrgData.receivedWelcomeEmailAt}
            userId={data.user.id}
            userOrgId={data.user.userOrgData.id}
            userOrgLoggedInAt={data.user.userOrgData.member?.loggedInAt}
          />
        )}

        {data?.user?.permissions && (
          <PermissionsCard
            adultId={data?.user.id}
            permissions={data.user.permissions}
          />
        )}
      </EnhancedContainer>

      {data?.user && (
        <History
          id={data.user.id}
          resource="members"
        />
      )}
    </Page>
  );
};

export default ParentsShow;
