// External Dependencies
import { yup } from '@presto-assistant/api_types/schemas/yup';

/**
 * Our shared api_types schema `updateOrganizationFinancialSettingsSchema`
 * validates the checkbox and statement values when the user submits the
 * form. However, the UI needs to show immediate feedback to the user
 * when they update the URL.
 */
export const financialSettingsExternalPaymentProviderUrlSchema = yup.object().shape({
  externalPaymentProviderUrl: yup.string().url().label('Link to External Payment Provider'),
});
