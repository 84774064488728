// External Dependencies
import * as Sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutEffect } from 'react';

// Internal Dependencies
import {
  NoAuth,
  OnboardingWrapper,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { isLoggedIn } from 'state/self/selectors';
import { updateDeviceInnerWidth } from 'state/device/actions';
import ChooseRole from 'pages/Signup/ChooseRole';
import CreateUserProfile from 'pages/Signup/CreateUserProfile';
import FourOhFour from 'pages/FourOhFour';
import Login from 'pages/Login';
import Logout from 'pages/Logout';
import MainContentContainer from 'components/shared/MainContentContainer';
import PasswordReset from 'pages/PasswordReset';
import PurchaseOrderUpload from 'pages/PurchaseOrderUpload';
import RoleRouter from 'pages/RoleRouter';
import Signup from 'pages/Signup';
import Sso from 'pages/Sso';
import SsoClassLink from 'pages/Sso/ClassLink';
import SsoClassLinkSignUp from 'pages/Sso/ClassLink/SignUp';
import Verify from 'pages/Verify';
import usePrevious from 'hooks/usePrevious';
import useWindowSize from 'hooks/useWindowSize';

// Local Dependencies
import { renderRoutes } from './helpers';
import {
  useGetDistrictAdminRoutes,
  useGetOnboardingRoutes,
  useGetOrganizationUserRoutes,
  useGetOrphanUserRoutes,
} from './hooks';

// Local Variables
// Enable Sentry to reach the router context
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// Component Definition
const AppRoutes = (): JSX.Element => {
  const dispatch = useDispatch();

  // Ensure this component re-renders on login/logout
  useSelector(isLoggedIn);

  // Get the current device width to store in the Redux state tree
  const { innerWidth } = useWindowSize();
  const previousInnerWidth = usePrevious(innerWidth);

  const organizationUserRoutes = useGetOrganizationUserRoutes();
  const districtAdminRoutes = useGetDistrictAdminRoutes();
  const onboardingRoutes = useGetOnboardingRoutes();
  const orphanUserRoutes = useGetOrphanUserRoutes();

  useLayoutEffect(() => {
    if (previousInnerWidth !== innerWidth) {
      dispatch(updateDeviceInnerWidth(innerWidth));
    }
  }, [dispatch, innerWidth, previousInnerWidth]);

  return (
    <SentryRoutes>
      <Route
        element={<Verify />}
        path={PATHS.VERIFY}
      />
      <Route
        element={<SsoClassLink />}
        path={`/${PATHS.SSO_CLASSLINK}`}
      />
      <Route
        element={<Sso />}
        path={`/${PATHS.SSO}/:token`}
      />
      <Route
        element={<Logout />}
        path={PATHS.LOGOUT}
      />

      {/* MainContentContainer for rendering layout components */}
      <Route
        element={<MainContentContainer />}
        path="/"
      >
        {/* Login Routes */}
        <Route
          element={<Login />}
          path="/"
        />
        <Route
          element={<Login />}
          path="/login"
        />

        {/* Signup and related routes */}
        <Route
          element={<NoAuth component={Signup} />}
          path={PATHS.SIGNUP}
        />
        <Route
          element={<NoAuth component={ChooseRole} />}
          path={PATHS.SIGNUP_CHOOSE_ROLE}
        />
        <Route
          element={<NoAuth component={CreateUserProfile} />}
          path={`${PATHS.SIGNUP}/${PATHS.CREATE_USER_PROFILE}`}
        />
        <Route
          element={<NoAuth component={PasswordReset} />}
          path={PATHS.PASSWORD_RESET}
        />
        <Route
          element={<NoAuth component={SsoClassLinkSignUp} />}
          path={`/${PATHS.SSO_CLASSLINK_SIGNUP}`}
        />

        {/* Unauthenticated Purchase Order Upload */}
        <Route
          element={<PurchaseOrderUpload />}
          path={`/${PATHS.PURCHASE_ORDER_UPLOAD}`}
        />

        {/* Role-based routes */}
        <Route
          element={<RoleRouter userRole="districtAdmin" />}
          path={PATHS.DISTRICT_ADMIN}
        >
          {renderRoutes(districtAdminRoutes)}
          <Route
            element={<FourOhFour />}
            path="*"
          />
        </Route>

        <Route
          element={<RoleRouter userRole="organizationUser" />}
          path="/"
        >
          {renderRoutes(organizationUserRoutes)}
          <Route
            element={<FourOhFour />}
            path="*"
          />
        </Route>

        <Route
          element={<RoleRouter userRole="orphanUser" />}
          path={PATHS.MISSING_ORGANIZATION}
        >
          {renderRoutes(orphanUserRoutes)}
          <Route
            element={<FourOhFour />}
            path="*"
          />
        </Route>

        {/* Default 404 page */}
        <Route
          element={<FourOhFour />}
          path="*"
        />
      </Route>

      {/* Onboarding routes */}
      <Route
        element={<OnboardingWrapper />}
        path={PATHS.ONBOARDING}
      >
        {renderRoutes(onboardingRoutes)}
        <Route
          element={<FourOhFour />}
          path="*"
        />
      </Route>
    </SentryRoutes>
  );
};

export default AppRoutes;
