// External Dependencies
import Button, { type ButtonProps } from '@mui/material/Button';
import clsx from 'clsx';
import styled from 'styled-components';

// Local Typings
interface Props {
  buttonText: string;
  className?: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  labelClasses?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'contained' | 'outlined' | 'text';
}

// Local Variables
const StyledButton = styled(Button)(({ theme }) => ({
  '&.button-root': {
    margin: '10px 10px 10px 0',
  },
  '&.form-button': {
    margin: theme.spacing(1),
  },
}));

// Component Definition
const CustomFormButton = ({
  buttonText,
  className,
  color = 'primary',
  labelClasses,
  size,
  type = 'submit',
  variant = 'contained',
  ...props
}: Props & ButtonProps): JSX.Element => {
  return (
    <StyledButton
      className={clsx('form-button', className)}
      classes={{ root: clsx('button-root', labelClasses) }}
      color={color}
      size={size}
      type={type}
      variant={variant}
      {...props}
    >
      {buttonText}
    </StyledButton>
  );
};

export default CustomFormButton;
