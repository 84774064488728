// External Dependencies
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedButton,
  EnhancedCard,
  EnhancedCardContent,
  EnhancedContainer,
  Flex,
  PrestoLogoOnlySvg,
} from 'components/shared';
import { SHORT_APP_NAME } from 'utils/constants';
import { removeClassLinkCode } from 'utils/cookies';
import { useClassLinkTokenInfo } from 'utils/api/auth';
import CircularProgress from '@mui/material/CircularProgress';
import ClassLinkLogo from 'components/shared/SsoSignInDialog/ClassLinkLogo';
import SignupContainer from 'pages/Signup/SignupContainer';
import SignupRoleCardsContainer from 'pages/Signup/SignupRoleCardsContainer';

// Local Variables
const StyledEnhancedContainer = styled(EnhancedContainer)(({ theme }) => ({
  '.email-address': {
    fontWeight: theme.typography.fontWeightMedium,
    margin: theme.spacing(1, 1, 2),
  },
  zIndex: 1,
}));

// Component Definition
const SsoClassLinkSignUp = (): JSX.Element => {
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    removeClassLinkCode();
    navigate('/');
  }, [navigate]);

  const handleConfirm = useCallback(() => {
    navigate('/signup');
  }, [navigate]);

  const {
    data: classLinkTokenInfoData,
    isFetching: isClassLinkTokenInfoFetching,
  } = useClassLinkTokenInfo();

  const classLinkEmail = classLinkTokenInfoData?.data.myInfo.Email;

  const loadingElement = (
    <Flex
      justifyContent="center"
      paddingY={3}
    >
      <CircularProgress />
    </Flex>
  );

  const emailElement = classLinkEmail
    ? (
      <span>
        the following email address:
        <Typography className="email-address">
          {classLinkEmail}
        </Typography>
      </span>
    ) : 'this email address.';

  return (
    <SignupContainer>
      <SignupRoleCardsContainer>
        <Typography
          className="title"
          component="h1"
          gutterBottom
          variant="h4"
        >
          Need a {SHORT_APP_NAME} Account?
        </Typography>

        <StyledEnhancedContainer>
          <EnhancedCard>
            <EnhancedCardContent>
              <Flex
                flexWrap="nowrap"
                gap={1}
                justifyContent="center"
                marginBottom={4}
                marginTop={4}
              >
                <PrestoLogoOnlySvg sx={{ width: 60 }} />

                <AddIcon />

                <ClassLinkLogo width={200} />
              </Flex>

              {isClassLinkTokenInfoFetching ? (
                loadingElement
              ) : (
                <Typography component="div">
                  Login account not found for{' '}
                  {emailElement}{' '}
                  Would you like to create one?
                </Typography>
              )}
            </EnhancedCardContent>

            {!isClassLinkTokenInfoFetching && (
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <EnhancedButton
                  onClick={handleCancel}
                  variant="text"
                >
                  Cancel
                </EnhancedButton>

                <EnhancedButton
                  color="primary"
                  onClick={handleConfirm}
                  variant="contained"
                >
                  Yes, Create Account
                </EnhancedButton>
              </CardActions>
            )}
          </EnhancedCard>
        </StyledEnhancedContainer>
      </SignupRoleCardsContainer>
    </SignupContainer>
  );
};

export default SsoClassLinkSignUp;
