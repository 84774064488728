// External Dependencies
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { ROLES, ROLE_IDS } from 'utils/constants';
import {
  isDirectorAdminInCurrentOrg,
  isSelfAdminInCurrentOrg,
} from 'utils';
import type { State } from 'state/reducers';

export const isDistrictAdmin = (state: State): boolean =>
  Boolean(state.self.tokenData?.districtAdminOrgId
    && state.self.tokenData?.orgId === null);

export const tokenData = (state: State) =>
  state.self.tokenData;

export const currentDistrictAdminOrgId = (state: State): string | null | undefined =>
  state.self.tokenData?.districtAdminOrgId;

// Returns a district id for DFA or for a user in an org that belongs to a district
export const districtId = (state: State): string | null => {
  const dfaDistrictId = currentDistrictAdminOrgId(state);

  if (dfaDistrictId) {
    return dfaDistrictId;
  }

  if (Object.keys(state.self.data ?? {}).length > 0) {
    const { data: selfData } = state.self;

    const currentOrg = selfData?.userOrgData?.find(
      (org: GQL.IUserOrganization) => org?.organizationId === selfData?.currentOrgId,
    );

    if (!currentOrg) {
      return null;
    }

    return currentOrg.district?.id ?? null;
  }

  return null;
};

export const isAdminInCurrentOrg = (state: any): boolean | undefined => {
  if (Object.keys(state.self.data ?? {}).length > 0) {
    const { data: selfData } = state.self;

    return isSelfAdminInCurrentOrg(selfData);
  }
  return undefined;
};

export const isLoggedIn = (state: State) =>
  !!state.self.data?.id;

export const selfId = (state: any): string | null =>
  state?.self?.data?.id ?? null;

export const isFullAccessDfa = (state: State) =>
  state?.self?.tokenData?.isFullAccessDfa;

export const isDirector = (state: any): boolean | undefined => {
  if (!state.self?.data) {
    return false;
  }

  // We allow a full-access DFA the same access as a director
  return isDirectorAdminInCurrentOrg(state.self?.data)
    || isFullAccessDfa(state);
};

export const isParent = (state: any): boolean | undefined =>
  state?.self?.data?.role?.id === ROLE_IDS[ROLES.ADULT]
  && !isAdminInCurrentOrg(state)
  && !isDistrictAdmin(state)
  && !isFullAccessDfa(state);

export const isStudent = (state: any): boolean | undefined =>
  state?.self?.data?.role?.id === ROLE_IDS[ROLES.STUDENT]
  && !isFullAccessDfa(state);

export const isParentOrStudent = (state: any): boolean | undefined =>
  isParent(state) || isStudent(state);

export const isNonParentOrStudent = (state: any): boolean | undefined =>
  !!state?.self?.data && !isParentOrStudent(state);

export const isCreator = (state: any): boolean =>
  state?.self?.data?.isCreator ?? false;

// Permissions
export const hasPermission = (
  resource: Exclude<keyof GQL.IPermissions, '__typename'>,
  action: Exclude<keyof GQL.IPermissionActions, '__typename'>,
) => (state: State) => Boolean(
  state.self?.data?.permissions?.[resource][action].allowed,
);

export const hasFeatureFlag = (featureFlag: FeatureFlag) => (state: State) => {
  const featureFlags = state.self.tokenData?.featureFlags ?? [];

  const flag = featureFlags.find((f) => f.id === featureFlag);

  return !!flag?.isActive;
};

export const minimumWebAppVersion = (state: any): string =>
  state?.self?.tokenData?.minimumWebAppVersion ?? '';

export const useHasPermission = (
  resource: Exclude<keyof GQL.IPermissions, '__typename'>,
  action: Exclude<keyof GQL.IPermissionActions, '__typename'>,
) => useSelector(hasPermission(resource, action));

export const selectIsParentWithNoPermissions = (state: State) => {
  const isParentMember = isParent(state);
  const hasNoPermissions = tokenData(state)?.permissionIds?.length === 0;

  return isParentMember && hasNoPermissions;
};
