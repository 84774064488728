// External Dependencies
import Cookies from 'universal-cookie';

// Internal Dependencies
import { JWTPayload } from '@presto-assistant/api_types';
import { apiClient } from 'utils/apiClient';
import { parseSearch } from 'utils';
import { useMemo } from 'react';

const cookies = new Cookies();

const REMEMBER_ME_KEY = 'rememberMe';
export const TOKEN_KEY = 'token';
const PRESTO_ADMIN_KEY = 'prestoAdmin';
const LAST_ACTIVITY_TIMESTAMP_KEY = 'lastActivityTimestamp';
const CLASSLINK_CODE_KEY = 'classLinkCode';

export const getCookieToken = () => {
  const cookieToken = cookies.get(TOKEN_KEY);

  if (!cookieToken || cookieToken === 'undefined') {
    return null;
  }

  return cookieToken;
};

export const getToken = () => {
  const search = parseSearch(window.location.search);

  const searchToken = search.token;

  const cookieToken = getCookieToken();

  const token = cookieToken || searchToken;

  if (token) {
    apiClient.setToken(token);
  }

  return token;
};

export const useParseToken = () => {
  const token = getToken();

  return useMemo(() => {
    if (!token) {
      return null;
    }

    try {
      return JSON.parse(atob(token.split('.')[1])) as JWTPayload;
    } catch (error) {
      return null;
    }
  }, [token]);
};

export const clearTokens = () => {
  cookies.remove(TOKEN_KEY, { path: '/' });
  cookies.remove(REMEMBER_ME_KEY, { path: '/' });
  cookies.remove(PRESTO_ADMIN_KEY);
};

export const shouldRememberUser = () => cookies.get(REMEMBER_ME_KEY);

const setRememberMe = (rememberMe: boolean) => {
  if (rememberMe) {
    const expiration = new Date();
    const msInDay = 86400000;
    const days = 7;
    expiration.setTime(expiration.getTime() + (days * msInDay));
    cookies.set(REMEMBER_ME_KEY, rememberMe, { expires: expiration, path: '/' });
  } else {
    cookies.remove(REMEMBER_ME_KEY);
  }
};

export const checkToRememberUser = (rememberMe: boolean) =>
  setRememberMe(rememberMe);

export const setPermCookie = (token: string) => {
  const expiration = new Date();
  const msInDay = 86400000;
  const days = 7;
  expiration.setTime(expiration.getTime() + (days * msInDay));
  cookies.set(TOKEN_KEY, token, { expires: expiration, path: '/' });
};

export const setTempCookie = (token: string) => {
  cookies.set(TOKEN_KEY, token, { path: '/' });
};

export const setAdminCookie = () => {
  cookies.set(PRESTO_ADMIN_KEY, true, { path: '/' });
};

export const getAdminCookie = () => cookies.get(PRESTO_ADMIN_KEY);

export const setTokenInStorage = (token: string, rememberMeOverride?: boolean) => {
  apiClient.setToken(token);

  if (rememberMeOverride === false) {
    setTempCookie(token);
  } else if (shouldRememberUser() || rememberMeOverride) {
    setPermCookie(token);
  } else {
    setTempCookie(token);
  }
};

export const setLatestActivity = () => {
  const now = Date.now();

  cookies.set(LAST_ACTIVITY_TIMESTAMP_KEY, now);
};

export const getLatestActivity = () =>
  cookies.get(LAST_ACTIVITY_TIMESTAMP_KEY);

export const setClassLinkCode = (code: string) => {
  const expiration = new Date();
  const minutes = 30;
  expiration.setTime(expiration.getTime() + minutes * 60_000);
  cookies.set(CLASSLINK_CODE_KEY, code, { expires: expiration, path: '/' });
};

export const getClassLinkCode = () => {
  return cookies.get(CLASSLINK_CODE_KEY);
};

export const removeClassLinkCode = () => {
  cookies.remove(CLASSLINK_CODE_KEY, { path: '/' });
};
