// External Dependencies
import {
  FC, useEffect, useRef, useState,
} from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';

// Internal Dependencies
import { hasFeatureFlag } from 'state/self/selectors';
import IntegrationsCard from 'components/shared/IntegrationsCard';

// Local Dependencies
import GoogleCalendarSettings from './GoogleCalendarSettings';
import StripeSettings from './StripeSettings';
import VancoSettings from './VancoSettings';

// Component Definition
const OrganizationIntegrationsCard: FC = () => {
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [shouldScroll, setShouldScroll] = useState(false);

  const hasOnlinePaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));
  const hasVancoPaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.VancoPayments));
  const hasMultiplePaymentProvidersFeatureFlag = useSelector(
    hasFeatureFlag(FeatureFlag.MultiplePaymentProviders),
  );
  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));

  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#integrations') {
      setShouldScroll(true);
    }
  }, [location.hash]);

  useEffect(() => {
    if (shouldScroll && scrollTargetRef.current) {
      scrollTargetRef.current.scrollIntoView();
    }
  }, [shouldScroll]);

  return (
    <IntegrationsCard ref={scrollTargetRef}>
      <GoogleCalendarSettings />

      {hasFinancesFeatureFlag && (
        <>
          {hasOnlinePaymentsFeatureFlag && !hasMultiplePaymentProvidersFeatureFlag && (
          <>
            <Divider />

            <StripeSettings />
          </>
          )}

          {hasOnlinePaymentsFeatureFlag && hasVancoPaymentsFeatureFlag
          && !hasMultiplePaymentProvidersFeatureFlag && (
            <>
              <Divider />

              <VancoSettings />
            </>
          )}
        </>
      )}
    </IntegrationsCard>
  );
};

export default OrganizationIntegrationsCard;
