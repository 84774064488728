// External Dependencies
import { FC } from 'react';

// Internal Dependencies
import {
  DashboardCard,
  DialogPermissionRequired,
  EnhancedButton,
  ShowPageDataDisplay,
} from 'components/shared';
import { useHasPermission } from 'state/self/selectors';
import { useIsOpen } from 'hooks/useIsOpen';

// Local Dependencies
import ApplyAllCreditsDialog from './ApplyAllCreditsDialog';

// Local Typings
interface Props {
  creditFromManualEntryInCents: number;
  creditFromOverpaymentInCents: number;
}

// Component Definition
const AvailableMemberCreditCard: FC<Props> = ({
  creditFromManualEntryInCents,
  creditFromOverpaymentInCents,
}) => {
  const {
    isOpen: isApplyAllCreditsDialogOpen,
    toggleIsOpen: toggleIsApplyAllCreditsDialogOpen,
  } = useIsOpen();

  const canWritePayments = useHasPermission('payments', 'write');

  return (
    <>
      <DashboardCard
        cardActions={(
          <EnhancedButton
            disabled={!creditFromManualEntryInCents && !creditFromOverpaymentInCents}
            onClick={toggleIsApplyAllCreditsDialogOpen}
            variant="outlined"
          >
            Apply Credits
          </EnhancedButton>

          )}
        content={(
          <>
            <ShowPageDataDisplay
              label="Credit from Overpayment"
              type="currency"
              value={creditFromOverpaymentInCents}
            />

            <ShowPageDataDisplay
              label="Credit from Manual Entry"
              type="currency"
              value={creditFromManualEntryInCents}
            />
          </>
          )}
        title="Current Year Credits"
      />

      <DialogPermissionRequired
        isOpen={isApplyAllCreditsDialogOpen && !canWritePayments}
        onClose={toggleIsApplyAllCreditsDialogOpen}
      />

      <ApplyAllCreditsDialog
        isOpen={isApplyAllCreditsDialogOpen && canWritePayments}
        onClose={toggleIsApplyAllCreditsDialogOpen}
      />
    </>
  );
};

export default AvailableMemberCreditCard;
