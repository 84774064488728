// External Dependencies
import {
  GetPurchaseOrderPresignedUrlRequest,
  UploadPurchaseOrderRequest,
} from '@presto-assistant/api_types/api/v1/purchaseOrder';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

/**
 * Hook for getting a presigned URL for a purchase order
 */
export const useGetPurchaseOrderPresignedUrl = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: GetPurchaseOrderPresignedUrlRequest['body']) => {
    return apiClient.open.purchaseOrder.getPresignedUrl(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};

/**
 * Hook for uploading a purchase order
 */
export const useUploadPurchaseOrder = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: UploadPurchaseOrderRequest['body']) => {
    return apiClient.open.purchaseOrder.upload(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};
