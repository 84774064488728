// External Dependencies
import { FinancialStatementScheduleTypes } from '@presto-assistant/api_types';
import { Form, Formik } from 'formik';
import { UpdateOrganizationFinancialSettingsRequest } from '@presto-assistant/api_types/api/v1/organization';
import { toTitleCase } from '@presto-assistant/api_types/utils/toTitleCase';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { CONTAINER_WIDTH } from 'utils/constants/layout';
import {
  Container,
  CustomCheckbox,
  CustomInput,
  CustomSelect,
  EnhancedCardContent,
  Page,
  SaveButton,
  ShowCard,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { mapEnum } from 'utils/lib/map_enum';
import { useGetOrganization } from 'gql/queries';
import { useUpdateFinancialSettings } from 'utils/api/organization';
import CardSectionTitle from 'components/shared/CardSectionTitle';

// Local Dependencies
import { financialSettingsExternalPaymentProviderUrlSchema } from './schema';

// Local Variables
const getNameFromEnum = (type: FinancialStatementScheduleTypes) => {
  switch (type) {
    case FinancialStatementScheduleTypes.BiMonthly:
      return 'Bi-Monthly';
    default:
      return toTitleCase(FinancialStatementScheduleTypes[type]);
  }
};

const getDescriptionFromEnum = (type: FinancialStatementScheduleTypes) => {
  switch (type) {
    case FinancialStatementScheduleTypes.Monthly:
      return '1st of every month';
    case FinancialStatementScheduleTypes.BiMonthly:
      return '1st and 15th of every month';
    case FinancialStatementScheduleTypes.Weekly:
      return 'Every Monday';
    default:
      return '';
  }
};

export const getScheduleTypeNameAndDescription = (type: FinancialStatementScheduleTypes) => {
  const name = getNameFromEnum(type);
  const description = getDescriptionFromEnum(type);

  return [name, description].filter(Boolean).join(' - ');
};

const scheduleTypeOptions = [
  {
    id: 0,
    label: 'Off',
  },
  ...mapEnum(FinancialStatementScheduleTypes)
    .map((type) => ({
      ...type,
      label: getScheduleTypeNameAndDescription(type.id),
    }))];

// Component Definition
const FinancialSettings = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    data: organizationData,
    loading: isLoadingOrganization,
    refetch: refetchOrganization,
  } = useGetOrganization();

  const handleNavigateBackToSettings = useCallback(() => {
    navigate(`/${PATHS.SETTINGS}`);
  }, [navigate]);

  const {
    isLoading: isUpdatingFinancialSettings,
    mutate: updateFinancialSettings,
  } = useUpdateFinancialSettings();

  const handleSuccess = useCallback(async () => {
    // refetch here to wait for the organization to be updated before navigating back
    await refetchOrganization();
    dispatch(addNotification('Financial settings updated successfully.', 'success'));
    handleNavigateBackToSettings();
  }, [dispatch, handleNavigateBackToSettings, refetchOrganization]);

  const handleFormikSubmit = useCallback((values: UpdateOrganizationFinancialSettingsRequest['body']) => {
    const scheduleTypeIdNum = values.scheduledStatementSettings.scheduleTypeId
      ? Number(values.scheduledStatementSettings.scheduleTypeId)
      : null;

    const payload: UpdateOrganizationFinancialSettingsRequest['body'] = {
      externalPaymentProviderUrl: values.externalPaymentProviderUrl,
      isIgnoringPreviousYearsFinances: values.isIgnoringPreviousYearsFinances,
      scheduledStatementSettings: {
        onlyMembersWithBalance: values.scheduledStatementSettings.onlyMembersWithBalance,
        onlySendToAdults: values.scheduledStatementSettings.onlySendToAdults,
        // use || because 0 means "off" want to send null if 0
        scheduleTypeId: scheduleTypeIdNum || null,
      },
    };

    updateFinancialSettings(
      payload,
      {
        onSuccess: handleSuccess,
      },
    );
  }, [
    handleSuccess,
    updateFinancialSettings,
  ]);

  const organization = organizationData?.organization;

  const handleClickCancel = useCallback(() => {
    handleNavigateBackToSettings();
  }, [handleNavigateBackToSettings]);

  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: `/${PATHS.SETTINGS}`,
      }}
      isLoading={isLoadingOrganization}
    >
      <Container maxWidth={CONTAINER_WIDTH}>
        <Formik<UpdateOrganizationFinancialSettingsRequest['body']>
          enableReinitialize
          initialValues={{
            externalPaymentProviderUrl: organization?.externalPaymentProviderUrl ?? '',
            isIgnoringPreviousYearsFinances: Boolean(organization?.isIgnoringPreviousYearsFinances),
            scheduledStatementSettings: {
              onlyMembersWithBalance: Boolean(
                organization?.financialStatementOnlyMembersWithBalance,
              ),
              onlySendToAdults: Boolean(
                organization?.financialStatementOnlySendToAdults,
              ),
              scheduleTypeId: organization?.financialStatementScheduleTypeId ?? null,
            },
          }}
          onSubmit={handleFormikSubmit}
          validationSchema={financialSettingsExternalPaymentProviderUrlSchema}
        >
          {() => (
            <Form>
              <ShowCard
                cardActions={(
                  <CardActions>
                    <Button onClick={handleClickCancel}>
                      Cancel
                    </Button>

                    <SaveButton isSaving={isUpdatingFinancialSettings || isLoadingOrganization}>
                      Save
                    </SaveButton>
                  </CardActions>
                )}
                icon={AccountBalanceIcon}
                title="Financial Settings"
              >
                <CardSectionTitle gutterBottom>
                  General Settings
                </CardSectionTitle>

                <EnhancedCardContent>
                  <Typography
                    color="textSecondary"
                    paragraph
                    variant="body2"
                  >
                    Ignore all financial fees from previous years.
                    Payments will not be able to be made for previous years.
                  </Typography>

                  <CustomCheckbox
                    label="Ignore financial data from previous years"
                    name="isIgnoringPreviousYearsFinances"
                  />
                </EnhancedCardContent>

                <Divider sx={{ marginBottom: 2 }} />

                <CardSectionTitle gutterBottom>
                  Financial Statement Settings
                </CardSectionTitle>

                <EnhancedCardContent>
                  <Typography
                    color="textSecondary"
                    paragraph
                    variant="body2"
                  >
                    The financial statement schedule is used to determine the
                    frequency of financial statements sent to members automatically.
                  </Typography>

                  <CustomSelect
                    label="Financial Statement Schedule"
                    name="scheduledStatementSettings.scheduleTypeId"
                    options={scheduleTypeOptions}
                    required
                  />

                  <CustomCheckbox
                    label="Only send to members with a balance"
                    name="scheduledStatementSettings.onlyMembersWithBalance"
                  />

                  <CustomCheckbox
                    label="Only send to adult relatives"
                    name="scheduledStatementSettings.onlySendToAdults"
                  />
                </EnhancedCardContent>

                {!organization?.hasAccountWithOnlinePayments && (
                  <>

                    <Divider sx={{ marginBottom: 2 }} />

                    <CardSectionTitle gutterBottom>
                      Link to External Payment Provider
                    </CardSectionTitle>

                    <EnhancedCardContent>
                      <Typography
                        color="textSecondary"
                        paragraph
                        variant="body2"
                      >
                        Provide a URL to an external payment provider that
                        will show as a QR code and link on all financial statements.
                      </Typography>

                      <CustomInput
                        label="Link to External Payment Provider"
                        name="externalPaymentProviderUrl"
                        placeholder="https://app.presto-assistant.com/payments"
                      />
                    </EnhancedCardContent>
                  </>
                )}
              </ShowCard>
            </Form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default FinancialSettings;
