import { useSelector } from 'react-redux';

import {
  isDirector,
  isDistrictAdmin,
  isFullAccessDfa,
  isParent,
  isStudent,
} from 'state/self/selectors';

// Local Typings
export type UserOrgRole =
  | 'District Admin'
  | 'Director'
  | 'Full-access DFA'
  | 'Parent'
  | 'Student'
  | 'Unknown role';

export const useGetUserOrgRole = (): UserOrgRole | undefined => {
  const isDirectorUser = useSelector(isDirector);
  const isDistrictAdminUser = useSelector(isDistrictAdmin);
  const isFullAccessDfaUser = useSelector(isFullAccessDfa);
  const isParentUser = useSelector(isParent);
  const isStudentUser = useSelector(isStudent);

  if (isFullAccessDfaUser) {
    return 'Full-access DFA';
  }
  if (isDirectorUser) {
    return 'Director';
  }
  if (isDistrictAdminUser) {
    return 'District Admin';
  }
  if (isParentUser) {
    return 'Parent';
  }
  if (isStudentUser) {
    return 'Student';
  }

  return undefined;
};
