// External Dependencies
import { OrganizationEntityTypes, UserRoles } from '@presto-assistant/api_types';
import { useMemo } from 'react';
import type { GridColDef } from '@mui/x-data-grid-pro';

// Internal Dependencies
import { getGradeOptions } from 'components/shared/GradeSelect';
import { mapEnum } from 'utils/lib/map_enum';
import {
  useGetOrganization,
  useGetOrganizationRoles,
} from 'gql/queries';

export const useColumns = (extraColumns: GridColDef[]) => {
  const {
    data: organizationRoleData,
  } = useGetOrganizationRoles();

  const { data: organizationData } = useGetOrganization();

  const entityTypeId = organizationData ? Number(organizationData.organization.entityType.id) : 0;

  const gradeOptions = useMemo(() => {
    if (!entityTypeId) {
      return [];
    }

    return getGradeOptions(entityTypeId);
  }, [entityTypeId]);

  return useMemo(() => {
    const isCollegeOrUniversity = organizationData?.organization
      .entityType.id === OrganizationEntityTypes.College.toString();

    const columns: GridColDef<GQL.IMemberIndex>[] = [
      {
        editable: true,
        field: 'firstName',
        headerName: 'First Name',
        width: 160,
      },
      {
        editable: true,
        field: 'lastName',
        headerName: 'Last Name',
        width: 160,
      },
      {
        editable: true,
        field: 'middleName',
        headerName: 'Middle Name',
        width: 160,
      },
      {
        editable: true,
        field: 'email',
        headerName: 'Email',
        width: 292,
      },
      {
        editable: true,
        field: 'roleLabel',
        headerName: 'Role',
        type: 'singleSelect',
        valueOptions: mapEnum(UserRoles).map((option) => ({
          label: option.label,
          value: option.label,
        })),
        width: 80,
      },
      {
        editable: true,
        field: 'gradeLabel',
        headerName: 'Grade',
        type: 'singleSelect',
        valueGetter: (params) => (isCollegeOrUniversity
          ? gradeOptions.find((option) => option.id === String(params.row.grade))?.label ?? ''
          : params.row.grade),
        valueOptions: gradeOptions.map((option) => ({
          label: option.label,
          value: isCollegeOrUniversity ? option.label : Number(option.id),
        })),
        width: 80,
      },
      {
        editable: true,
        field: 'primaryRoleLabel',
        headerName: 'Primary Role', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IMemberIndex).primaryRole?.label,
        valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
          label: role.label,
          value: role.label,
        })),
      },
      {
        editable: true,
        field: 'studentId',
        headerName: 'Student ID',
      },
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [extraColumns, gradeOptions, organizationData, organizationRoleData]);
};
