// External Dependencies
import { DistrictSsoTypes, FeatureFlag } from '@presto-assistant/api_types';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccountCheckIcon from 'mdi-material-ui/AccountCheck';
import AccountSearchIcon from 'mdi-material-ui/AccountSearch';
import CloudRefreshIcon from 'mdi-material-ui/CloudRefresh';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import CloudUploadIcon from 'mdi-material-ui/CloudUpload';
import EmailCheckIcon from 'mdi-material-ui/EmailCheck';
import EmailSearchIcon from 'mdi-material-ui/EmailSearch';
import TextBoxCheckIcon from 'mdi-material-ui/TextBoxCheck';
import TextBoxIcon from 'mdi-material-ui/TextBox';
import TextBoxSearchIcon from 'mdi-material-ui/TextBoxSearch';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { IToolbarAction } from 'components/shared/DataTable/Toolbar';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { hasFeatureFlag, hasPermission } from 'state/self/selectors';
import {
  open as openStudentFileUploadDialog,
} from 'state/ui/studentFileUploadDialog/actions';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useGetOrganization } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useParseToken } from 'utils/cookies';
import AssignToGroupDialog from 'pages/People/shared/AssignToGroupDialog';
import DialogDistrictRequired from 'components/shared/DialogDistrictRequired';
import SendFinancialStatementDialog from 'components/shared/SendFinancialStatementDialog';
import SsoSignInDialog from 'components/shared/SsoSignInDialog';

// Local Dependencies
import DialogStudentFileUpload from './DialogStudentFileUpload';

// Local Typings
interface Props {
  currentGroupId?: string;
  isDistrictRequiredDialogOpen?: boolean;
  isRosteringNotEnabledDialogOpen?: boolean;
  isSendStatementDialogOpen: boolean;
  isSsoPromptDialogOpen?: boolean;
  isUpdatingStudents: boolean;
  memberIdsToAssignToGroup: string[];
  memberIdsToSendStatementsTo: string[] | null;
  onCloseAssignMembersToGroupDialog: () => void;
  onCloseDistrictRequiredDialog?: () => void;
  onCloseRosteringNotEnabledDialog?: () => void;
  onCloseSendStatementDialog: () => void;
  onCloseSsoPromptDialog?: () => void;
}

type UseMemberToolbarActions = Props & {
  toolbarActions: IToolbarAction[];
}

// Local Variables
export const useMemberToolbarActions = ({
  filteredMemberIds,
  selectedMemberIds,
  showSendStatementsToEveryone = true,
  withImports,
}: {
  filteredMemberIds: string[];
  selectedMemberIds: string[];
  showSendStatementsToEveryone?: boolean;
  withImports: boolean;
}): UseMemberToolbarActions => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useParseToken();

  const ssoTenantId = token?.ssoTenantId;

  const [isUpdatingStudents, setIsUpdatingStudents] = useState(false);

  const [
    memberIdsToSendStatementsTo,
    setMemberIdsToSendStatementsTo,
  ] = useState<string[] | null>(null);

  const [
    memberIdsToAssignToGroup,
    setMemberIdsToAssignToGroup,
  ] = useState<string[]>([]);

  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));
  const canEmailMembers = useSelector(hasPermission('emailMembers', 'write'));
  const canCreateUsers = useSelector(hasPermission('users', 'write'));
  const canEditUsers = useSelector(hasPermission('users', 'edit'));
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canEditGroups = useSelector(hasPermission('groups', 'edit'));

  const {
    isOpen: isSsoPromptDialogOpen,
    toggleIsOpen: toggleIsSsoPromptDialogOpen,
  } = useIsOpen();
  const {
    isOpen: isDistrictRequiredDialogOpen,
    toggleIsOpen: toggleIsDistrictRequiredDialogOpen,
  } = useIsOpen();
  const {
    isOpen: isRosteringNotEnabledDialogOpen,
    toggleIsOpen: toggleIsRosteringNotEnabledDialogOpen,
  } = useIsOpen();
  const {
    handleOpen: handleOpenSendStatementDialog,
    isOpen: isSendStatementDialogOpen,
    toggleIsOpen: toggleSendStatementDialog,
  } = useIsOpen();

  const {
    data: organizationData,
  } = useGetOrganization();

  const handleOpenDialogStudentFileUpload = useCallback((isUpdating: boolean) => () => {
    setIsUpdatingStudents(isUpdating);

    dispatch(openStudentFileUploadDialog());
  }, [dispatch]);

  const handleClickImportFromClassLink = useCallback(() => () => {
    const district = organizationData?.organization.district;

    if (!district) {
      toggleIsDistrictRequiredDialogOpen();
      return;
    }

    if (!district.rosteringTenantId) {
      toggleIsRosteringNotEnabledDialogOpen();
      return;
    }

    if (ssoTenantId && district.rosteringTenantId !== ssoTenantId) {
      dispatch(addNotification('You are signed in under a different ClassLink account than your district', 'error'));
      return;
    }

    if (!ssoTenantId) {
      toggleIsSsoPromptDialogOpen();
      return;
    }

    navigate(`/${PATHS.CLASS_LINK_IMPORT}`);
  }, [
    dispatch,
    organizationData,
    navigate,
    ssoTenantId,
    toggleIsSsoPromptDialogOpen,
    toggleIsDistrictRequiredDialogOpen,
    toggleIsRosteringNotEnabledDialogOpen,
  ]);

  const handleClickSendStatements = useCallback((memberIds: string[] | null) => () => {
    setMemberIdsToSendStatementsTo(memberIds);
    handleOpenSendStatementDialog();
  }, [handleOpenSendStatementDialog]);

  const handleClickAssignMembersToGroup = useCallback((memberIds: string[]) => () => {
    setMemberIdsToAssignToGroup(memberIds);
  }, []);

  const handleCloseAssignMembersToGroupDialog = useCallback(() => {
    setMemberIdsToAssignToGroup([]);
  }, []);

  const handleClickEmailMembers = useCallback((memberIds: string[]) => () => {
    // Add ids to the recipient ids for a new email
    dispatch(updateRecipients(memberIds));
    navigate(`/${PATHS.EMAIL_NEW}`);
  }, [dispatch, navigate]);

  const toolbarActions = useMemo<IToolbarAction[]>(() => {
    const actions: IToolbarAction[] = [];

    if (canCreateUsers && withImports) {
      actions.push({
        action: handleOpenDialogStudentFileUpload(false),
        icon: <CloudUploadIcon />,
        sectionTitle: 'Import',
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        text: 'Import new students',
      });
    }

    if (canCreateUsers && withImports) {
      actions.push({
        action: handleClickImportFromClassLink(),
        icon: <CloudSyncIcon />,
        text: 'Import students from ClassLink',
      });
    }

    if (canEditUsers && withImports) {
      actions.push({
        action: handleOpenDialogStudentFileUpload(true),
        icon: <CloudRefreshIcon />,
        // TODO: Update this to the new way the API tells us about active
        // isDisabled: !self?.currentOrgActive,
        sectionTitle: canCreateUsers ? undefined : 'Import',
        text: 'Import student updates',
      });
    }

    if (canEmailMembers) {
      actions.push(...[
        {
          action: handleClickEmailMembers(selectedMemberIds),
          icon: <EmailCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          sectionTitle: 'Email',
          text: `Email selected (${selectedMemberIds.length})`,
        },
        {
          action: handleClickEmailMembers(filteredMemberIds),
          icon: <EmailSearchIcon />,
          isDisabled: filteredMemberIds.length === 0,
          text: `Email filtered (${filteredMemberIds.length})`,
        },
      ]);
    }

    if (hasFinancesFeatureFlag && canReadFinances) {
      if (showSendStatementsToEveryone) {
        actions.push({
          action: handleClickSendStatements(null),
          icon: <TextBoxIcon />,
          sectionTitle: 'Financial Statements',
          text: 'Send statements to everyone',
        });
      }

      actions.push(...[
        {
          action: handleClickSendStatements(selectedMemberIds as string[]),
          icon: <TextBoxCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,

          sectionTitle: !showSendStatementsToEveryone ? 'Financial Statements' : '',
          text: `Send statements to selected (${selectedMemberIds.length})`,
        },
        {
          action: handleClickSendStatements(filteredMemberIds as string[]),
          icon: <TextBoxSearchIcon />,
          isDisabled: filteredMemberIds.length === 0,
          text: `Send statements to filtered (${filteredMemberIds.length})`,
        },
      ]);
    }

    if (canEditGroups) {
      actions.push(...[
        {
          action: handleClickAssignMembersToGroup(selectedMemberIds as string[]),
          icon: <AccountCheckIcon />,
          isDisabled: selectedMemberIds.length === 0,
          sectionTitle: 'Group Members',
          text: `Add selected (${selectedMemberIds.length}) to a group`,
        },
        {
          action: handleClickAssignMembersToGroup(filteredMemberIds as string[]),
          icon: <AccountSearchIcon />,
          isDisabled: filteredMemberIds.length === 0,
          text: `Add filtered (${filteredMemberIds.length}) to a group`,
        },
      ]);
    }

    return actions;
  }, [
    canCreateUsers,
    canEditGroups,
    canEditUsers,
    canEmailMembers,
    canReadFinances,
    hasFinancesFeatureFlag,
    filteredMemberIds,
    handleClickAssignMembersToGroup,
    handleClickEmailMembers,
    handleClickImportFromClassLink,
    handleClickSendStatements,
    handleOpenDialogStudentFileUpload,
    selectedMemberIds,
    showSendStatementsToEveryone,
    withImports,
  ]);

  return useMemo(() => ({
    isDistrictRequiredDialogOpen,
    isRosteringNotEnabledDialogOpen,
    isSendStatementDialogOpen,
    isSsoPromptDialogOpen,
    isUpdatingStudents,
    memberIdsToAssignToGroup,
    memberIdsToSendStatementsTo,
    onCloseAssignMembersToGroupDialog: handleCloseAssignMembersToGroupDialog,
    onCloseDistrictRequiredDialog: toggleIsDistrictRequiredDialogOpen,
    onCloseRosteringNotEnabledDialog: toggleIsRosteringNotEnabledDialogOpen,
    onCloseSendStatementDialog: toggleSendStatementDialog,
    onCloseSsoPromptDialog: toggleIsSsoPromptDialogOpen,
    toolbarActions,
  }), [
    handleCloseAssignMembersToGroupDialog,
    isDistrictRequiredDialogOpen,
    isRosteringNotEnabledDialogOpen,
    isSendStatementDialogOpen,
    isSsoPromptDialogOpen,
    isUpdatingStudents,
    memberIdsToAssignToGroup,
    memberIdsToSendStatementsTo,
    toggleIsDistrictRequiredDialogOpen,
    toggleIsRosteringNotEnabledDialogOpen,
    toggleSendStatementDialog,
    toggleIsSsoPromptDialogOpen,
    toolbarActions,
  ]);
};

// Component Definition
const MemberToolbarActionDialogs = ({
  currentGroupId,
  isDistrictRequiredDialogOpen,
  isRosteringNotEnabledDialogOpen,
  isSendStatementDialogOpen,
  isSsoPromptDialogOpen,
  isUpdatingStudents,
  memberIdsToAssignToGroup,
  memberIdsToSendStatementsTo,
  onCloseAssignMembersToGroupDialog,
  onCloseDistrictRequiredDialog,
  onCloseRosteringNotEnabledDialog,
  onCloseSendStatementDialog,
  onCloseSsoPromptDialog,
}: Props): JSX.Element => {
  return (
    <>
      <DialogStudentFileUpload isUpdatingStudents={isUpdatingStudents} />

      <SendFinancialStatementDialog
        isOpen={isSendStatementDialogOpen}
        memberIds={memberIdsToSendStatementsTo}
        onClose={onCloseSendStatementDialog}
      />

      <AssignToGroupDialog
        currentGroupId={currentGroupId}
        memberIds={memberIdsToAssignToGroup}
        onClose={onCloseAssignMembersToGroupDialog}
      />

      {onCloseSsoPromptDialog && (
        <SsoSignInDialog
          isOpen={Boolean(isSsoPromptDialogOpen)}
          onClose={onCloseSsoPromptDialog}
          ssoTypeId={DistrictSsoTypes.ClassLink}
        />
      )}

      {onCloseDistrictRequiredDialog && (
        <DialogDistrictRequired
          isOpen={Boolean(isDistrictRequiredDialogOpen)}
          onClose={onCloseDistrictRequiredDialog}
        />
      )}

      {onCloseRosteringNotEnabledDialog && (
        <ConfirmationDialog
          confirmButtonAction={onCloseRosteringNotEnabledDialog}
          description="Your district has not enabled ClassLink rostering."
          handleClose={onCloseRosteringNotEnabledDialog}
          hideDeclineButton
          open={Boolean(isRosteringNotEnabledDialogOpen)}
          title="ClassLink Rostering Not Enabled"
        />
      )}
    </>
  );
};

export default MemberToolbarActionDialogs;
