// External Dependencies
import { getOperationName } from '@apollo/client/utilities';
import {
  useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import type {
  GridColDef,
  GridRowId,
  GridRowSelectionModel,
} from '@mui/x-data-grid-pro';

// Internal Dependencies
import {
  EnhancedButton,
  TableDataGrid,
} from 'components/shared';
import { GET_GROUP, useGetGroupUsersIndex } from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { REMOVE_MEMBERS_FROM_GROUP } from 'gql/mutations';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { updateRecipients } from 'state/ui/emailNew/actions';
import { useClearTableSelection } from 'hooks/useClearTableSelection';
import { useHasPermission } from 'state/self/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialog from 'components/shared/DeleteDialog';
import MemberToolbarActionDialogs, { useMemberToolbarActions } from 'pages/People/Students/All/StudentsTable/MemberToolbarActionDialogs';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  groupId: string;
  isLoading: boolean;
}

// Component Definition
const GroupTableUI = ({
  groupId,
  isLoading,
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);
  const [
    filteredRowIds,
    setFilteredRowIds,
  ] = useState<GridRowId[]>([]);
  const [
    selectedGroupMemberIds,
    setSelectedGroupMemberIds,
  ] = useState<GridRowSelectionModel>([]);

  const canReadMembers = useHasPermission('users', 'read');
  const canEditMembers = useHasPermission('users', 'edit');
  const canEditGroups = useHasPermission('groups', 'edit');
  const canWriteEmail = useHasPermission('emailMembers', 'write');
  const canReadFinances = useHasPermission('finances', 'read');
  const canReadUsers = useHasPermission('users', 'read');

  const handleRemoveSelectionAll = useClearTableSelection('group');

  const {
    data,
    fullCount,
    isLoading: isLoadingUsers,
  } = useGetGroupUsersIndex(groupId);

  const {
    isOpen: isRemoveGroupMemberDialogOpen,
    toggleIsOpen: toggleRemoveGroupMemberDialog,
  } = useIsOpen();

  const handleOpenSingleItemDeleteDialog = useCallback(
    (row: GQL.IUser) => {
      setSingleItemDeleteId(row.id);
      toggleRemoveGroupMemberDialog();
    },
    [toggleRemoveGroupMemberDialog],
  );

  const handleClickViewMember = useCallback((row: GQL.IUser) => {
    navigate(`/${PATHS.MEMBERS}/${row.id}`);
  }, [navigate]);

  const handlePressEditMember = useCallback((row: GQL.IUser) => {
    navigate(`/${PATHS.MEMBERS}/${row.id}/edit`);
  }, [navigate]);

  const handleNavigateToEditGroupPage = useCallback(() => {
    navigate(`/groups/${groupId}/edit`);
  }, [groupId, navigate]);

  // Use an edit button where we normally have an add button.
  // Many users have asked over the years about how the add students
  const editButton = useMemo(() => (
    <EnhancedButton
      onClick={handleNavigateToEditGroupPage}
      size="small"
      startIcon={<EditIcon />}
      variant="outlined"
    >
      Group
    </EnhancedButton>
  ), [handleNavigateToEditGroupPage]);

  const extraColumns = useMemo<GridColDef[]>(
    () => {
      const handleClickEmailGroupMember = (row: GQL.IUser) => {
        // Add user id to the recipient ids for a new email
        dispatch(updateRecipients([row.id]));
        navigate(`/${PATHS.EMAIL_NEW}`);
      };

      const actionsColumn = createDataGridActionsColumn<GQL.IUser>([
        ...(canReadMembers ? [{
          action: handleClickViewMember,
          icon: <PersonIcon />,
          text: 'View member',
        }] : []),
        ...(canEditMembers ? [{
          action: handlePressEditMember,
          icon: <EditIcon />,
          text: 'Edit member',
        }] : []
        ),
        ...(canWriteEmail ? [{
          action: handleClickEmailGroupMember,
          icon: <EmailIcon />,
          text: 'Email member',
        }] : []),
        ...(canEditGroups ? [{
          action: handleOpenSingleItemDeleteDialog,
          icon: <RemoveIcon fontSize="small" />,
          text: 'Remove from group',
        }] : []),
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canEditGroups,
      canEditMembers,
      canReadMembers,
      canWriteEmail,
      dispatch,
      handleClickViewMember,
      handleOpenSingleItemDeleteDialog,
      handlePressEditMember,
      navigate,
    ],
  );

  const {
    isSendStatementDialogOpen,
    isUpdatingStudents,
    memberIdsToAssignToGroup,
    memberIdsToSendStatementsTo,
    onCloseAssignMembersToGroupDialog,
    onCloseSendStatementDialog,
    toolbarActions,
  } = useMemberToolbarActions({
    filteredMemberIds: filteredRowIds as string[],
    selectedMemberIds: selectedGroupMemberIds as string[],
    showSendStatementsToEveryone: false,
    withImports: false,
  });

  const showSelection = canReadFinances || canWriteEmail || canEditGroups;

  const columns = useColumns(extraColumns);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          checkboxSelection={canEditGroups || (canReadUsers && canWriteEmail)}
          columns={columns}
          customAddButton={canEditGroups ? editButton : undefined}
          loading={isLoadingUsers || isLoading}
          onFilter={setFilteredRowIds}
          onSelectionModelChange={setSelectedGroupMemberIds}
          rows={data ?? []}
          selectionModel={selectedGroupMemberIds}
          tableResource="group"
          toolbarActions={toolbarActions}
          withSearch
          zeroStateMessage="Add new members to see them here."
        />
      </DataGridContainer>

      {showSelection && (
        <MemberToolbarActionDialogs
          currentGroupId={groupId}
          isSendStatementDialogOpen={isSendStatementDialogOpen}
          isUpdatingStudents={isUpdatingStudents}
          memberIdsToAssignToGroup={memberIdsToAssignToGroup}
          memberIdsToSendStatementsTo={memberIdsToSendStatementsTo}
          onCloseAssignMembersToGroupDialog={onCloseAssignMembersToGroupDialog}
          onCloseSendStatementDialog={onCloseSendStatementDialog}
        />
      )}

      <DeleteDialog
        actionVerb="Remove"
        additionalVariables={{ id: groupId }}
        clearCachePredicates={['groupUsers', 'user']}
        context={['user', 'users', 'group']}
        isOpen={isRemoveGroupMemberDialogOpen}
        mutation={REMOVE_MEMBERS_FROM_GROUP}
        onClose={toggleRemoveGroupMemberDialog}
        onRemoveSelectionAll={handleRemoveSelectionAll}
        reduxTableKey="group"
        refetchQueries={() => [
          getOperationName(GET_GROUP) as string,
        ]}
        singleItemId={singleItemDeleteId}
        size={fullCount}
      />
    </>
  );
};

export default GroupTableUI;
