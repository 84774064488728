// External Dependencies
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CartHeartIcon from 'mdi-material-ui/CartHeart';
import LicenseIcon from 'mdi-material-ui/CardAccountDetailsOutline';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  DashboardCard,
  SupportLink,
  ZeroStateIcon,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { openContactUs } from 'utils/helpScout';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  hasLicense?: boolean;
  isDistrictAdmin?: boolean;
}

// Component Definition
const PurchaseALicenseCard = ({
  hasLicense = false,
  isDistrictAdmin = false,
}: Props): JSX.Element => {
  const {
    palette: {
      prestoPrimaryLight,
    },
  } = useTheme();
  const navigate = useNavigate();

  const licenseParams = useSelector(tableQueryParams('licenses'));

  const handleNavigateToLicensesTable = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.LICENSES}${licenseParams}`);
  }, [licenseParams, navigate]);

  const iconElement = useMemo(() => (
    <ZeroStateIcon
      Icon={hasLicense ? LicenseIcon : CartHeartIcon}
      htmlColor={prestoPrimaryLight}
    />
  ), [hasLicense, prestoPrimaryLight]);

  const content = useMemo(() => (
    <>
      {iconElement}

      <Box
        px={2}
        textAlign="center"
      >
        {!hasLicense ? (
          <>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Ready to purchase {APP_NAME} for your{' '}
              {isDistrictAdmin ? 'district' : 'organization'}?

            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              <SupportLink
                buttonText="Learn about purchase options."
                slug="purchase-a-presto-license"
              />
            </Typography>
          </>
        ) : (
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Manage licenses for the organizations in your district.
          </Typography>
        )}
      </Box>

      {hasLicense ? (
        <Button
          onClick={handleNavigateToLicensesTable}
          size="small"
          variant="outlined"
        >
          View Licenses
        </Button>
      ) : (
        <Button
          onClick={openContactUs}
          size="small"
          variant="outlined"
        >
          Ask us how
        </Button>
      )}
    </>
  ), [handleNavigateToLicensesTable, hasLicense, isDistrictAdmin, iconElement]);

  return (
    <DashboardCard
      content={content}
      horizontallyCenterContent
      title={`${hasLicense ? 'Manage Licenses' : 'Purchase a License'}`}
      verticallyCenterContent
    />
  );
};

export default PurchaseALicenseCard;
