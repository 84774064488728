// External Dependencies
import { useDispatch } from 'react-redux';
import {
  useMemo, useState,
} from 'react';
import type { GridColDef } from '@mui/x-data-grid-pro';

// Internal Dependencies
import { ConfirmationDialog, TableDataGrid } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { useActivateUser } from 'gql/mutations';
import { useGetOrganizationSchoolYear } from 'hooks/useGetOrganizationSchoolYear';
import { useHasPermission } from 'state/self/selectors';
import { usePreviousMembers } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  schoolYearEnding: number;
}

// Component Definition
const MembersTable = ({
  schoolYearEnding,
}: Props): JSX.Element => {
  const currentSchoolYearEnding = useGetOrganizationSchoolYear();

  const [activateUserOrgId, setActivateUserOrgId] = useState<string | null>(null);

  const dispatch = useDispatch();

  const {
    data,
    loading: isLoading,
  } = usePreviousMembers(schoolYearEnding);

  const hasPermissionToEditUsers = useHasPermission('users', 'edit');
  const canAddUsers = useHasPermission('users', 'write');

  const handleSetActivateUserId = (row: GQL.IMemberIndex) =>
    setActivateUserOrgId(row.organizationMembershipId);

  const handleClose = () => setActivateUserOrgId(null);

  const handleCompleted = () => {
    dispatch(addNotification('User activated!', 'success'));
    handleClose();
  };

  const {
    data: currentMembers,
  } = usePreviousMembers(currentSchoolYearEnding);

  const [
    activateUser,
    {
      loading: isSubmitting,
    },
  ] = useActivateUser({
    onCompleted: handleCompleted,
  });

  const handleConfirm = () => {
    if (activateUserOrgId) {
      activateUser({
        variables: { userOrganizationId: activateUserOrgId },
      });
    }
  };

  const extraColumns = useMemo<GridColDef<GQL.IMemberIndex>[]>(
    () => {
      const activeCurrentMemberIds = currentMembers?.allMembers
        .filter((member) => member.isActive)
        .map((member) => member.id) ?? [];

      const actionsColumn = createDataGridActionsColumn<GQL.IMemberIndex>([
        ...(canAddUsers ? [{
          action: handleSetActivateUserId,
          isDisabled: (row: GQL.IMemberIndex) => activeCurrentMemberIds.includes(row.id),
          text: 'Activate',
        }] : []),
      ]);

      return actionsColumn ? [actionsColumn] : [];
    },
    [
      canAddUsers,
      currentMembers,
    ],
  );

  const columns = useColumns(extraColumns);

  const handleTableClickRow = (id: string) => `/${PATHS.STUDENTS}/${id}`;

  return (
    <DataGridContainer>
      <TableDataGrid
        clickRowTo={handleTableClickRow}
        columns={columns}
        loading={isLoading}
        rows={data?.allMembers ?? []}
        tableResource="previousMembers"
        withEditMode={hasPermissionToEditUsers}
        withSearch
      />

      <ConfirmationDialog
        canBeUndone
        confirmButtonAction={handleConfirm}
        declineButtonAction={handleClose}
        description="Are you sure you want to activate this user?"
        handleClose={handleClose}
        isSubmitting={isSubmitting}
        open={!!activateUserOrgId}
        title="Activate user"
      />
    </DataGridContainer>
  );
};

export default MembersTable;
