import { ClassLinkClass } from '@presto-assistant/api_types/api/v1/classLink';
import { DataGridColDef } from 'types/dataGrid';
import {
  EnhancedCard, Page, Subtitle, TableDataGrid,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { capitalize } from 'utils';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { useGetClasses } from 'utils/api/classlink';
import { useMemo } from 'react';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

interface Props {
  schoolId: string;
}

const ClassLinkImportClasses = ({
  schoolId,
}: Props): JSX.Element => {
  const {
    data: classesData,
    isLoading,
  } = useGetClasses(schoolId);

  const columns = useMemo<DataGridColDef<ClassLinkClass>[]>(() => {
    return [
      {
        field: 'title',
        headerName: 'Title',
      },
      {
        field: 'classType',
        headerName: 'Class Type',
        valueFormatter: ({ value }) => capitalize(value),
      },
      {
        field: 'status',
        headerName: 'Status',
        valueFormatter: ({ value }) => capitalize(value),
      },
      dateTimeColumn({
        field: 'dateLastModified',
        headerName: 'Last Modified',
      }),
    ];
  }, []);

  return (
    <Page
      backButtonProps={{
        label: 'Schools',
        path: `/${PATHS.CLASS_LINK_IMPORT}`,
      }}
    >
      <Subtitle>
        ClassLink Classes
      </Subtitle>

      <EnhancedCard>
        <DataGridContainer>
          <TableDataGrid
            clickRowTo={(id) => `/${PATHS.CLASS_LINK_IMPORT}?schoolId=${schoolId}&classId=${id}`}
            columns={columns}
            getRowId={(row: ClassLinkClass) => row.sourcedId ?? ''}
            loading={isLoading}
            rows={classesData?.data.classes ?? []}
          />
        </DataGridContainer>
      </EnhancedCard>
    </Page>
  );
};

export default ClassLinkImportClasses;
