// External Dependencies
import { FC } from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/SaveAlt';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  DatePickerField,
  EnhancedCard,
  Flex,
} from 'components/shared';
import { useGetFileSignedUrl, useGetFilesDirectoriesIndex } from 'gql/queries';
import CustomAutoComplete from 'components/shared/CustomAutoComplete';
import SchoolYearSelect from 'components/shared/Selectors/SchoolYearSelect';
import SectionTitle from 'components/shared/MainContentContainer/SectionTitle';

// Local Dependencies
import FileAssignmentFormList from './FileAssignmentFormList';

// Local Typings
interface Props {
  assignments: GQL.IFileAssignmentsInput[] | null;
  fieldNamePrefix?: string;
  fileId?: string;
  isPublic: boolean;
  maxDate?: string | null;
  minDate?: string | null;
  onDeleteFile?: () => void;
}
export interface FormValues {
  assignments: GQL.IFileAssignmentsInput[] | null;
  directory: string;
  endDate: string | null;
  fileName: string;
  isPublic: boolean;
  schoolYearEnding: number;
  startDate: string | null;
}

// Component Definition
const FileDetailsForm: FC<Props> = ({
  assignments,
  fieldNamePrefix = '',
  fileId,
  isPublic,
  maxDate,
  minDate,
  onDeleteFile,
}) => {
  const {
    data: directoryData,
  } = useGetFilesDirectoriesIndex();

  const getName = (field: string) => `${fieldNamePrefix}${field}`;

  const [getSignedUrl] = useGetFileSignedUrl();

  if (!directoryData || directoryData.length === 0) {
    return null;
  }

  const directoryOptions = directoryData.map(({ directory }) => directory);

  const handleDownloadFile = () => {
    if (fileId) {
      getSignedUrl({ variables: { id: fileId } });
    }
  };

  return (
    <EnhancedCard sx={{ marginBottom: 2 }}>
      <CardContent>
        <Flex justifyContent="space-between">
          <SectionTitle title="Details" />

          {onDeleteFile && (
            <IconButton
              onClick={onDeleteFile}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          )}

          {fileId && (
            <Tooltip
              placement="top"
              title="Download file"
            >
              <IconButton
                onClick={handleDownloadFile}
                size="large"
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          )}
        </Flex>

        <Box mb={2}>
          <Flex alignItems="flex-start">
            <Box
              flexGrow={1}
              minWidth={300}
              mx={1}
            >
              <CustomInput
                label="File Name"
                name={getName('fileName')}
              />
            </Box>

            <Box
              flexGrow={1}
              minWidth={300}
              mx={1}
            >
              <CustomAutoComplete
                disableClearable
                freeSolo
                fullWidth
                label="Directory"
                name={getName('directory')}
                options={directoryOptions}
              />
            </Box>
          </Flex>
        </Box>

        <Box mb={2}>
          <Flex alignItems="flex-start">
            <Box
              flexGrow={1}
              mx={1}
            >
              <SchoolYearSelect
                includeAllYearsOption
                name={getName('schoolYearEnding')}
                required
              />
            </Box>

            <Box
              flexGrow={1}
              mx={1}
            >
              <DatePickerField
                disableFuture={false}
                label="Start Date"
                maxDate={maxDate ? moment(maxDate) : undefined}
                name={getName('startDate')}
              />
            </Box>

            <Box
              flexGrow={1}
              mx={1}
            >
              <DatePickerField
                disableFuture={false}
                label="End Date"
                minDate={minDate ? moment(minDate) : undefined}
                name={getName('endDate')}
              />
            </Box>
          </Flex>
        </Box>

        <Box mx={1}>
          <CustomCheckbox
            label="Is visible to all users?"
            name={getName('isPublic')}
          />
        </Box>

        {!isPublic && (
          <FileAssignmentFormList
            assignments={assignments}
            getName={getName}
          />
        )}
      </CardContent>
    </EnhancedCard>
  );
};

export default FileDetailsForm;
