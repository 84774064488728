// External Dependencies
import { LazyQueryResult, gql } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { hasPermission } from 'state/self/selectors';
import {
  useLazyQueryEnhanced,
  useQueryEnhanced,
} from 'utils/lib/graphql';
import { usePaginatedListQuery } from 'hooks/usePaginatedListQuery';

// Local Typings
interface GroupsUserItem {
  email: GQL.IUser['email'];
  firstName: GQL.IUser['firstName'];
  id: GQL.IUser['id'];
  lastName: GQL.IUser['lastName'];
}

interface GroupsIndexResponse {
  groupsIndex: GQL.IGroupsIndexAll;
}
interface GroupsIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
}
export interface GroupsUsersResponse {
  groupsUsers: GroupsUserItem[];
}
export interface SimpleGroup {
  id: string;
  isPrimary: boolean;
  label: string;
  userCount: number;
}
interface GroupsAllResponse {
  groups: {
    data: SimpleGroup[];
  };
}
interface GroupsBySchoolYearResponse {
  groupsBySchoolYear: {
    data: SimpleGroup[];
  };
}
interface GroupUsersResponse {
  groupUsers: GQL.IGroupUsersAll;
}
interface GroupUsersVariables {
  groupId: string;
  queryParams?: GQL.IIndexQueryParams;
}
export interface GroupResponseData {
  group: GQL.IGroup;
}

const GET_GROUPS_ALL = gql`
  query GroupsAll {
    groups(queryParams: {
      orderBy: "label"
    }) {
      data {
        id
        isPrimary
        label
        userCount
      }
    }
  }
`;

const GET_GROUPS_BY_SCHOOL_YEAR = gql`
  query GroupsBySchoolYear($schoolYearEnding: Int) {
    groupsBySchoolYear(schoolYearEnding: $schoolYearEnding) {
      data {
        id
        isPrimary
        label
        userCount
      }
    }
  }
`;

export const GET_GROUP = gql`
  query Group($id: ID!) {
    group(id: $id) {
      id
      isPrimary
      label
      organization {
        label
      }
      users {
        userList {
          id
        }
      }
    }
  }
`;

export const GET_GROUPS_INDEX = gql`
  query GroupsIndex(
    $queryParams: IndexQueryParams
  ) {
    groupsIndex(
      queryParams: $queryParams
    ) {
      data {
        id
        isPrimary
        label
        memberCount
      }
      fullCount
    }
  }
`;

export const GET_GROUPS_INDEX_REPORT = gql`
  query GroupsIndexReport(
    $queryParams: IndexQueryParams
  ) {
    groupsIndex(
      queryParams: $queryParams
    ) {
      fullCount
    }
  }
`;

export const GET_GROUPS_USERS = gql`
  query GroupsUsers($selection: Selection!) {
    groupsUsers(selection: $selection) {
      email
      id
      firstName
      lastName
    }
  }
`;

export const GET_GROUP_USERS = gql`
  query GroupUsers(
    $groupId: ID!
    $queryParams: IndexQueryParams
  ) {
    groupUsers(
      groupId: $groupId
      queryParams: $queryParams
    ) {
      data {
        email
        id
        firstName
        lastName
        role {
          id
          label
        }
      }
      fullCount
    }
  }
`;

export const useGetGroupsIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
): [
    (
    ) => void,
    LazyQueryResult<GroupsIndexResponse, GroupsIndexVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<GroupsIndexResponse, GroupsIndexVariables>({
    gqlQuery,
    options,
    tableResource: 'groups',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetGroupUsersIndexItemsQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
  groupId: string,
): [
    (
    ) => void,
    LazyQueryResult<GroupUsersResponse, GroupUsersVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<GroupUsersResponse, GroupUsersVariables>({
    getAdditionalVariables: () => ({ groupId }),
    gqlQuery,
    options,
    tableResource: 'group',
  });

  return [
    apiRequest,
    values,
  ];
};

// Deprecated, but used by EOY Groups Table
export const useGetGroupsIndexAll = () => {
  const [query, values] = useGetGroupsIndexItemsQuery(
    GET_GROUPS_INDEX,
    { exportReport: false },
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useGetGroupsIndex = () => usePaginatedListQuery<
  GroupsIndexResponse,
  GQL.IGroupIndexItem
  >({
    dataSelector: (data) => data.groupsIndex.data,
    fullCountSelector: (data) => data.groupsIndex.fullCount,
    query: GET_GROUPS_INDEX,
  });

export const useDownloadGroupsIndexItems = () =>
  useGetGroupsIndexItemsQuery(
    GET_GROUPS_INDEX_REPORT,
    { exportReport: true },
  );

export const useGetGroupsUsers = (onCompleted: (data: GroupsUsersResponse) => void) =>
  useLazyQueryEnhanced<GroupsUsersResponse, GQL.IGroupsUsersOnQueryArguments>(
    GET_GROUPS_USERS,
    {
      onCompleted,
    },
  );

export const useGetGroupsAll = () => {
  const canReadGroups = useSelector(hasPermission('groups', 'read'));

  return useQueryEnhanced<GroupsAllResponse>(
    GET_GROUPS_ALL,
    {
      skip: !canReadGroups,
    },
  );
};

export const useGetGroupsBySchoolYear = (schoolYearEnding?: number, isPrimary?: boolean) => {
  const result = useQueryEnhanced<GroupsBySchoolYearResponse>(GET_GROUPS_BY_SCHOOL_YEAR, {
    variables: { schoolYearEnding },
  });

  const sortedData = useMemo(() => {
    if (!result.data) { return result.data; }
    const sortedGroups = result.data.groupsBySchoolYear.data.slice().sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    const filteredGroups = isPrimary === undefined
      ? sortedGroups
      : sortedGroups.filter((group) => group.isPrimary === isPrimary);

    return {
      ...result.data,
      groupsBySchoolYear: {
        ...result.data.groupsBySchoolYear,
        data: filteredGroups,
      },
    };
  }, [isPrimary, result.data]);

  return { ...result, data: sortedData };
};

const useGetGroupUsersIndexQuery = () => {
  return gql`
    query GroupUsers(
      $groupId: ID!
    ) {
      groupUsers(
        groupId: $groupId
      ) {
        data {
          email
          id
          firstName
          lastName
          role {
            id
            label
          }
        }
        fullCount
      }
    }
  `;
};

export const useGetGroupUsersIndex = (groupId: string) => {
  const groupUsersIndexQuery = useGetGroupUsersIndexQuery();

  return usePaginatedListQuery<
    GroupUsersResponse, GQL.IUser, GQL.IGroupUsersOnQueryArguments>({
      dataSelector: (res) => res.groupUsers.data,
      fullCountSelector: (res) => res.groupUsers.fullCount,
      query: groupUsersIndexQuery,
      variables: { groupId },
    });
};

export const useGetGroup = (
  id?: string,
  onCompleted?: (data: GroupResponseData) => void,
) =>
  useQueryEnhanced<GroupResponseData>(
    GET_GROUP,
    {
      onCompleted,
      skip: !id,
      variables: {
        id,
      },
    },
  );
