// External Dependencies
import { CreateVancoFinancialPaymentPayload } from '@presto-assistant/api_types/api/v1/financialPayment';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictApolloCache } from 'gql/client';
import { evictQueryCache } from 'utils/lib/graphql';
import { useQueryClient } from '@tanstack/react-query';
import { useTanstackMutation } from 'utils/api/index';

export const useCreateVancoPayment = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: CreateVancoFinancialPaymentPayload) => {
    return apiClient.v1.financialPayment.createVancoPayment(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('financialPayment');
      evictApolloCache('myFinancialFees');
      evictApolloCache('myFinancialPayments');
      evictApolloCache('myFinancialFeesAll');
    },
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};

export const useDeletePayment = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: {
    body: {
      note: string;
    }
    params: {
      paymentId: string;
    }
  }) => {
    return apiClient.v1.financialPayment.deletePayment(payload);
  }, []);

  const queryClient = useQueryClient();

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictQueryCache(queryClient, [
        'financialCreditOverview',
        'financialCredits',
        'financialCreditsIndex',
        'financialPayment',
        'myFinancialFees',
        'myFinancialFeesAll',
        'myFinancialPayments',
      ]);
    },
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};
