// Internal Dependencies
import {
  CONTACT_US_PAGE_HREF,
  IS_LOCAL,
  IS_TEST,
} from 'utils/constants/app';
import { getFullName } from 'utils';
import type { UserOrgRole } from 'hooks/useGetUserOrgRole';

// Local Variables
// const helpScoutBeaconId = process.env.REACT_APP_HELPSCOUT_BEACON_ID;
const helpScoutBeaconSecureKey = process.env.REACT_APP_HELPSCOUT_BEACON_SECURE_KEY;

const helpScoutScript = `!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});
`;

// Ideally we would store this in the .env file, but the Help Scout Beacon
//  script only works if the ID is hardcoded in the script.
const helpScoutInitScript = `window.Beacon('init', '536dc3d5-e598-4c4c-875d-36c0357d941a')
`;

// Helper function to inject scripts into app.
export const injectScript = (targetId: string, script: string) => {
  // There should be a <script> tag already in the document with an id of targetId.
  const targetScriptElement = document.getElementById(targetId);

  if (targetScriptElement) {
    targetScriptElement.innerHTML = script;
  } else {
    // eslint-disable-next-line no-console
    console.error(
      `Did not find target ${targetId} script element to inject into.`,
    );
  }
};

// Generate HMAC signature for Help Scout
async function generateHmacSignature(secretKey: string, message: string): Promise<string> {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(secretKey);
  const messageData = encoder.encode(message);

  const cryptoKey = await window.crypto.subtle.importKey(
    'raw',
    keyData,
    {
      hash: 'SHA-256',
      name: 'HMAC',
    },
    false,
    ['sign'],
  );

  const signature = await window.crypto.subtle.sign('HMAC', cryptoKey, messageData);
  const signatureArray = Array.from(new Uint8Array(signature));
  return signatureArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}

export const initializeHelpScout = async (
  selfData: GQL.ISelf,
  userOrgRole: UserOrgRole,
) => {
  // The script will run everywhere except local or test environments
  if (IS_LOCAL || IS_TEST) {
    return;
  }

  injectScript('#helpScout', helpScoutScript);
  injectScript('#helpScoutInit', helpScoutInitScript);

  const isHelpScoutBeaconAvailable = Boolean(window.Beacon);

  if (selfData && isHelpScoutBeaconAvailable) {
    // Find organization name in selfData's userOrgData array,
    //  based on the currentOrgId
    const currentOrganizationName = selfData.userOrgData.find(
      (userOrg) => userOrg.organization.id === selfData.currentOrgId,
    )?.organization.label;

    // If the userOrgRole is District Admin, we'll use the district name
    //  instead of the organization name
    // The self data has a district memberships array.
    // Find the district where the self.id matches the districtMembership.id
    const districtName = selfData.districtMemberships.find(
      (districtMembership) => districtMembership.id === selfData.id,
    )?.district?.label;

    try {
      let signatureHex = '';

      // Generate HMAC sha256 signature for Help Scout using the
      //  Secure Key from this HelpScout documentation page:
      // https://secure.helpscout.net/settings/beacons/536dc3d5-e598-4c4c-875d-36c0357d941a/messaging
      if (helpScoutBeaconSecureKey) {
        signatureHex = await generateHmacSignature(helpScoutBeaconSecureKey, selfData.email);
      }

      // Append the user's organizational role to the organization label
      //  to help our team have context for any issues brought up in chat.
      const organizationNameWithRole = `${currentOrganizationName ?? districtName} — ${userOrgRole}`;

      // Add signature to use secure mode for Help Scout to
      //  allow users to access their chat history
      window.Beacon('identify', {
        company: organizationNameWithRole,
        email: selfData.email,
        jobTitle: selfData?.title,
        name: getFullName(selfData),
        ...signatureHex && { signature: signatureHex },
      });
    } catch (error) {
      console.error('Error generating HMAC signature:', error);
    }
  }
};

export const openContactUs = () => {
  const isHelpScoutBeaconAvailable = Boolean(window.Beacon);

  if (isHelpScoutBeaconAvailable) {
    // Open the Beacon widget if it is closed
    if (!window.Beacon('info')?.status.isOpened) {
      window.Beacon('open');
    }
    window.Beacon('navigate', '/ask/');
  } else {
    window.open(CONTACT_US_PAGE_HREF, 'noopener,noreferrer');
  }
};
