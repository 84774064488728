// External Dependencies
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { FC, FormEvent, useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import { CardDetails } from 'pages/Finances/MyFinancialPayments/New/shared/PaymentMethodCard';
import { ShowCardHeader } from 'components/shared';
import { addNotification } from 'state/notifications/actions';

// Local Dependencies
import {
  EnhancedCard,
  SaveButton,
} from '..';
import StripeInput from './StripeInput';

// Local Typings
interface Props {
  onSetCardDetails: (cardDetails: CardDetails) => void;
}

// Local Variables
const mapToCardDetails = (
  paymentMethod: PaymentMethod,
): CardDetails => {
  return {
    brand: paymentMethod.card?.brand ?? '',
    expMonth: paymentMethod.card?.exp_month?.toString() ?? '',
    expYear: paymentMethod.card?.exp_year?.toString() ?? '',
    lastFour: paymentMethod.card?.last4 ?? '',
    paymentMethodToken: paymentMethod.id,
  };
};

// Component Definition
const StripePaymentForm: FC<Props> = ({
  onSetCardDetails,
}) => {
  const [isSubmittingCardDetails, setIsSubmittingCardDetails] = useState(false);

  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event: FormEvent) => {
    setIsSubmittingCardDetails(true);

    event.preventDefault();

    const card = elements?.getElement(CardNumberElement);

    if (card == null) {
      return;
    }

    const paymentResult = await stripe?.createPaymentMethod({
      card,
      type: 'card',
    });

    if (paymentResult?.paymentMethod) {
      onSetCardDetails(mapToCardDetails(paymentResult.paymentMethod));
    }

    if (paymentResult?.error) {
      dispatch(addNotification(paymentResult.error.message ?? '', 'error'));
    }

    setIsSubmittingCardDetails(false);
  };

  const isSubmitting = isSubmittingCardDetails;

  return (
    <Container maxWidth="sm">
      <EnhancedCard>
        <ShowCardHeader title="Payment Method" />

        <form onSubmit={handleSubmit}>
          <CardContent>
            <Box
              marginY={2}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    autocomplete: 'cc-number',
                    component: CardNumberElement,
                  },
                }}
                fullWidth
                label="Credit Card Number"
                name="ccnumber"
                required
                variant="filled"
              />
            </Box>

            <Box
              display="flex"
              gap={2}
              marginY={2}
            >
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    autocomplete: 'cc-exp',
                    component: CardExpiryElement,
                  },
                }}
                fullWidth
                label="Expiration Date"
                name="ccexp"
                required
                variant="filled"
              />

              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: StripeInput,
                  inputProps: {
                    autocomplete: 'cc-csc',
                    component: CardCvcElement,
                  },
                }}
                fullWidth
                label="CVC"
                name="cvc"
                required
                variant="filled"
              />
            </Box>
          </CardContent>

          <CardActions>
            <Box
              display="flex"
              justifyContent="flex-end"
              marginTop={2}
              width="100%"
            >
              <SaveButton
                color="primary"
                disabled={!stripe || !elements}
                isSaving={isSubmitting}
                type="submit"
                variant="contained"
              >
                Next
              </SaveButton>
            </Box>
          </CardActions>
        </form>
      </EnhancedCard>
    </Container>
  );
};

export default StripePaymentForm;
