// External Dependencies
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent, { type CardContentProps } from '@mui/material/CardContent';
import CardHeader, { type CardHeaderProps } from '@mui/material/CardHeader';
import styled from 'styled-components';
import type { ReactElement } from 'react';

// Local Typings
interface Props {
  cardActions?: ReactElement;
  cardHeaderProps?: CardHeaderProps;
  content: string | ReactElement;
  horizontallyCenterContent?: boolean;
  title: string | ReactElement;
  verticalSpan?: number;
  verticallyCenterContent?: boolean;
}

interface StyledCardProps extends CardContentProps {
  $verticalSpan: number;
}

interface StyledCardContentProps extends CardContentProps {
  $horizontallyCenterContent?: boolean;
  $verticallyCenterContent?: boolean;
}

// Local Variables
const StyledCard = styled(Card)<StyledCardProps>(({
  $verticalSpan,
  theme,
}) => ({
  alignContent: 'space-between',
  display: 'grid',
  gridRow: `span ${$verticalSpan}`,
  gridTemplateRows: `${theme.spacing(6)} 1fr`,
  width: '100%',
}));

const StyledCardContent = styled(CardContent)<StyledCardContentProps>(({
  $horizontallyCenterContent,
  $verticallyCenterContent,
  theme,
}) => ({
  // This resets the default padding-bottom of 32px coming from MUI
  '&:last-child': {
    paddingBottom: theme.spacing(2),
  },
  alignItems: $verticallyCenterContent ? 'center' : 'end',
  display: 'grid',
  justifyItems: $horizontallyCenterContent ? 'center' : 'inherit',
}));

// Component Definition
const DashboardCard = ({
  cardActions,
  cardHeaderProps,
  content,
  horizontallyCenterContent,
  title,
  verticalSpan = 1,
  verticallyCenterContent,
  ...props
}: Props): JSX.Element => {
  return (
    <StyledCard
      $verticalSpan={verticalSpan}
      variant="outlined"
      {...props} // allows us to make a styled(DashboardCard)
    >
      <CardHeader
        subheader={title}
        {...cardHeaderProps}
      />

      <StyledCardContent
        $horizontallyCenterContent={horizontallyCenterContent}
        $verticallyCenterContent={verticallyCenterContent}
      >
        {content}
      </StyledCardContent>

      {cardActions && (
        <CardActions>
          <Box
            display="flex"
            flexGrow={1}
            justifyContent="flex-end"
          >
            {cardActions}
          </Box>
        </CardActions>
      )}
    </StyledCard>
  );
};

export default DashboardCard;
