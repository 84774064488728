// External Dependencies
import {
  CreateDocumentUploadRequest,
  DownloadDocumentUploadRequest,
  GetDocumentUploadsRequest,
} from '@presto-assistant/api_types/api/v1/documentUpload';
import { useCallback } from 'react';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useGetDocumentUploads = (params: GetDocumentUploadsRequest['params']) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.documentUploadClient.getDocumentUploads({
        idRef: params.idRef,
        tableRef: params.tableRef,
      });
    },
    queryKey: ['documentUploads', params.tableRef, params.idRef],
  });
};

export const useCreateDocumentUpload = () => {
  const mutationFn = useCallback((body: CreateDocumentUploadRequest['body']) => {
    return apiClient.v1.documentUploadClient.createDocumentUpload(body);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};

export const useDownloadDocumentUpload = () => {
  const mutationFn = useCallback((params: DownloadDocumentUploadRequest['params']) => {
    return apiClient.v1.documentUploadClient.downloadDocumentUpload(params);
  }, []);

  return useTanstackMutation({
    mutationFn,
  });
};
