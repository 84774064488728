// External Dependencies
import { useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import styled from 'styled-components';

// Internal Dependencies
import { TableColumn, displayCell } from 'components/shared/TableV2';

// Local Variables
const StyledCheckIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.success.dark,
}));

const renderCanPayOnline = (row: GQL.IFinancialFeeIndexItem) =>
  (row.onlinePaymentsEnabled ? <StyledCheckIcon fontSize="small" /> : '—');

export const useColumns = (
  extraColumns?: TableColumn<GQL.IFinancialFeeIndexItem>[],
): TableColumn<GQL.IFinancialFeeIndexItem>[] => useMemo(() => {
  const columns: TableColumn<GQL.IFinancialFeeIndexItem>[] = [
    {
      Header: 'First',
      accessor: (row) => displayCell(row.userFirstName),
      sortBy: 'userFirstName',
    },
    {
      Header: 'Last',
      accessor: (row) => displayCell(row.userLastName),
      sortBy: 'userLastName',
    },
    {
      Header: 'Item',
      accessor: (row) => displayCell(row.financialItemLabel),
      sortBy: 'financialItemLabel',
    },
    {
      Header: 'Item Price',
      accessor: (row) => displayCell(row.priceInCents, { format: 'money' }),
    },
    {
      Header: 'Payments Total',
      accessor: (row) => displayCell(row.paymentsTotalInCents, { format: 'money' }),
    },
    {
      Header: 'Credit Applied',
      accessor: (row) => displayCell(row.creditsAppliedInCents, { format: 'money' }),
    },
    {
      Header: 'Balance Due',
      accessor: (row) => displayCell(row.balanceDueInCents, { format: 'money' }),
      sortBy: 'balanceDueInCents',
    },
    {
      Header: 'Can Pay Online',
      accessor: renderCanPayOnline,
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
