// External Dependencies
import { getOperationName } from '@apollo/client/utilities';
import { useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from 'mdi-material-ui/Download';

// Internal Dependencies
import { DELETE_FILE } from 'gql/mutations';
import {
  GET_FILES_INDEX,
  GET_FILE_DIRECTORIES,
  useGetFileSignedUrl,
  useGetFilesIndexByDirectory,
} from 'gql/queries';
import { PATHS } from 'utils/constants/routes';
import { TableDataGrid } from 'components/shared';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { useHasPermission } from 'state/self/selectors';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import DeleteDialogV2, { type DeleteDialogV2Props } from 'components/shared/DeleteDialogV2';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';
import type { DataGridColDef } from 'types/dataGrid';

// Local Dependencies
import { useColumns } from './hooks';

// Local Typings
interface Props {
  directory: string;
}

// Component Definition
const AllFilesByDirectoryTable = ({
  directory,
}: Props): JSX.Element => {
  const [singleItemDeleteId, setSingleItemDeleteId] = useState<string | null>(null);

  const {
    handleClose: closeDeleteDialog,
    isOpen: isDeleteDialogOpen,
  } = useIsOpen();

  const canDeleteFiles = useHasPermission('s3Uploads', 'delete');
  const canCreateFiles = useHasPermission('s3Uploads', 'write');

  const formatDeletePayload = useCallback<DeleteDialogV2Props<GQL.IDeleteFileOnMutationArguments>['formatPayload']>(() => ({
    id: singleItemDeleteId ?? '',
  }), [singleItemDeleteId]);

  const [getSignedUrl] = useGetFileSignedUrl();

  const {
    data,
    isLoading,
  } = useGetFilesIndexByDirectory(directory);

  const handleOpenSingleItemDeleteDialog = useCallback((row: GQL.IFileIndexItem) => {
    setSingleItemDeleteId(row.id);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setSingleItemDeleteId(null);
    closeDeleteDialog();
  }, [closeDeleteDialog]);

  const handleClickDownload = useCallback((row: GQL.IFileIndexItem) => {
    getSignedUrl({
      variables: { id: row.id },
    });
  }, [getSignedUrl]);

  const handleTableClickRow = useCallback((id: string) =>
    `/${PATHS.FILES}/${directory}/${id}`, [directory]);

  const extraColumns = useMemo<DataGridColDef<GQL.IFileIndexItem>[]>(() => {
    const actionsColumn = createDataGridActionsColumn<GQL.IFileIndexItem>([
      {
        action: handleClickDownload,
        icon: <DownloadIcon />,
        text: 'Download',
      },
      ...(canDeleteFiles ? [
        {
          action: handleOpenSingleItemDeleteDialog,
          icon: <DeleteIcon />,
          text: 'Delete',
        },
      ] : []),
    ]) as DataGridColDef<GQL.IFileIndexItem>;

    return actionsColumn ? [actionsColumn] : [];
  }, [canDeleteFiles, handleClickDownload, handleOpenSingleItemDeleteDialog]);

  const columns = useColumns(extraColumns);

  return (
    <>
      <DataGridContainer>
        <TableDataGrid
          addButtonProps={canCreateFiles ? {
            label: 'Files',
            to: `/${PATHS.FILES}/new?directory=${directory}`,
          } : null}
          clickRowTo={handleTableClickRow}
          columns={columns}
          components={{
            NoRowsOverlay: TableDataGridZeroState,
          }}
          loading={isLoading}
          rows={data ?? []}
          tableResource="files"
          withSearch
        />
      </DataGridContainer>

      <DeleteDialogV2<GQL.IDeleteFileOnMutationArguments>
        context={['file']}
        formatPayload={formatDeletePayload}
        hasSelection={false}
        isOpen={isDeleteDialogOpen || !!singleItemDeleteId}
        mutation={DELETE_FILE}
        onClose={handleCloseDeleteDialog}
        refetchQueries={() => [
          getOperationName(GET_FILE_DIRECTORIES) as string,
          getOperationName(GET_FILES_INDEX) as string,
        ]}
      />
    </>
  );
};

export default AllFilesByDirectoryTable;
