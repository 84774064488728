// External Dependencies
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  Container,
  CustomInput,
  EnhancedAlert,
  EnhancedCard,
  Flex,
  Page,
  PrestoLogoVerticalSvg,
  ShowCardHeader,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { UPLOAD_FILE_TYPES } from 'utils/constants/files';
import { convertDollarsToCents } from '@presto-assistant/api_types/utils';
import { getErrorMessage } from 'utils/api';
import { getToken } from 'utils/cookies';
import { useGetPurchaseOrderPresignedUrl, useUploadPurchaseOrder } from 'utils/api/open';
import { yup } from 'utils/yup';
import Avatar from '@mui/material/Avatar';
import UploadDropzone from 'components/shared/UploadDropzone';
import styled from 'styled-components';
import useSelfQuery from 'hooks/useSelfQuery';

// Form Schema
const purchaseOrderSchema = yup.object().shape({
  amount: yup
    .string()
    .required('Please enter an amount')
    .isPositive('Amount must be positive'),
  invoiceNumber: yup
    .string()
    .required('Please enter an invoice number'),
  purchaseOrderNumber: yup
    .string()
    .required('Please enter a purchase order number'),
  submittedByEmail: yup
    .string()
    .email('Please enter a valid email')
    .required('Please enter an email'),
  submittedByFirstName: yup
    .string()
    .required('Please enter a first name'),
  submittedByLastName: yup
    .string()
    .required('Please enter a last name'),
});

// Form Types
interface PurchaseOrderFormValues {
  amount: string;
  invoiceNumber: string;
  purchaseOrderNumber: string;
  submittedByEmail: string;
  submittedByFirstName: string;
  submittedByLastName: string;
}

// Local Variables
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark'
    ? theme.palette.stripeBlue['300']
    : theme.palette.stripeBlue['600'],
}));

const LogoContainer = styled.div(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),
    maxWidth: 272,
  },
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(4),
  marginTop: theme.spacing(4),
  maxWidth: 360,
  width: '100%',
}));

// Component Definition
const PurchaseOrderUpload: FC = () => {
  const navigate = useNavigate();

  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [uploadErrors, setUploadErrors] = useState<string[]>([]);
  const [hasUploadError, setHasUploadError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const isAuthenticated = Boolean(getToken());
  const { self } = useSelfQuery({ skip: !isAuthenticated });

  const { mutateAsync: getPresignedUrl } = useGetPurchaseOrderPresignedUrl({
    suppressErrorNotification: true,
  });

  const { mutateAsync: uploadPurchaseOrder } = useUploadPurchaseOrder({
    suppressErrorNotification: true,
  });

  // Initial form values with user data if authenticated
  const initialValues = useMemo<PurchaseOrderFormValues>(() => ({
    amount: '',
    invoiceNumber: '',
    purchaseOrderNumber: '',
    submittedByEmail: isAuthenticated && self ? self.email || '' : '',
    submittedByFirstName: isAuthenticated && self ? self.firstName || '' : '',
    submittedByLastName: isAuthenticated && self ? self.lastName || '' : '',
  }), [isAuthenticated, self]);

  const handleDrop = useCallback((files: File[]) => {
    if (!files.length) { return; }

    // Store the selected file
    setSelectedFile(files[0]);

    // Reset error states
    setHasUploadError(false);
    setUploadErrors([]);
    setIsSuccess(false);
  }, []);

  const handleRemoveFile = useCallback(() => {
    setSelectedFile(null);
    setHasUploadError(false);
    setUploadErrors([]);
  }, []);

  const handleSubmit = useCallback(async (
    values: PurchaseOrderFormValues,
    { setSubmitting }: FormikHelpers<PurchaseOrderFormValues>,
  ) => {
    if (!selectedFile) {
      setHasUploadError(true);
      setUploadErrors(['Please select a file to upload']);
      setSubmitting(false);
      return;
    }

    setIsUploadingFiles(true);
    setHasUploadError(false);
    setUploadErrors([]);

    try {
      // Convert amount to cents
      const amountInCents = convertDollarsToCents(parseFloat(values.amount));
      const invoiceNumberValue = parseInt(values.invoiceNumber, 10);

      if (Number.isNaN(amountInCents)) {
        throw new Error('Please enter a valid amount');
      }

      if (Number.isNaN(invoiceNumberValue)) {
        throw new Error('Please enter a valid invoice number');
      }

      // Step 1: Get presigned URL
      const presignedUrlResponse = await getPresignedUrl({
        amountInCents,
        invoiceNumber: invoiceNumberValue,
      });

      if (!presignedUrlResponse.data.data.getUrl) {
        throw new Error('Could not get presigned URL');
      }

      // Step 2: Upload file to S3
      await fetch(presignedUrlResponse.data.data.putUrl, {
        body: selectedFile,
        headers: {
          'Content-Type': selectedFile.type,
        },
        method: 'PUT',
      });

      // Step 3: Notify backend about the upload
      await uploadPurchaseOrder({
        amountInCents,
        invoiceNumber: invoiceNumberValue,
        purchaseOrderNumber: values.purchaseOrderNumber,
        s3Url: presignedUrlResponse.data.data.getUrl,
        submittedByEmail: values.submittedByEmail,
        submittedByFirstName: values.submittedByFirstName,
        submittedByLastName: values.submittedByLastName,
      });

      setIsSuccess(true);
    } catch (error) {
      console.error('Error uploading file:', error);
      setHasUploadError(true);

      setUploadErrors([getErrorMessage(error)]);
    } finally {
      setIsUploadingFiles(false);
      setSubmitting(false);
    }
  }, [
    getPresignedUrl,
    selectedFile,
    uploadPurchaseOrder,
  ]);

  const handleGoToLogin = useCallback(() => {
    navigate(`/${PATHS.LOGIN}`);
  }, [navigate]);

  const handleGoToDashboard = useCallback(() => {
    navigate(`/${PATHS.DASHBOARD}`);
  }, [navigate]);

  const handleLogoClick = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <Page>
      {!isAuthenticated && (
        <Flex
          justifyContent="center"
          width="100%"
        >
          <LogoContainer onClick={handleLogoClick}>
            <PrestoLogoVerticalSvg />
          </LogoContainer>
        </Flex>
      )}
      <Container maxWidth={600}>
        <EnhancedCard sx={{ marginBottom: 3 }}>
          <ShowCardHeader
            avatar={(
              <StyledAvatar>
                <ReceiptIcon />
              </StyledAvatar>
            )}
            title="Upload Purchase Order"
          />
          <CardContent>
            <Typography
              paragraph
              variant="body1"
            >
              Upload a purchase order file to be processed.
              Accepted file types are PDF, JPEG, and PNG.
            </Typography>

            {isSuccess ? (
              <Box mb={3}>
                <EnhancedAlert
                  severity="success"
                  title="Purchase order uploaded successfully"
                >
                  <Typography variant="body2">
                    Your purchase order has been uploaded and will be processed shortly.
                  </Typography>
                  <Box mt={2}>
                    <Button
                      color="primary"
                      onClick={isAuthenticated ? handleGoToDashboard : handleGoToLogin}
                      variant="contained"
                    >
                      {isAuthenticated ? 'Go to Dashboard' : 'Go to Login'}
                    </Button>
                  </Box>
                </EnhancedAlert>
              </Box>
            ) : (
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={purchaseOrderSchema}
              >
                {({
                  handleSubmit: formikHandleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={formikHandleSubmit}>
                    <Box mb={3}>
                      <CustomInput
                        disabled={isUploadingFiles || isSubmitting}
                        isCurrency
                        label="Amount"
                        name="amount"
                        placeholder="0.00"
                      />
                      <CustomInput
                        disabled={isUploadingFiles || isSubmitting}
                        htmlInputProps={{ min: '0' }}
                        label="Invoice Number"
                        name="invoiceNumber"
                        placeholder="123456"
                        type="number"
                      />
                      <CustomInput
                        disabled={isUploadingFiles || isSubmitting}
                        label="Purchase Order Number"
                        name="purchaseOrderNumber"
                        placeholder="PO-123456"
                        type="text"
                      />
                      <CustomInput
                        InputProps={{
                          readOnly: isAuthenticated,
                        }}
                        disabled={isUploadingFiles || isAuthenticated || isSubmitting}
                        label="Submitted by Email"
                        name="submittedByEmail"
                        placeholder="email@example.com"
                        type="email"
                      />
                      <CustomInput
                        InputProps={{
                          readOnly: isAuthenticated,
                        }}
                        disabled={isUploadingFiles || isAuthenticated || isSubmitting}
                        label="Submitted by First Name"
                        name="submittedByFirstName"
                        placeholder="John"
                        type="text"
                      />
                      <CustomInput
                        InputProps={{
                          readOnly: isAuthenticated,
                        }}
                        disabled={isUploadingFiles || isAuthenticated || isSubmitting}
                        label="Submitted by Last Name"
                        name="submittedByLastName"
                        placeholder="Doe"
                        type="text"
                      />
                    </Box>

                    {isUploadingFiles && (
                      <Box mb={3}>
                        <LinearProgress />
                      </Box>
                    )}

                    {!selectedFile ? (
                      <UploadDropzone
                        acceptedFileTypes={[
                          UPLOAD_FILE_TYPES.pdf,
                          UPLOAD_FILE_TYPES.jpeg,
                          UPLOAD_FILE_TYPES.png,
                        ]}
                        disabled={isUploadingFiles || isSubmitting}
                        handleDrop={handleDrop}
                        multiple={false}
                        rejectedDropErrorMessage="Please upload a valid purchase order file (PDF, JPEG, or PNG)."
                      />
                    ) : (
                      <Collapse
                        in={Boolean(selectedFile)}
                        timeout="auto"
                      >
                        <Flex marginY={2}>
                          <Chip
                            color="primary"
                            disabled={isUploadingFiles || isSubmitting}
                            icon={<AttachFileIcon fontSize="small" />}
                            label={selectedFile.name}
                            onDelete={isUploadingFiles || isSubmitting
                              ? undefined
                              : handleRemoveFile}
                            sx={{ margin: 0.5 }}
                            variant="outlined"
                          />
                        </Flex>
                      </Collapse>
                    )}

                    {hasUploadError && (
                      <Box mb={3}>
                        <EnhancedAlert
                          severity="error"
                          title="Error uploading file"
                        >
                          <Box component="div">
                            {uploadErrors.map((error) => (
                              <Typography
                                key={error}
                                variant="body2"
                              >
                                {error}
                              </Typography>
                            ))}
                          </Box>
                        </EnhancedAlert>
                      </Box>
                    )}

                    <CardActions sx={{ justifyContent: 'flex-end', mt: 3 }}>
                      <Button
                        color="primary"
                        disabled={isUploadingFiles || !selectedFile || isSubmitting}
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Submit Purchase Order
                      </Button>
                    </CardActions>
                  </Form>
                )}
              </Formik>
            )}
          </CardContent>
        </EnhancedCard>
      </Container>
    </Page>
  );
};

export default PurchaseOrderUpload;
