// External Dependencies
import { CreateDocumentUploadRequest } from '@presto-assistant/api_types/api/v1/documentUpload';
import {
  PropsWithChildren, useCallback, useState,
} from 'react';
import { useFormikContext } from 'formik';
import Collapse from '@mui/material/Collapse';
import LinearProgress from '@mui/material/LinearProgress';

// Internal Dependencies
import { CustomInput, EnhancedAlert, ShowPageDataDisplay } from 'components/shared/index';
import { apiClient } from 'utils/apiClient';
import UploadDropzone from 'components/shared/UploadDropzone';

// Local Typings
interface Props {
  idRef: string;
  isUploadingFile: boolean;
  setIsUploadingFile: (isUploadingFile: boolean) => void;
  tableRef: 'inventory_repairs';
}

// Component Definition
const CreateDocumentUploadDialogContent = ({
  idRef,
  isUploadingFile,
  setIsUploadingFile,
  tableRef,
}: PropsWithChildren<Props>): JSX.Element => {
  const formikContext = useFormikContext<CreateDocumentUploadRequest['body']>();

  const [filename, setFilename] = useState<string | null>(null);
  const [hasUploadError, setHasUploadError] = useState(false);

  const handleDrop = useCallback(async (droppedFiles: File[]) => {
    try {
      setHasUploadError(false);
      setIsUploadingFile(true);

      const {
        data: {
          data: {
            getUrl,
            putUrl,
          },
        },
      } = await apiClient.v1.documentUploadClient.getPresignedUrls({
        filename: droppedFiles[0].name,
        idRef,
        tableRef,
      });

      const s3Response = await fetch(putUrl, {
        body: droppedFiles[0],
        method: 'PUT',
      });

      if (!s3Response.ok) {
        throw new Error('Unable to upload file.');
      }

      formikContext.setFieldValue('s3Url', getUrl);

      if (!formikContext.values.filename) {
        formikContext.setFieldValue('filename', droppedFiles[0].name);
      }

      setFilename(droppedFiles[0].name);
    } catch (s3UploadError) {
      console.log('s3UploadError', s3UploadError);
      setHasUploadError(true);
    } finally {
      setIsUploadingFile(false);
    }
    // do not include formikContext here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filename,
    idRef,
    setIsUploadingFile,
    tableRef,
  ]);

  return (
    <>
      <Collapse in={!filename}>
        <>
          <UploadDropzone
            acceptedFileTypes={['*']}
            disabled={isUploadingFile}
            handleDrop={handleDrop}
            helperText="Upload a file up to 10 MB"
            marginTop={0}
            maxSizeInMb={10}
            rejectedDropErrorMessage="Unable to upload files"
          />

          {hasUploadError && (
            <EnhancedAlert
              severity="error"
              sx={{ marginTop: 2 }}
            >
              Unable to upload file
            </EnhancedAlert>
          )}
        </>
      </Collapse>

      <Collapse in={Boolean(filename)}>
        <>
          <ShowPageDataDisplay
            label="File Name"
            value={filename}
          />

          <CustomInput
            label="Description"
            name="description"
          />

          <CustomInput
            label="Comments"
            name="comments"
          />
        </>
      </Collapse>

      {isUploadingFile && (
        <LinearProgress
          sx={{ marginY: 1 }}
          variant="indeterminate"
        />
      )}
    </>
  );
};

export default CreateDocumentUploadDialogContent;
