// External Dependencies
import { EnhancedGetUsersResponse } from '@presto-assistant/api_types/api/v1/classLink';
import { GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CardActions from '@mui/material/CardActions';

// Internal Dependencies
import {
  ConfirmationDialog,
  EnhancedButton,
  EnhancedCard,
  Flex,
  Page,
  Subtitle,
  TableDataGrid,
} from 'components/shared';
import { DataGridColDef } from 'types/dataGrid';
import { PATHS } from 'utils/constants/routes';
import { SHORT_APP_NAME } from 'utils/constants';
import { capitalize } from 'utils';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { evictApolloCache } from 'gql/client';
import { openDialogSuccess } from 'state/ui/successDialog/actions';
import { useGetStudentsForClass, useProvisionStudents } from 'utils/api/classlink';
import { useIsOpen } from 'hooks/useIsOpen';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Typings
interface Props {
  classId: string;
  schoolId: string;
}

type EnhancedClassLinkUser = EnhancedGetUsersResponse['users'][0];

// Component Definition
const ClassLinkImportStudentsForClass = ({
  classId,
  schoolId,
}: Props): JSX.Element => {
  const [
    selectedRowIds,
    setSelectedRowIds,
  ] = useState<GridRowSelectionModel>([]);
  const {
    isOpen: isConfirmationDialogOpen,
    toggleIsOpen: toggleConfirmationDialogOpen,
  } = useIsOpen();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: studentsData,
    isLoading,
  } = useGetStudentsForClass(classId);

  const {
    isLoading: isSubmitting,
    mutate: provisionStudents,
  } = useProvisionStudents();

  const handleSubmit = useCallback(async () => {
    if (selectedRowIds.length > 0) {
      provisionStudents({
        classId,
        userIds: selectedRowIds as string[],
      }, {
        onSuccess: () => {
          toggleConfirmationDialogOpen();
          dispatch(openDialogSuccess({
            animationData: null,
            description: 'Your students have been imported successfully!',
            title: 'Students Imported',
          }));

          evictApolloCache('dashboardMetrics');
          evictApolloCache('studentsIndex');
          evictApolloCache('parentsIndex');
          evictApolloCache('inactiveUsersIndex');

          navigate(`/${PATHS.STUDENTS}`);
        },
      });
    }
  }, [
    classId,
    dispatch,
    navigate,
    provisionStudents,
    selectedRowIds,
    toggleConfirmationDialogOpen,
  ]);

  const columns = useMemo<DataGridColDef<EnhancedClassLinkUser>[]>(() => {
    return [
      {
        field: 'prestoMemberId',
        headerName: 'Imported',
        type: 'boolean',
      },
      {
        field: 'givenName',
        headerName: 'First Name',
      },
      {
        field: 'familyName',
        headerName: 'Last Name',
      },
      {
        field: 'status',
        headerName: 'Status',
        valueFormatter: ({ value }) => capitalize(value),
      },
      dateTimeColumn({
        field: 'dateLastModified',
        headerName: 'Last Modified',
      }),
    ];
  }, []);

  return (
    <Page
      backButtonProps={{
        label: 'School',
        path: `/${PATHS.CLASS_LINK_IMPORT}?schoolId=${schoolId}`,
      }}
    >
      <Subtitle>
        ClassLink Students for Class
      </Subtitle>

      <EnhancedCard>
        <DataGridContainer>
          <TableDataGrid
            checkboxSelection
            columns={columns}
            getRowId={(row: EnhancedClassLinkUser) => row.sourcedId}
            isRowSelectable={({ row }: { row: EnhancedClassLinkUser }) => !row.prestoMemberId}
            loading={isLoading}
            onSelectionModelChange={setSelectedRowIds}
            rows={studentsData?.data.users ?? []}
          />
        </DataGridContainer>

        <CardActions>
          <Flex
            justifyContent="flex-end"
            width="100%"
          >
            <EnhancedButton
              disabled={selectedRowIds.length === 0}
              onClick={toggleConfirmationDialogOpen}
            >
              Import Selected Students
            </EnhancedButton>
          </Flex>
        </CardActions>
      </EnhancedCard>

      <ConfirmationDialog
        confirmButtonAction={handleSubmit}
        declineButtonAction={toggleConfirmationDialogOpen}
        description={`This will import the selected students into your ${SHORT_APP_NAME} organization.`}
        handleClose={toggleConfirmationDialogOpen}
        isSubmitting={isSubmitting}
        open={isConfirmationDialogOpen}
        title="Import Students?"
      />
    </Page>
  );
};

export default ClassLinkImportStudentsForClass;
