// External Dependencies
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { openDialogSuccess } from 'state/ui/successDialog/actions';
import { useApplyAllFinancialCredits } from 'utils/api/financialCredits';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const ApplyAllCreditsDialog = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const {
    data: organizationData,
  } = useGetOrganization();

  const {
    mutate: applyAllFinancialCredits,
  } = useApplyAllFinancialCredits();

  const isIgnoringPreviousYearsFinances = organizationData?.organization
    .isIgnoringPreviousYearsFinances;

  const handleConfirmButtonAction = useCallback(() => {
    applyAllFinancialCredits({});
    onClose();
    dispatch(openDialogSuccess({
      animationData: null,
      description: 'Credits are being applied. Refresh your browser in a few minutes for updated balances.',
      title: 'Applying Credits',
    }));
  }, [
    applyAllFinancialCredits,
    dispatch,
    onClose,
  ]);

  return (
    <ConfirmationDialog
      confirmButtonAction={handleConfirmButtonAction}
      declineButtonAction={onClose}
      description={isIgnoringPreviousYearsFinances
        ? 'This will apply all available credits for the current school year and future years. Credits will be applied to fees with the highest balance first.'
        : 'This will apply all available credits for all school years. Credits will be applied to fees with the highest balance first.'}
      handleClose={onClose}
      open={isOpen}
      title="Apply All Credits?"
    />
  );
};

export default ApplyAllCreditsDialog;
