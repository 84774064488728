// External Dependencies
import { DistrictSsoTypes } from '@presto-assistant/api_types';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowForward';
import DisableIcon from '@mui/icons-material/RemoveCircleOutline';
import EnableIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  ConfirmationDialog,
  ListItemWithSecondaryAction,
  StyledLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { SHORT_APP_NAME } from 'utils/constants/app';
import { addNotification } from 'state/notifications/actions';
import { classLinkUrl } from 'utils/constants/urls';
import { useIsOpen } from 'hooks/useIsOpen';
import { useMyDistrict, useUpdateTenantId } from 'utils/api/district';
import { useParseToken } from 'utils/cookies';
import { useParsedSearch } from 'hooks/useParsedSearch';
import SsoSignInDialog from 'components/shared/SsoSignInDialog';

// Local Variables
const StyledArrowRightIcon = styled(ArrowRightIcon)({
  color: 'inherit',
  fontSize: '0.8em',
  marginLeft: '0.25em',
}) as typeof ArrowRightIcon;

// Component Definition
const ClassLinkRosteringSetting = (): JSX.Element | null => {
  const [shouldPresentEnabledInDialog, setShouldPresentEnabledInDialog] = useState(false);

  const { openSsoDialog, tenantIdType } = useParsedSearch();

  const [isEnableRosteringDialogOpen, setIsEnableRosteringDialogOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleToggleIsEnableRosteringDialogOpen = useCallback(() => {
    setIsEnableRosteringDialogOpen((prevState) => !prevState);
  }, []);

  const {
    data: districtData,
    isLoading: isDistrictLoading,
  } = useMyDistrict();

  const {
    isOpen: isSsoPromptDialogOpen,
    toggleIsOpen: toggleIsSsoPromptDialogOpen,
  } = useIsOpen();

  const parsedToken = useParseToken();

  const rosteringTenantId = districtData?.data.data.rosteringTenantId;

  const {
    isLoading: isUpdatingRosteringTenantId,
    mutate: updateTenantId,
  } = useUpdateTenantId();

  const handleSubmit = useCallback(() => {
    updateTenantId({
      isEnabled: !rosteringTenantId,
      type: 'rostering',
    }, {
      onSuccess: () => {
        dispatch(addNotification('ClassLink rostering setting updated successfully.', 'success'));
        handleToggleIsEnableRosteringDialogOpen();
      },
    });
  }, [
    dispatch,
    handleToggleIsEnableRosteringDialogOpen,
    rosteringTenantId,
    updateTenantId,
  ]);

  useEffect(() => {
    if (isEnableRosteringDialogOpen) {
      setShouldPresentEnabledInDialog(Boolean(rosteringTenantId));
    }
    // intentionally leaving out rosteringTenantId from this dependency array
    // this allows the dialog content to persist while closing
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnableRosteringDialogOpen]);

  const handleClickEnableRosteringButton = useCallback(() => {
    if (parsedToken?.ssoTenantId) {
      handleToggleIsEnableRosteringDialogOpen();
    } else {
      toggleIsSsoPromptDialogOpen();
    }
  }, [parsedToken, handleToggleIsEnableRosteringDialogOpen, toggleIsSsoPromptDialogOpen]);

  useEffect(() => {
    if (parsedToken?.ssoTenantId && openSsoDialog && tenantIdType === 'rostering') {
      setIsEnableRosteringDialogOpen(true);
    }
  }, [
    openSsoDialog,
    parsedToken?.ssoTenantId,
    tenantIdType,
  ]);

  useEffect(
    () => {
      if (isSsoPromptDialogOpen) {
        navigate({
          pathname: location.pathname,
          search: '?openSsoDialog=true&tenantIdType=rostering',
        }, {
          replace: true,
        });
      }
    },
    // do not include location.pathname in this dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSsoPromptDialogOpen, navigate],
  );

  useEffect(() => {
    if (isEnableRosteringDialogOpen) {
      navigate({
        pathname: location.pathname,
        search: '',
      }, {
        replace: true,
      });
    }
  }, [
    isEnableRosteringDialogOpen,
    navigate,
    location.pathname,
  ]);

  const handleClickNavigateToClassLink = useCallback(() => {
    navigate(`/${PATHS.DISTRICT_ADMIN}/${PATHS.CLASS_LINK_SCHOOLS}`);
  }, [navigate]);

  if (isDistrictLoading) {
    return null;
  }

  return (
    <>
      <ListItemWithSecondaryAction
        primaryText={<>ClassLink Rostering Enabled: <strong>{rosteringTenantId ? 'ON' : 'OFF'}</strong></>}
        secondaryAction={{
          alwaysShowButton: true,
          buttonIcon: rosteringTenantId ? <DisableIcon /> : <EnableIcon />,
          buttonText: rosteringTenantId ? 'Disable' : 'Enable',
          isLoading: isUpdatingRosteringTenantId,
          onClick: handleClickEnableRosteringButton,
        }}
        secondaryText={(
          <>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              Allow teachers in your district to easily import students from
              {' '}
              <StyledLink
                href={classLinkUrl}
                openInNewTab
              >
                ClassLink
              </StyledLink>.
            </Typography>

            {rosteringTenantId && (
              <Typography
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                <StyledLink onClick={handleClickNavigateToClassLink}>
                  Preview Roster
                  <StyledArrowRightIcon />
                </StyledLink>
              </Typography>
            )}
          </>
        )}
      />

      <SsoSignInDialog
        isOpen={isSsoPromptDialogOpen}
        onClose={toggleIsSsoPromptDialogOpen}
        ssoTypeId={DistrictSsoTypes.ClassLink}
      />

      <ConfirmationDialog
        confirmButtonAction={handleSubmit}
        confirmButtonText={shouldPresentEnabledInDialog ? 'Yes, disable' : 'Yes, enable'}
        declineButtonAction={handleToggleIsEnableRosteringDialogOpen}
        description={shouldPresentEnabledInDialog
          ? `This will prevent directors from accessing ClassLink data in ${SHORT_APP_NAME}.`
          : 'Allow directors in your district to quickly import students using ClassLink.'}
        handleClose={handleToggleIsEnableRosteringDialogOpen}
        isConfirmButtonDisabled={!parsedToken?.ssoTenantId}
        isSubmitting={isUpdatingRosteringTenantId}
        open={isEnableRosteringDialogOpen
          && !isSsoPromptDialogOpen
          && Boolean(parsedToken?.ssoTenantId)}
        title={shouldPresentEnabledInDialog ? 'Disable ClassLink Rostering?' : 'Enable ClassLink Rostering?'}
      />
    </>
  );
};

export default ClassLinkRosteringSetting;
