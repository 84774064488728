// External Dependencies
import { ApolloError } from '@apollo/client';
import {
  Form,
  Formik,
  FormikHelpers,
} from 'formik';
import { updateOnboardingContactInfoSchema } from '@presto-assistant/api_types/schemas/self';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import {
  CustomFormButton,
  CustomInput,
  EnhancedAlert,
  Flex,
  OnboardingStepTitle,
  StateSelector,
} from 'components/shared';
import { GET_SELF } from 'gql/queries';
import { UPDATE_ONBOARDING_CONTACT } from 'gql/mutations';
import { normalizeFormErrors } from 'utils/lib/graphql_errors';
import { trimValues } from 'utils/lib/trimValues';
import { useMutationEnhanced } from 'utils/lib/graphql';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { useClassLinkTokenInfo } from 'utils/api/auth';
import ExistingAddressCheckbox, { OnboardingContactValues } from './ExistingAddressCheckbox';
import OnboardingProgress from '../OnboardingProgress';

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: 12,
  },
  '.button': {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    margin: '16px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  '.description': {
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(1.5),
    },
    margin: theme.spacing(2, 2, 1),
  },
  '.zipcode': {
    marginLeft: 16,
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

const emptyInitialValues: OnboardingContactValues = {
  addressOne: '',
  addressTwo: '',
  city: '',
  email: '',
  phoneNumber: '',
  stateId: '',
  zipcode: '',
};

// Component Definition
const OnboardingUserContactInfo = () => {
  const [initialValues, setInitialValues] = useState<OnboardingContactValues>(emptyInitialValues);
  const [submitError, setSubmitError] = useState('');

  const { loading, self } = useSelfQuery();

  const {
    data: classLinkTokenInfoData,
  } = useClassLinkTokenInfo();

  const [
    updateOnboardingContact,
    { loading: isSubmittingUpdateSelf },
  ] = useMutationEnhanced(UPDATE_ONBOARDING_CONTACT);

  const handleUpdateInitialValues = useCallback((values: OnboardingContactValues) => {
    setInitialValues(values);
  }, []);

  // When the self data arrives, update the initialValues for the form
  useEffect(() => {
    if (self) {
      handleUpdateInitialValues({
        addressOne: self?.addressOne || '',
        addressTwo: self?.addressTwo || '',
        city: self?.city || '',
        email: self?.email || self?.authUserEmail || '',
        phoneNumber: self?.phoneNumber || classLinkTokenInfoData?.data.userInfo?.phone || '',
        stateId: self?.state?.id || '',
        zipcode: self?.zipcode || '',
      });
    }
  }, [
    classLinkTokenInfoData?.data,
    handleUpdateInitialValues,
    self,
  ]);

  const handleSubmitForm = useCallback(async (
    values: OnboardingContactValues,
    { setErrors }: FormikHelpers<OnboardingContactValues>,
  ) => {
    try {
      await updateOnboardingContact({
        refetchQueries: [{ query: GET_SELF }],
        variables: trimValues(values) as OnboardingContactValues,
      });
    } catch (err) {
      setSubmitError(
        'There was an error updating contact data. Please try again.',
      );
      setErrors(normalizeFormErrors(err as ApolloError));
    }
  }, [updateOnboardingContact]);

  const onboardingRole = self?.onboarding.onboardingRole as DB.User.OnboardingRole | undefined;
  const isOnboardingStudentOrParent = onboardingRole === 'parent' || onboardingRole === 'student';

  const existingAddressElement = useMemo(() => {
    if (!isOnboardingStudentOrParent && onboardingRole) {
      return (
        <Box marginLeft={1.5}>
          <ExistingAddressCheckbox />
        </Box>
      );
    }

    return null;
  }, [
    isOnboardingStudentOrParent,
    onboardingRole,
  ]);

  const buttonElement = useCallback((isSubmittingFormik: boolean) => {
    if (isSubmittingFormik || isSubmittingUpdateSelf) {
      return (
        <CircularProgress
          className="button"
          size={24}
          thickness={6}
        />
      );
    }

    return (
      <CustomFormButton
        buttonText="Update Contact"
        className="button"
        disabled={isSubmittingFormik || isSubmittingUpdateSelf}
        key="onboarding-contact-submit-button"
        labelClasses="buttonText"
        size="large"
      />
    );
  }, [isSubmittingUpdateSelf]);

  if (loading) {
    return <OnboardingProgress />;
  }

  return (
    <>
      <OnboardingStepTitle title="Contact Info" />

      <Formik<OnboardingContactValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validationSchema={updateOnboardingContactInfoSchema}
      >
        {({
          handleSubmit,
          isSubmitting,
          values,
        }) => {
          const didEmailChange = Boolean(self && values.email && values.email !== self.email);

          return (
            <Form onSubmit={handleSubmit}>
              <Grow in={!loading}>
                <StyledCardContent>
                  <Box
                    component="section"
                    textAlign="left"
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                    >
                      Login Info
                    </Typography>

                    <Typography
                      className="description"
                      variant="body2"
                    >
                      Use this email address to sign in to Presto.
                    </Typography>

                    <Flex>
                      <CustomInput
                        label="Email"
                        name="email"
                      />

                      <Collapse in={isOnboardingStudentOrParent && didEmailChange}>
                        <Box
                          mb={submitError ? 2 : 0}
                          mt={1}
                        >
                          <EnhancedAlert
                            isTip
                            title="Reminder"
                          >
                            Students and any Adult relatives should
                            use separate email addresses.
                            This allows each of you to sign
                            in and see a different view of {APP_NAME}.
                          </EnhancedAlert>
                        </Box>
                      </Collapse>
                    </Flex>
                  </Box>

                  <Box
                    component="section"
                    mt={2}
                    textAlign="left"
                  >
                    <Box mb={1}>
                      <Typography
                        component="h3"
                        variant="h6"
                      >
                        Contact Info
                      </Typography>
                    </Box>

                    {existingAddressElement}

                    <Flex>
                      <CustomInput
                        label="Address 1"
                        name="addressOne"
                      />
                      <CustomInput
                        label="Address 2"
                        name="addressTwo"
                      />
                      <CustomInput
                        label="City"
                        name="city"
                      />

                      <Flex
                        alignItems="baseline"
                        flex="1 0 100%"
                        flexWrap="nowrap"
                      >
                        <StateSelector />
                        <CustomInput
                          label="Zip Code"
                          name="zipcode"
                          rootStyles="zipcode"
                        />
                      </Flex>

                      <CustomInput
                        label="Phone Number"
                        name="phoneNumber"
                        type="tel"
                      />
                    </Flex>
                  </Box>

                  <Collapse in={Boolean(submitError)}>
                    <EnhancedAlert severity="error">{submitError}</EnhancedAlert>
                  </Collapse>

                  {buttonElement(isSubmitting)}
                </StyledCardContent>
              </Grow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OnboardingUserContactInfo;
