// External Dependencies
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { Flex } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { tableQueryParams } from 'state/table/selectors';
import DataGridFilterCard from 'components/shared/DataGridFilterCard';
import SchoolYearDataGridFilter from 'components/shared/SchoolYearDataGridFilter';

// Local Dependencies
import MembersTable from './MembersTable';

// Component Definition
const PreviousYearMembers = (): JSX.Element => {
  const { schoolYear } = useParams<{ schoolYear: string; }>();

  const previousMemberParams = useSelector(tableQueryParams('previousMembers'));

  const getPathOnFilterChange = useCallback(
    (schoolYearEnding: number) => `/${PATHS.PREVIOUS_YEAR_MEMBERS}/${schoolYearEnding}${previousMemberParams}`,
    [previousMemberParams],
  );

  return (
    <Flex
      alignItems="flex-start"
      flexDirection="column"
      gap={2}
    >
      <DataGridFilterCard>
        <SchoolYearDataGridFilter
          getPathOnFilterChange={getPathOnFilterChange}
          previousYearsOnly
          schoolYearEndingFromUrl={Number(schoolYear)}
        />
      </DataGridFilterCard>

      <MembersTable schoolYearEnding={Number(schoolYear)} />
    </Flex>
  );
};

export default PreviousYearMembers;
