// External Dependencies
import { CardOwnProps } from '@mui/material/Card';
import {
  CreateDocumentUploadRequest,
  GetDocumentUploadsResponse,
} from '@presto-assistant/api_types/api/v1/documentUpload';
import { useCallback, useMemo } from 'react';
import DocumentsIcon from '@mui/icons-material/InsertDriveFile';
import DownloadIcon from '@mui/icons-material/SaveAlt';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { EnhancedCard, ShowCard, TableDataGrid } from 'components/shared/index';
import { createDataGridActionsColumn } from 'components/shared/TableV2';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { useDownloadDocumentUpload, useGetDocumentUploads } from 'utils/api/documentUploads';
import { useIsOpen } from 'hooks/useIsOpen';
import CreateDocumentUploadDialog from 'components/shared/DocumentHub/CreateDocumentUploadDialog';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

// Local Typings
interface Props {
  idRef: string;
  metadata: CreateDocumentUploadRequest['body']['metadata'];
  sx?: CardOwnProps['sx'];
  tableRef: 'inventory_repairs';
}

type DocumentUpload = GetDocumentUploadsResponse['data']['documentUploads'][0];

// Component Definition
const DocumentHub = ({
  idRef,
  metadata,
  sx,
  tableRef,
}: Props): JSX.Element => {
  const {
    isOpen: isCreateDialogOpen,
    toggleIsOpen: toggleIsCreateDialogOpen,
  } = useIsOpen();

  const {
    data: documentUploadsData,
    isLoading,
  } = useGetDocumentUploads({
    idRef,
    tableRef,
  });

  const {
    mutate: downloadDocumentUpload,
  } = useDownloadDocumentUpload();

  const documentUploads = documentUploadsData?.data.data.documentUploads;

  const handleClickDownload = useCallback((row: DocumentUpload) => {
    downloadDocumentUpload({ id: row.id });
  }, [downloadDocumentUpload]);

  const actionsColumn = useMemo<DataGridColDef<DocumentUpload> | null>(() => {
    return createDataGridActionsColumn<DocumentUpload>([
      {
        action: handleClickDownload,
        icon: <DownloadIcon />,
        text: 'Download',
      },
    ]) as DataGridColDef<DocumentUpload>;
  }, [handleClickDownload]);

  const columns = useMemo<DataGridColDef<DocumentUpload>[]>(() => {
    return [
      {
        field: 'filename',
        headerName: 'File Name',
      },
      dateTimeColumn({
        field: 'createdAt',
        headerName: 'Created At',
      }),
      {
        field: 'description',
        headerName: 'Description',
      },
      {
        field: 'comments',
        headerName: 'Comments',
      },
      {
        field: 'createdByMemberEmail',
        headerName: 'Created By Email',
      },
      {
        field: 'createdByMemberFirstName',
        headerName: 'Created By First Name',
      },
      {
        field: 'createdByMemberLastName',
        headerName: 'Created By Last Name',
      },
      ...(actionsColumn ? [actionsColumn] : []),
    ];
  }, [actionsColumn]);

  return (
    <>
      <ShowCard
        icon={DocumentsIcon}
        sx={sx}
        title="Document Hub"
      >
        <EnhancedCard>
          <DataGridContainer>
            <TableDataGrid
              addButtonProps={{
                label: 'Upload Document',
                onClick: toggleIsCreateDialogOpen,
              }}
              columns={columns}
              loading={isLoading}
              onUpdateParams={() => {}}
              rows={documentUploads ?? []}
            />
          </DataGridContainer>
        </EnhancedCard>
      </ShowCard>

      <CreateDocumentUploadDialog
        idRef={idRef}
        isOpen={isCreateDialogOpen}
        metadata={metadata}
        onClose={toggleIsCreateDialogOpen}
        tableRef={tableRef}
      >
        Hello
      </CreateDocumentUploadDialog>
    </>
  );
};

export default DocumentHub;
