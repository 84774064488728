import { ClassLinkOrg, ClassLinkUser } from '@presto-assistant/api_types/api/v1/classLink';
import { DataGridColDef } from 'types/dataGrid';
import {
  EnhancedCard, Page, Subtitle, TableDataGrid,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { capitalize } from 'utils';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { useGetSchools, useGetStudentsForSchool } from 'utils/api/classlink';
import { useMemo } from 'react';
import { useParamsWithId } from 'hooks/useParamsWithId';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

const ClassLinkSchool = (): JSX.Element => {
  const { id } = useParamsWithId();

  const {
    data: schoolsData,
  } = useGetSchools();
  const {
    data: studentsForSchoolData,
    isLoading,
  } = useGetStudentsForSchool(id ?? '');

  const schoolData = schoolsData?.data.orgs.find((school) => school.sourcedId === id);

  const columns = useMemo<DataGridColDef<ClassLinkUser>[]>(() => {
    return [
      {
        field: 'givenName',
        headerName: 'First Name',
      },
      {
        field: 'familyName',
        headerName: 'Last Name',
      },
      {
        field: 'username',
        headerName: 'Username',
      },
      {
        field: 'email',
        headerName: 'Email',
      },
      {
        field: 'identifier',
        headerName: 'Student ID',
      },
      {
        field: 'status',
        headerName: 'Status',
        valueFormatter: ({ value }) => capitalize(value),
      },
      dateTimeColumn({
        field: 'dateLastModified',
        headerName: 'Last Modified',
      }),
    ];
  }, []);

  return (
    <Page
      backButtonProps={{
        label: 'ClassLink Schools',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.CLASS_LINK_SCHOOLS}`,
      }}
    >
      <Subtitle>
        {schoolData?.name ?? 'ClassLink School'}
      </Subtitle>

      <EnhancedCard>
        <DataGridContainer>
          <TableDataGrid
            columns={columns}
            getRowId={(row: ClassLinkOrg) => row.sourcedId}
            loading={isLoading}
            rows={studentsForSchoolData?.data.users ?? []}
          />
        </DataGridContainer>
      </EnhancedCard>
    </Page>
  );
};

export default ClassLinkSchool;
