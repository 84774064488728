// External Dependencies
import {
  Genders,
  OrganizationEntityTypes,
} from '@presto-assistant/api_types';
import {
  GridFilterInputValueProps,
  GridPreProcessEditCellProps,
} from '@mui/x-data-grid-pro';
import {
  createUserSchema,
  updateUserSchema,
} from '@presto-assistant/api_types/schemas/user';
import {
  range,
} from '@presto-assistant/api_types/utils';
import {
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

// Internal Dependencies
import { DataGridColDef } from 'types/dataGrid';
import { SHIRT_SIZE_OPTIONS } from 'utils/constants/user';
import { dateColumn, dateTimeColumn } from 'utils/lib/tableColumns';
import {
  displayDynamicFieldCell,
} from 'components/shared/TableV2';
import {
  getMemberStatusValue,
  memberStatusOptions,
  minColumnWidth,
  renderMemberStatusCell,
} from 'pages/People/shared/memberStatusColumnHelpers';
import {
  renderCheckboxCell,
  renderEditCell,
  renderSelectEditInputCell,
} from 'components/shared/TableDataGrid/helpers';
import { runValidationSchema } from 'utils';
import { selectEditModeTable } from 'state/table/selectors';
import {
  useGetDynamicFields,
  useGetGroupsBySchoolYear,
  useGetOrganization,
  useGetOrganizationRoles,
  useGetPossibleFeederOrganizations,
} from 'gql/queries';
import { useGetGenderOptions } from 'hooks/useGetGenderOptions';
import { useGetStateOptions } from 'hooks/useGetStateOptions';
import { useGradeColDef } from 'components/shared/TableDataGrid/hooks';
import { useHasPermission } from 'state/self/selectors';

// Local Dependencies
import { useFinancialMaterializedViewColumns } from './helpers';

// Local Typings
type UpdateSchemaFields = GQL.IUpdateUserInput | GQL.ICreateUserInput;

// Local Variables
export const GroupsInput = (schoolYearEnding?: number, isPrimary?: boolean) =>
  (props: GridFilterInputValueProps) => {
    const { applyValue, focusElementRef, item } = props;

    // it is fine to use hooks here because this function is invoked in the render method
    /* eslint-disable react-hooks/rules-of-hooks */
    const inputRef = useRef<HTMLInputElement>();

    useImperativeHandle(focusElementRef, () => ({
      focus: () => {
        inputRef.current?.querySelector<HTMLInputElement>('input[id="group-filter"]')?.focus();
      },
    }));

    const handleFilterChange = (_event: any, selectedGroups: GQL.ISimpleGroup[]) => {
      applyValue({ ...item, value: selectedGroups });
    };

    const {
      data: groupsData,
    } = useGetGroupsBySchoolYear(schoolYearEnding, isPrimary);
    /* eslint-enable react-hooks/rules-of-hooks */

    return (
      <Autocomplete
        defaultValue={item.value}
        getOptionLabel={(group: GQL.ISimpleGroup) => group.label}
        id="group-filter"
        multiple
        onChange={handleFilterChange}
        options={groupsData?.groupsBySchoolYear.data ?? [] as any}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Groups"
            placeholder="Groups"
            size="medium"
            variant="standard"
          />
        )}
      // Match the default styles of chips for datagrid singleSelect
        renderTags={(value: readonly GQL.ISimpleGroup[], getTagProps) =>
          value.map((option: GQL.ISimpleGroup, index: number) => (
          // can suppress, because key needs to come from getTagProps
          // eslint-disable-next-line react/jsx-key
            <Chip
              label={option.label}
              size="small"
              variant="outlined"
              {...getTagProps({ index })}
            />
          ))}
        size="small"
      />
    );
  };

export const PrimaryRoleInput = (props: GridFilterInputValueProps) => {
  const { applyValue, focusElementRef, item } = props;

  const inputRef = useRef<HTMLInputElement>();

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current?.querySelector<HTMLInputElement>('input[id="primary-role-filter"]')?.focus();
    },
  }));

  const handleFilterChange = (_event: any, selectedPrimaryRoles: GQL.IPrimaryRole[]) => {
    applyValue({ ...item, value: selectedPrimaryRoles });
  };

  const {
    data: rolesData,
  } = useGetOrganizationRoles();

  return (
    <Autocomplete
      defaultValue={item.value}
      getOptionLabel={(role: GQL.IPrimaryRole) => role.label}
      id="primary-role-filter"
      multiple
      onChange={handleFilterChange}
      options={rolesData?.organizationRoles ?? [] as any}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Roles"
          placeholder="Roles"
          size="medium"
          variant="standard"
        />
      )}
      // Match the default styles of chips for datagrid singleSelect
      renderTags={(value: readonly GQL.IPrimaryRole[], getTagProps) =>
        value.map((option: GQL.IPrimaryRole, index: number) => (
          // can suppress, because key needs to come from getTagProps
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={option.label}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))}
      size="small"
    />
  );
};

const SecondaryRolesInput = (props: GridFilterInputValueProps) => {
  const { applyValue, focusElementRef, item } = props;

  const inputRef = useRef<HTMLInputElement>();

  useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      inputRef.current?.querySelector<HTMLInputElement>('input[id="secondary-roles-filter"]')?.focus();
    },
  }));

  const handleFilterChange = (_event: any, selectedSecondaryRoles: GQL.ISecondaryRole[]) => {
    applyValue({ ...item, value: selectedSecondaryRoles });
  };

  const {
    data: rolesData,
  } = useGetOrganizationRoles();

  return (
    <Autocomplete
      defaultValue={item.value}
      getOptionLabel={(role: GQL.ISecondaryRole) => role.label}
      id="secondary-roles-filter"
      multiple
      onChange={handleFilterChange}
      options={rolesData?.organizationRoles ?? [] as any}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Roles"
          placeholder="Roles"
          size="medium"
          variant="standard"
        />
      )}
      // Match the default styles of chips for datagrid singleSelect
      renderTags={(value: readonly GQL.ISecondaryRole[], getTagProps) =>
        value.map((option: GQL.ISecondaryRole, index: number) => (
          // can suppress, because key needs to come from getTagProps
          // eslint-disable-next-line react/jsx-key
          <Chip
            label={option.label}
            size="small"
            variant="outlined"
            {...getTagProps({ index })}
          />
        ))}
      size="small"
    />
  );
};

const generateAdultColumns = (maxAdultIndex: number) => {
  const adultIndexRange = range(0, maxAdultIndex, { excludeUpperBoundary: true });

  const adultColumns: DataGridColDef<GQL.IStudentIndex>[] = adultIndexRange
    .flatMap((adultIndex) => [
      {
        editable: false,
        field: `adults[${adultIndex}]?.firstName` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} First Name`,
        minWidth: 150,
        valueGetter: (params) => params.row.adults[adultIndex]?.firstName,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.lastName` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Last Name`,
        minWidth: 150,
        valueGetter: (params) => params.row.adults[adultIndex]?.lastName,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.email` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Email`,
        valueGetter: (params) => params.row.adults[adultIndex]?.email,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.status` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Member Status`,
        minWidth: minColumnWidth,
        renderCell: (params) => renderMemberStatusCell(params.value as string),
        type: 'singleSelect',
        valueGetter: (params) => getMemberStatusValue({
          authUserEmail: params.row.adults[adultIndex]?.authUserEmail,
          receivedWelcomeEmailAt: params.row.adults[adultIndex]?.receivedWelcomeEmailAt,
          rowId: params.row.adults[adultIndex]?.id,
        }),
        valueOptions: memberStatusOptions,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.phoneNumber` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Phone`,
        valueGetter: (params) => params.row.adults[adultIndex]?.phoneNumber,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.addr1` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Addr 1`,
        valueGetter: (params) => params.row.adults[adultIndex]?.addressOne,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.addr2` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Addr 2`,
        valueGetter: (params) => params.row.adults[adultIndex]?.addressTwo,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.city` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} City`,
        valueGetter: (params) => params.row.adults[adultIndex]?.city,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.zipcode` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Zip`,
        valueGetter: (params) => params.row.adults[adultIndex]?.zipcode,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.state?.label` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} State`,
        valueGetter: (params) => params.row.adults[adultIndex]?.state?.label,
      },
      {
        editable: false,
        field: `adults[${adultIndex}]?.relationshipType?.label` as keyof GQL.IStudentIndex,
        headerName: `Adult ${adultIndex + 1} Relationship`,
        minWidth: 175,
        valueGetter: (params) => params.row.adults[adultIndex]?.relationshipType?.label,
      },
    ]);

  return adultColumns;
};

export const readGroupsColumns = (isPrimary: boolean): DataGridColDef<GQL.IStudentIndex>[] => [
  {
    field: `${isPrimary ? 'primary' : 'secondary'}Groups` as keyof GQL.IStudentIndex,
    filterOperators: [
      {
        InputComponent: GroupsInput(undefined, isPrimary),
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          return (params): boolean => {
            const groupIds = (params.row as GQL.IStudentIndex).groups
              .filter((g) => g.isPrimary === isPrimary)
              .map((g) => g.id);

            const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
            const selectedGroupIds = selectedGroups.map((g) => g.id);

            if (!selectedGroups.length) {
              return true;
            }

            return groupIds.some((id) => selectedGroupIds.includes(id));
          };
        },
        label: 'is any of',
        value: 'isAnyOf',
      },
      {
        InputComponent: GroupsInput(undefined, isPrimary),
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          return (params): boolean => {
            const groupIds = (params.row as GQL.IStudentIndex).groups
              .filter((g) => g.isPrimary === isPrimary)
              .map((g) => g.id);

            const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
            const selectedGroupIds = selectedGroups.map((g) => g.id);

            if (!selectedGroupIds.length) {
              return true;
            }

            return selectedGroupIds.every((id) => groupIds.includes(id));
          };
        },
        label: 'is all of',
        value: 'isAllOf',
      },
      {
        InputComponent: GroupsInput(undefined, isPrimary),
        getApplyFilterFn: (filterItem) => {
          if (
            !filterItem.field
            || !filterItem.value
            || !filterItem.operator
          ) {
            return null;
          }

          return (params): boolean => {
            const groupIds = (params.row as GQL.IStudentIndex).groups
              .filter((g) => g.isPrimary === isPrimary)
              .map((g) => g.id);

            const selectedGroups: GQL.ISimpleGroup[] = filterItem.value;
            const selectedGroupIds = selectedGroups.map((g) => g.id);

            if (!selectedGroupIds.length) {
              return true;
            }

            return selectedGroupIds.every((id) => !groupIds.includes(id));
          };
        },
        label: 'is not in',
        value: 'isNotIn',
      },
    ],
    headerName: `${isPrimary ? 'Primary ' : 'Secondary '} Groups`,
    valueGetter: (params) => (params.row as GQL.IStudentIndex).groups
      .filter((g) => g.isPrimary === isPrimary)
      .map((group) => group.label).join('; '),
  },
];

export const useColumns = ({
  extraColumns,
  maxNumberOfAdults,
  tableResource,
}: {
  extraColumns?: DataGridColDef<GQL.IStudentIndex>[];
  maxNumberOfAdults: number;
  tableResource: 'students';
}) => {
  const currentEditModeTable = useSelector(selectEditModeTable);

  const isEditModeActive = currentEditModeTable === tableResource;

  const {
    data: organizationRoleData,
  } = useGetOrganizationRoles();

  const { data: organizationData } = useGetOrganization();

  // Select options
  const genderOptions = useGetGenderOptions();
  const genderOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...genderOptions.options,
  ], [genderOptions.options]);

  const gradeCol = useGradeColDef<GQL.IStudentIndex>({
    editable: true,
    field: 'grade',
  });

  const primaryRoleOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...organizationRoleData?.organizationRoles ?? [],
  ], [organizationRoleData?.organizationRoles]);

  const shirtSizeOptionsWithEmptyOption = useMemo(() => [
    {
      id: '',
      label: '',
      value: '',
    },
    ...SHIRT_SIZE_OPTIONS,
  ], []);

  const stateOptions = useGetStateOptions();
  const mappedStateOptions = stateOptions?.options.map((option) => ({
    id: option.id.toString(),
    label: option.label,
    value: option.label,
  }));

  const {
    data: possibleFeederOrganizationData,
  } = useGetPossibleFeederOrganizations();

  const { data: dynamicFieldData } = useGetDynamicFields({
    tableRef: 'members',
  });

  const preProcessEditCellProps = async (
    params: GridPreProcessEditCellProps,
    fieldName: keyof UpdateSchemaFields,
  ) => {
    let value = params.props.value!.toString();

    // The studentInfo schema expects an object that contains studentId
    if (fieldName === 'studentInfo') {
      value = {
        studentId: params.props.value!.toString(),
      };
    }

    const schema = (['email', 'firstName', 'lastName'] as (keyof UpdateSchemaFields)[]).includes(fieldName)
      ? createUserSchema
      : updateUserSchema;

    // Validate like Formik does
    const errorMessage: any = await runValidationSchema<UpdateSchemaFields>(
      value,
      fieldName,
      schema as any,
    );

    return {
      ...params.props,
      error: errorMessage[fieldName as keyof UpdateSchemaFields],
    };
  };

  const canReadGroups = useHasPermission('groups', 'read');

  const financialMaterializedViewColumns = useFinancialMaterializedViewColumns<GQL.IStudentIndex>();

  return useMemo(() => {
    const isCollegeOrUniversity = organizationData?.organization
      .entityType.id === OrganizationEntityTypes.College.toString();

    const hasSuccessorOrganization = !!organizationData?.organization.successorOrganization;

    const adultColumns: DataGridColDef<GQL.IStudentIndex>[] = [
      ...generateAdultColumns(maxNumberOfAdults),
    ];

    const columns: DataGridColDef<GQL.IStudentIndex>[] = [
      {
        editable: true,
        field: 'firstName',
        headerName: 'First Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'firstName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'lastName',
        headerName: 'Last Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'lastName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'middleName',
        headerName: 'Middle Name',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'middleName',
        ),
        renderEditCell,
        width: 160,
      },
      {
        editable: true,
        field: 'email',
        headerName: 'Member Email',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'email',
        ),
        renderEditCell,
        width: 292,
      },
      {
        editable: false,
        field: 'status' as keyof GQL.IStudentIndex,
        headerName: 'Member Status',
        minWidth: minColumnWidth,
        renderCell: (params) => renderMemberStatusCell(params.value as string),
        type: 'singleSelect',
        valueGetter: (params) => getMemberStatusValue({
          authUserEmail: params.row.authUserEmail,
          receivedWelcomeEmailAt: params.row.receivedWelcomeEmailAt,
          rowId: params.row.id,
        }),
        valueOptions: memberStatusOptions,
      },
      ...financialMaterializedViewColumns,
      {
        editable: true,
        field: 'phoneNumber',
        headerName: 'Phone Number',
        width: 292,
      },
      dateColumn({
        editable: true,
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        minWidth: 248,
      }),
      {
        editable: true,
        field: 'gender',
        headerName: 'Gender',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: genderOptionsWithEmptyOption,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row.gender?.id === Genders.Other.toString()
          ? `Other ${params.row.otherGenderLabel
            ? ` - ${params.row.otherGenderLabel}`
            : ''}`
          : params.row.gender?.label),
        valueOptions: genderOptions.options,
        valueSetter: (params) => ({
          ...params.row,
          gender: genderOptions.options
            .find((option) => option.value === params.value) as GQL.IUserGender | undefined,
        } as GQL.IStudentIndex),
      } as DataGridColDef<GQL.IStudentIndex>,
      { ...gradeCol },
      {
        editable: true,
        field: 'shirtSize',
        headerName: 'Shirt Size',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: shirtSizeOptionsWithEmptyOption,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row.shirtSize) ?? '',
        valueOptions: SHIRT_SIZE_OPTIONS.map((shirtSize) => ({
          label: shirtSize.label,
          value: shirtSize.id,
        })),
        width: 80,
      },
      {
        editable: true,
        field: 'primaryRole',
        filterOperators: [
          {
            InputComponent: PrimaryRoleInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const primaryRoleId = (params.row as GQL.IStudentIndex).primaryRole?.id;

                if (!primaryRoleId) {
                  return false;
                }

                const selectedPrimaryRoles: GQL.IPrimaryRole[] = filterItem.value;
                const selectedPrimaryRoleIds = selectedPrimaryRoles.map((g) => Number(g.id));

                if (!selectedPrimaryRoleIds.length) {
                  return false;
                }

                return selectedPrimaryRoleIds.includes(primaryRoleId);
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: PrimaryRoleInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const primaryRoleId = (params.row as GQL.IStudentIndex).primaryRole?.id;

                if (!primaryRoleId) {
                  return true;
                }

                const selectedPrimaryRoles: GQL.IPrimaryRole[] = filterItem.value;
                const selectedPrimaryRoleIds = selectedPrimaryRoles.map((g) => Number(g.id));

                if (!selectedPrimaryRoleIds.length) {
                  return false;
                }

                return !selectedPrimaryRoleIds.includes(primaryRoleId);
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Primary Role', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
        renderEditCell: (params) => renderSelectEditInputCell({
          options: primaryRoleOptionsWithEmptyOption,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IStudentIndex).primaryRole?.label,
        valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
          label: role.label,
          value: role.label,
        })),
        valueSetter: (params) => ({
          ...params.row,
          primaryRole: (organizationRoleData?.organizationRoles
            .find((role) => role.label === params.value) as GQL.IPrimaryRole | undefined) ?? null,
        }),
      },
      {
        field: 'secondaryRoles',
        filterOperators: [
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles?.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return secondaryRoleIds.some((id) => selectedRoleIds.includes(id));
              };
            },
            label: 'is any of',
            value: 'isAnyOf',
          },
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles?.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return selectedRoleIds.every((id) => secondaryRoleIds.includes(id));
              };
            },
            label: 'is all of',
            value: 'isAllOf',
          },
          {
            InputComponent: SecondaryRolesInput,
            getApplyFilterFn: (filterItem) => {
              if (
                !filterItem.field
                || !filterItem.value
                || !filterItem.operator
              ) {
                return null;
              }

              return (params): boolean => {
                const secondaryRoleIds = (params.row as GQL.IStudentIndex)
                  .secondaryRoles?.map((role) => role.id);

                const selectedRoles: GQL.ISecondaryRole[] = filterItem.value;
                const selectedRoleIds = selectedRoles.map((role) => Number(role.id));

                if (!selectedRoles.length) {
                  return true;
                }

                return selectedRoleIds.every((id) => !secondaryRoleIds.includes(id));
              };
            },
            label: 'is not in',
            value: 'isNotIn',
          },
        ],
        headerName: 'Secondary Roles', // TODO: make this say "instrument" for band, orch; "part" for choir; "role" for theater, dance
        valueGetter: (params) => (params.row as GQL.IStudentIndex).secondaryRoles
          .map((secondaryRole) => secondaryRole.label).join('; '),
        valueOptions: organizationRoleData?.organizationRoles.map((role) => ({
          label: role.label,
          value: role.label,
        })),
      },
      {
        editable: true,
        field: 'studentId',
        headerName: 'Student ID',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'studentInfo',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'previousCampus',
        headerName: 'Previous Campus',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'previousCampus',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'isEligible',
        headerName: 'Is Eligible',
        renderCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        renderEditCell: (params) => renderCheckboxCell({
          isEditModeActive,
          params,
        }),
        type: 'boolean',
      },
      {
        editable: false,
        field: 'primarySpokenLanguage.id' as keyof GQL.IStudentIndex,
        headerName: 'Primary Spoken Language',
        // doing optional chaining here due to indexed db cache
        valueGetter: (params) => params.row.primarySpokenLanguage?.label,
      },
      {
        editable: false,
        field: 'authUserEmail',
        headerName: 'Account Email',
      },
      dateTimeColumn({
        editable: false,
        field: 'loggedInAt',
        headerName: 'Last Logged In',
      }),
      {
        editable: false,
        field: 'numberOfInventoryCheckouts',
        headerName: 'Inventory Checkouts',
        type: 'number',
      },
      {
        editable: false,
        field: 'numberOfUniformCheckouts',
        headerName: 'Uniform Checkouts',
        type: 'number',
      },
      ...(canReadGroups ? readGroupsColumns(true) : []),
      ...(canReadGroups ? readGroupsColumns(false) : []),
      {
        editable: true,
        field: 'privateLessonTeacher',
        headerName: 'Lesson Teacher',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'privateLessonTeacher',
        ),
        renderEditCell,
      },
      ...(!isCollegeOrUniversity && hasSuccessorOrganization ? [{
        editable: true,
        field: 'successorOrganization',
        headerName: 'Successor Organization',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: possibleFeederOrganizationData?.possibleFeederOrganizations,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => ((params.row as GQL.IStudentIndex)
          .successorOrganization?.label)
            ?? organizationData?.organization.successorOrganization?.label,
        valueOptions: possibleFeederOrganizationData?.possibleFeederOrganizations
          .map((possibleOrganization) => ({
            label: possibleOrganization.label,
            value: possibleOrganization.label,
          })) ?? [],
        valueSetter: (params) => ({
          ...params.row,
          successorOrganization: (params.row as GQL.IStudentIndex)
            .successorOrganization?.label
            ?? possibleFeederOrganizationData?.possibleFeederOrganizations
              .find((possibleOrganization) =>
                possibleOrganization.label === params.value),
        }),
        width: 200,
      } as DataGridColDef<GQL.IStudentIndex>] : []),
      {
        editable: true,
        field: 'addressOne',
        headerName: 'Address 1',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'addressOne',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'addressTwo',
        headerName: 'Address 2',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'addressTwo',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'city',
        headerName: 'City',
        preProcessEditCellProps: (params) => preProcessEditCellProps(
          params,
          'city',
        ),
        renderEditCell,
      },
      {
        editable: true,
        field: 'state',
        headerName: 'State',
        renderEditCell: (params) => renderSelectEditInputCell({
          options: mappedStateOptions,
          params,
        }),
        type: 'singleSelect',
        valueGetter: (params) => (params.row as GQL.IStudentIndex).state?.label
          ?? '',
        valueOptions: mappedStateOptions,
        valueSetter: (params) => ({
          ...params.row,
          state: {
            __typename: 'State',
            abbreviation: '',
            id: mappedStateOptions?.find((option) => option.label === params.value)?.id ?? '',
            label: params.value,
          },
        }),
      },
      {
        editable: true,
        field: 'zipcode',
        headerName: 'Zip Code',
      },
      {
        editable: true,
        field: 'allergies',
        headerName: 'Allergies',
      },
      ...adultColumns,
      ...(dynamicFieldData?.dynamicFields ?? [])
        .map<DataGridColDef<GQL.IStudentIndex>>((field) => ({
          field: field.dynamicFieldRef as keyof GQL.IStudentIndex,
          headerName: field.label,
          valueGetter: (params) => displayDynamicFieldCell(field, params.row),
        })),
      ...(extraColumns ?? []),
    ];

    return columns;
  }, [
    canReadGroups,
    dynamicFieldData?.dynamicFields,
    extraColumns,
    financialMaterializedViewColumns,
    genderOptions.options,
    genderOptionsWithEmptyOption,
    gradeCol,
    isEditModeActive,
    mappedStateOptions,
    maxNumberOfAdults,
    organizationData?.organization.entityType.id,
    organizationData?.organization.successorOrganization,
    organizationRoleData?.organizationRoles,
    possibleFeederOrganizationData?.possibleFeederOrganizations,
    primaryRoleOptionsWithEmptyOption,
    shirtSizeOptionsWithEmptyOption,
  ]);
};
