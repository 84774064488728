// External Dependencies
import CircularProgress from '@mui/material/CircularProgress';

// Internal Dependencies
import { Flex } from 'components/shared';
import { useGetGroup } from 'gql/queries';
import type { GroupResponseData } from 'gql/queries';

// Local Dependencies
import GroupTableUI from './GroupTableUI';

// Local Typings
interface Props {
  groupId: string;
  onUpdateTitle: (title: string) => void;
}

// Component Definition
const GroupTable = ({
  groupId,
  onUpdateTitle,
}: Props): JSX.Element | null => {
  const handleOnCompleted = (response: GroupResponseData) => {
    onUpdateTitle(response.group.label);
  };

  const { data, error, loading } = useGetGroup(
    groupId,
    handleOnCompleted,
  );

  if (loading) {
    return (
      <Flex justifyContent="center">
        <CircularProgress
          size={28}
          sx={{ m: 2 }}
        />
      </Flex>
    );
  }

  if (!data || error) {
    return null;
  }

  return (
    <GroupTableUI
      groupId={groupId}
      isLoading={loading}
    />
  );
};

export default GroupTable;
