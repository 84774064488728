// External Dependencies
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import styled, { useTheme } from 'styled-components';
import type { ReactNode } from 'react';

// Internal Dependencies
import { EnhancedCard, EnhancedCardContent, SkewedBackground } from 'components/shared';
import PrestoLogoHorizontalSvg from 'components/shared/PrestoLogoHorizontalSvg';

// Local Typings
interface Props {
  message?: ReactNode;
  title?: string;
}

// Local Variables
const StyledEnhancedCard = styled(EnhancedCard)({
  borderRadius: 8,
  zIndex: 1,
});

const Maintenance = ({
  message = 'We are performing some scheduled maintenance. Please check back in a few hours.',
  title,
}:Props): JSX.Element => {
  const theme = useTheme();

  return (
    <>
      <SkewedBackground />

      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            paddingTop: 4,
          }}
        >
          <Box
            sx={{
              marginBottom: 3,
              width: '90%',
              zIndex: 1,
            }}
          >
            <Typography
              align="center"
              component="h1"
            >
              <PrestoLogoHorizontalSvg htmlColor={theme.palette.primary.main} />
            </Typography>
          </Box>

          <StyledEnhancedCard>
            <EnhancedCardContent>
              <img
                alt="maintenance"
                src="https://res.cloudinary.com/presto-assistant/image/upload/v1642376166/web-app/maintenance_optimized_naesge.svg"
                width="100%"
              />

              {title && (
                <Typography
                  sx={{
                    alignItems: 'center',
                    marginTop: 2,
                  }}
                  variant="h5"
                >
                  {title}
                </Typography>
              )}

              <Typography
                sx={{
                  alignItems: 'center',
                  marginTop: 2,
                }}
              >
                {message}
              </Typography>
            </EnhancedCardContent>
          </StyledEnhancedCard>
        </Box>
      </Container>
    </>
  );
};

export default Maintenance;
