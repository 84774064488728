// External Dependencies
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  EnhancedAlert,
  StyledLink,
} from 'components/shared';
import { SHORT_APP_NAME } from 'utils/constants';
import { stripeUrl } from 'utils/constants/urls';
import { useGetOrganization } from 'gql/queries';

// Local Typings
interface Props {
  showPercentageFeeText: boolean;
}

// Local Variables
const StyledStrong = styled.strong({ fontWeight: 600 });

// Component Definition
const StripeBillingAlert = ({ showPercentageFeeText }: Props): JSX.Element | null => {
  const { data: orgData } = useGetOrganization();

  if (!orgData) {
    return null;
  }
  const stripeBillingStatementOrgName = `${SHORT_APP_NAME.toUpperCase()}* ${orgData.organization.label.toUpperCase()}`.slice(0, 22).trim();

  return (
    <Collapse in={Boolean(orgData)}>
      <Box marginBottom={2}>
        <EnhancedAlert
          severity="info"
          title="Billing Details"
        >
          {showPercentageFeeText && (
            <Typography
              component="span"
              gutterBottom
              sx={{ marginRight: 0.5 }}
              variant="body2"
            >
              A non-refundable fee of 2.9% + $0.30 is included
              in the total for each online transaction.
            </Typography>
          )}

          <Typography
            component="span"
            gutterBottom
            variant="body2"
          >
            Secure payment provided by
            {' '}
            <StyledLink
              href={stripeUrl}
              openInNewTab
            >
              Stripe
            </StyledLink>.
          </Typography>

          <Typography variant="body2">
            Any Stripe payment will appear on your billing statement
            as &quot;
            <code>
              <StyledStrong>
                {stripeBillingStatementOrgName}
              </StyledStrong>
            </code>&quot;.
          </Typography>
        </EnhancedAlert>
      </Box>
    </Collapse>
  );
};

export default StripeBillingAlert;
