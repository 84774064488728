// External Dependencies
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import {
  EnhancedAlert,
  EnhancedCard,
  Subtitle,
} from 'components/shared';
import { FeatureFlag } from '@presto-assistant/api_types';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from 'utils/lib/local_storage';
import { hasFeatureFlag, hasPermission } from 'state/self/selectors';

// Local Dependencies
import LinkToInactiveUsers from '../../shared/LinkToInactiveUsers';
import LinkToPreviousYearMembers from '../../shared/LinkToPreviousYearMembers';
import StudentTable from './StudentsTable';

// Local Variables
const HAS_REMOVED_STUDENTS_TABLE_FINANCES_ALERT = 'hasRemovedStudentsTableFinancesAlert';

// Component Definition
const Students = (): JSX.Element => {
  const canReadFinances = useSelector(hasPermission('finances', 'read'));
  const canReadPayments = useSelector(hasPermission('payments', 'read'));
  const hasFinancesFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.Finances));

  const localStorageHasRemovedAlert = getLocalStorageItem(
    HAS_REMOVED_STUDENTS_TABLE_FINANCES_ALERT,
  );
  const shouldSeeAlert = hasFinancesFeatureFlag && (canReadFinances || canReadPayments);

  const [isAlertOpen, setIsAlertOpen] = useState(shouldSeeAlert && !localStorageHasRemovedAlert);

  const handleRemoveAlert = useCallback(() => {
    setLocalStorageItem(HAS_REMOVED_STUDENTS_TABLE_FINANCES_ALERT, 'true');
    setIsAlertOpen(false);
  }, []);

  return (
    <>
      <Subtitle>
        Students
      </Subtitle>

      {shouldSeeAlert && (
        <EnhancedAlert
          isAlertOpen={isAlertOpen}
          onClose={handleRemoveAlert}
          sx={{ marginBottom: 2 }}
        >
          * Financial data in this table is updated hourly
        </EnhancedAlert>
      )}

      <EnhancedCard>
        <StudentTable />
      </EnhancedCard>

      <LinkToInactiveUsers />

      <LinkToPreviousYearMembers />
    </>
  );
};

export default Students;
