// External Dependencies
import { Formik, type FormikHelpers } from 'formik';
import {
  useCallback, useState,
} from 'react';
import { useSelector } from 'react-redux';

// Internal Dependencies
import { DialogPeoplePicker } from 'components/shared';
import { isOpen } from 'state/ui/peoplePickerDialog/selectors';

// Local Dependencies
import { groupsSchema } from './schema';
import GroupsFormFormikForm, { type CustomSetter } from './GroupsFormFormikForm';

// Local Typings
interface Props {
  initialValues: GQL.ICreateGroupInput;
  onSubmit?: (values: GQL.ICreateGroupInput) => void;
}

// Component Definition
const GroupsForm = ({
  initialValues,
  onSubmit,
}: Props): JSX.Element => {
  const isPeoplePickerDialogOpen = useSelector(isOpen);

  const [
    rawUserIds,
    setRawUserIds,
  ] = useState<string[]>(initialValues.userIds ?? []);
  const [userIds, setUserIds] = useState<CustomSetter[]>([]);

  const handleUpdateSelectedMembers = useCallback((selectedIds: string[]) => {
    // Used to pre-populate the DialogPeoplePicker
    setRawUserIds(selectedIds);

    // Used to add "fields" to the formik values
    setUserIds([
      {
        fieldName: 'userIds',
        fieldValue: selectedIds,
      },
    ]);
  }, []);

  const handleFormikSubmit = useCallback(async (
    values: GQL.ICreateGroupInput,
    formikProps: FormikHelpers<GQL.ICreateGroupInput>,
  ) => {
    const { setSubmitting } = formikProps;

    await onSubmit?.(values);

    setSubmitting(false);
  }, [onSubmit]);

  return (
    <>
      <Formik<GQL.ICreateGroupInput>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleFormikSubmit}
        validationSchema={groupsSchema}
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
        }) => (
          <GroupsFormFormikForm
            errors={errors}
            isEditing={Boolean(initialValues.label)}
            isSubmitting={isSubmitting}
            onSubmit={handleSubmit}
            rawUserIds={rawUserIds}
            setFieldValue={setFieldValue}
            touched={touched}
            userIds={userIds}
          />
        )}
      </Formik>

      <DialogPeoplePicker
        isOpen={isPeoplePickerDialogOpen}
        onAddSelectedIds={handleUpdateSelectedMembers}
        preSelectedIds={rawUserIds}
        tableResource="peoplePickerGroups"
        title="Select Members"
      />
    </>
  );
};

export default GroupsForm;
