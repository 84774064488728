// External Dependencies
import { ProvisionStudentMembersRequest } from '@presto-assistant/api_types/api/v1/classLink';
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { useTanstackMutation } from 'utils/api/index';

export const useGetClasses = (schoolId: string | null) => {
  return useQuery({
    enabled: Boolean(schoolId),
    queryFn: () => {
      return apiClient.v1.classLink.classesForSchool({ schoolId: schoolId || '' });
    },
    queryKey: ['classLinkClasses', schoolId],
  });
};

export const useProvisionStudents = () => {
  const mutationFn = (body: ProvisionStudentMembersRequest['body']) => {
    return apiClient.v1.classLink.provisionStudentMembers(body);
  };

  return useTanstackMutation({
    mutationFn,
  });
};

export const useGetStudentsForClass = (classId: string | null) => {
  return useQuery({
    enabled: Boolean(classId),
    queryFn: () => {
      return apiClient.v1.classLink.studentsForClass({ classId: classId || '' });
    },

    queryKey: ['classLinkStudentsForClass', classId],
  });
};

export const useGetSchools = () => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.classLink.schools();
    },
    queryKey: ['classLinkSchools'],
  });
};

export const useGetStudentsForSchool = (schoolId: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.classLink.studentsForSchool({ schoolId });
    },
    queryKey: ['classLinkStudentsForSchool', schoolId],
  });
};
