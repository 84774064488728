// External Dependencies
import { ReportTypes } from '@presto-assistant/api_types';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { ConfirmationDialog } from 'components/shared';
import { addNotification } from 'state/notifications/actions';
import { useCreateStudentUniformCheckoutReport } from 'utils/api/report';

// Local Typings
interface Props {
  isOpen: boolean;
  onToggleDialog: () => void;
}

// Component Definition
const ConfirmCreateStudentCheckoutReportDialog = ({
  isOpen,
  onToggleDialog,
}: Props): JSX.Element => {
  const dispatch = useDispatch();

  const {
    isLoading,
    mutate: createStudentUniformCheckoutReport,
  } = useCreateStudentUniformCheckoutReport();

  const handleCreateStudentUniformAssignmentReport = useCallback(() => {
    createStudentUniformCheckoutReport({
      payload: {
        label: 'Student Uniform Checkout Report',
      },
      reportTypeId: ReportTypes.StudentUniformCheckoutReport,
    }, {
      onError: (error) => {
        console.error('Error creating student uniform checkout report:', error);
        dispatch(
          addNotification('Something went wrong. Please try again.', 'error'),
        );
        onToggleDialog();
      },
      onSuccess: () => {
        dispatch(
          addNotification('Report should arrive by email soon!', 'success'),
        );
        onToggleDialog();
      },
    });
  }, [createStudentUniformCheckoutReport, dispatch, onToggleDialog]);

  return (
    <ConfirmationDialog
      confirmButtonAction={handleCreateStudentUniformAssignmentReport}
      confirmButtonText="Yes, Create Report"
      declineButtonAction={onToggleDialog}
      description={(
        <>
          <Typography gutterBottom>
            Create a report of uniform checkouts for all current students.
          </Typography>

          <Typography>
            This report will be sent to your email.
          </Typography>
        </>
      )}
      handleClose={onToggleDialog}
      isSubmitting={isLoading}
      open={isOpen}
      title="Create a Report?"
    />
  );
};

export default ConfirmCreateStudentCheckoutReportDialog;
