// External Dependencies
import { LazyQueryResult, QueryHookOptions } from '@apollo/client';
import { useEffect } from 'react';
import gql from 'graphql-tag';

// Internal Dependencies
import { StudentData } from 'gql/queries';
import { UseGetTableQueryArgs, useGetTableQuery } from 'hooks/useGetTableQuery';
import { isDirectorAdminInCurrentOrg } from 'utils';
import { isDistrictAdmin } from 'state/self/selectors';
import { useLazyQueryEnhanced, useQueryEnhanced } from 'utils/lib/graphql';
import { useSelector } from 'react-redux';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Typings
interface OrgSubDetailsResponse {
  organization: {
    active: boolean;
    daysUntilCurrentLicenseExpires: number;
    daysUntilPaymentIsDue: number;
    isFreeTrial: boolean;
  }
}
interface DistrictOrganizationIndexResponse {
  districtOrganizationsIndex: GQL.IDistrictOrganizationIndexItemAll;
}
interface DistrictOrganizationIndexVariables {
  queryParams?: GQL.IIndexQueryParams;
}
interface SchoolYearResponse {
  organizationSchoolYearSelectOptions: GQL.ISchoolYearOption[];
}
interface OrganizationTypesResponse {
  organizationTypes: GQL.IOrganizationType[];
}
interface OrganizationsByDirectorEmailResponse {
  organizationsByDirectorEmail: GQL.IOrganization[];
}

const GET_ORGANIZATION_STRIPE_BALANCE = gql`
  query Organization {
    organization {
      id
      stripeBalance {
        availableInCents
        pendingInCents
      }
    }
  }
`;

export const GET_ORGANIZATION = gql`
  query Organization($id: ID) {
    organization(id: $id) {
      active
      addressOne
      addressTwo
      areOnlinePaymentsEnabled
      canBeginEndOfYearProcess
      ccDirectors
      city
      creator {
        id
        email
        firstName
        lastName
        phoneNumber
      }
      code
      currentSchoolYearEnding
      daysUntilCurrentSchoolYearExpires
      daysUntilPaymentIsDue
      district {
        addressOne
        addressTwo
        admin {
          email
          firstName
          id
          lastName
        }
        areOnlinePaymentsEnabled
        canUseIndividualizedFundraiserCredits
        city
        districtAssistants {
          email
          id
          firstName
          lastName
          phoneNumber
        }
        id
        isDistrictLibraryEnabled
        label
        phoneNumber
        rosteringTenantId
        ssoTenantId
        state {
          id
          label
        }
        zipcode
      }
      entityType {
        id
        label
      }
      externalPaymentProviderUrl
      financialStatementOnlyMembersWithBalance
      financialStatementOnlySendToAdults
      financialStatementScheduleTypeId
      graduatingGrade
      hasAccountWithOnlinePayments
      hasGoogleCalendar
      hasStripeId
      hasVancoPcct
      id
      isFreeTrial
      isCoveringStripeFeeByDefault
      isIgnoringPreviousYearsFinances
      label
      logoUrl
      maxCompletedEndOfYearStep
      organizationType {
        id
        label
      }
      phoneNumber
      stateId
      state {
          abbreviation
          id
          label
      }
      successorOrganization {
        id
        label
      }
      vancoPcct
      zipcode
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  query Organizations($queryParams: QueryParams) {
    organizations(queryParams: $queryParams) {
      data {
        addressOne
        addressTwo
        city
        creator {
          email
          id
        }
        code
        id
        hasGoogleCalendar
        label
        logoUrl
        phoneNumber
        stateId
        state {
          abbreviation
          id
          label
        }
        zipcode
      }
      fullCount
    }
  }
`;

export const GET_ORGANIZATION_SCHOOL_YEAR_OPTIONS = gql`
  query OrganizationSchoolYearSelectOptions(
    $includeAllYearsOption: Boolean
    $organizationId: ID
  ) {
    organizationSchoolYearSelectOptions(
      includeAllYearsOption: $includeAllYearsOption
      organizationId: $organizationId
    ) {
      id
      label
    }
  }
`;

const GET_ORGANIZATION_SUBSCRIPTION_DETAILS = gql`
  query OrganizationSubscriptionDetails {
    organization {
      active
      daysUntilCurrentLicenseExpires
      daysUntilPaymentIsDue
      isFreeTrial
    }
  }
`;

const GET_POSSIBLE_FEEDER_ORGANIZATIONS = gql`
  query PossibleFeederOrganizations {
    possibleFeederOrganizations {
      id
      label
    }
  }
`;

const GET_DISTRICT_ORGANIZATIONS = gql`
  query DistrictOrganizations {
    districtOrganizations {
      id
      label
      organizationType {
        id
        label
      }
    }
  }
`;

export const GET_DISTRICT_ORGNANIZATIONS_INDEX_ITEMS = gql`
  query DistrictOrganizationsItemsIndex($queryParams: IndexQueryParams) {
    districtOrganizationsIndex(queryParams: $queryParams) {
      data {
        directorEmail
        directorFirstName
        directorLastName
        entityType
        freeTrialDaysRemaining
        hasActiveLicense
        id
        isCurrentlyFreeTrial
        label
        organizationType
        subscriptionDaysRemaining
      }
      fullCount
    }
  }
`;

export const GET_DISTRICT_ORGNANIZATIONS_INDEX_ITEMS_REPORT = gql`
  query DistrictOrganizationsItemsIndexReport($queryParams: IndexQueryParams) {
    districtOrganizationsIndex(queryParams: $queryParams) {
      fullCount
    }
  }
`;

export const GET_ORGANIZATION_TYPES = gql`
  query OrganizationTypes {
    organizationTypes {
      id
      label
    }
  }
`;

export const GET_ORGANIZATIONS_BY_DIRECTOR_EMAIL = gql`
  query OrganizationsByDirectorEmail($email: String!) {
    organizationsByDirectorEmail(email: $email) {
      creator {
        email
        id
      }
      id
      label
    }
  }
`;

export const GET_ORGANIZATION_MEMBERSHIP_ID = gql`
  query OrganizationMembershipId($id: ID!) {
    user(id: $id) {
      id
      role {
        id
        label
      }
      userOrgData {
        id
      }
    }
  }
`;

export const useGetOrganizationSubscriptionDetails = () => {
  const isDFA = useSelector(isDistrictAdmin);

  return useQueryEnhanced<OrgSubDetailsResponse>(
    GET_ORGANIZATION_SUBSCRIPTION_DETAILS,
    {
      skip: isDFA,
    },
  );
};

export const useGetPossibleFeederOrganizations = () => {
  const { self } = useSelfQuery();

  const isUserDirector = !!self && isDirectorAdminInCurrentOrg(self);

  return useQueryEnhanced<{ possibleFeederOrganizations: GQL.IShallowOrganization[] }>(
    GET_POSSIBLE_FEEDER_ORGANIZATIONS,
    {
      skip: !isUserDirector,
    },
  );
};

export const useGetDistrictOrganizationsIndexQuery = (
  gqlQuery: UseGetTableQueryArgs['gqlQuery'],
  options: UseGetTableQueryArgs['options'],
  isUsingDataGrid = false,
): [
    (
    ) => void,
    LazyQueryResult<DistrictOrganizationIndexResponse, DistrictOrganizationIndexVariables>
  ] => {
  const {
    apiRequest,
    values,
  } = useGetTableQuery<DistrictOrganizationIndexResponse, DistrictOrganizationIndexVariables>({
    gqlQuery,
    isUsingDataGrid,
    options,
    tableResource: 'districtOrganizations',
  });

  return [
    apiRequest,
    values,
  ];
};

export const useGetDistrictOrganizationIndex = ({
  isUsingDataGrid,
}: {
  isUsingDataGrid: boolean;
}) => {
  const [query, values] = useGetDistrictOrganizationsIndexQuery(
    GET_DISTRICT_ORGNANIZATIONS_INDEX_ITEMS,
    { exportReport: false },
    isUsingDataGrid,
  );

  useEffect(() => {
    query();
  }, [query]);

  return values;
};

export const useDownloadDistrictOrganizationIndexItems = () =>
  useGetDistrictOrganizationsIndexQuery(
    GET_DISTRICT_ORGNANIZATIONS_INDEX_ITEMS_REPORT,
    { exportReport: true },
  );

export const useGetOrganization = (options?: QueryHookOptions) => {
  const { self } = useSelfQuery();

  return useQueryEnhanced<{ organization: GQL.IOrganization }>(
    GET_ORGANIZATION,
    {
      ...options,
      skip: !self?.currentOrgId || options?.skip,
    },
  );
};

export const useGetOrganizationById = (
  variables: GQL.IOrganizationOnQueryArguments,
) =>
  useQueryEnhanced<{
    organization: GQL.IOrganization
  }>(
    GET_ORGANIZATION,
    {
      skip: !variables.id,
      variables,
    },
  );

export const useGetOrganizationStripeBalance = () =>
  useLazyQueryEnhanced<
    { organization: GQL.IOrganization }
  >(GET_ORGANIZATION_STRIPE_BALANCE);

export const useGetOrganizationSchoolYearOptions = (
  variables: GQL.IOrganizationSchoolYearSelectOptionsOnQueryArguments = {},
) => {
  const isDFA = useSelector(isDistrictAdmin);

  return useQueryEnhanced<SchoolYearResponse>(
    GET_ORGANIZATION_SCHOOL_YEAR_OPTIONS,
    {
      // This query could be used by a district admin, so we can't assume
      // that the current org id is available. The current org id is avaiable
      // in the token for the api.
      skip: isDFA && !variables.organizationId,
      variables,
    },
  );
};

export const useGetOrganizationTypes = () =>
  useQueryEnhanced<OrganizationTypesResponse>(
    GET_ORGANIZATION_TYPES,
  );

export const useGetOrganizationsByDirectorEmail = () =>
  useLazyQueryEnhanced<
    OrganizationsByDirectorEmailResponse,
    GQL.IOrganizationsByDirectorEmailOnQueryArguments
  >(
    GET_ORGANIZATIONS_BY_DIRECTOR_EMAIL,
  );

export const useGetDistrictOrganizations = (options?: QueryHookOptions) =>
  useQueryEnhanced<{ districtOrganizations: GQL.IShallowOrganization[] }>(
    GET_DISTRICT_ORGANIZATIONS,
    options,
  );

export const useGetOrganizationMembershipId = (
  id?: string,
) => useQueryEnhanced<{
    user: Pick<StudentData, 'id' | 'role' | 'userOrgData'>;
  }>(
    GET_ORGANIZATION_MEMBERSHIP_ID,
    {
      skip: !id,
      variables: { id },
    },
  );
