// External Dependencies
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Internal Dependencies
import { SUPPORT_SITE_HREF } from 'utils/constants';

// Local Dependencies
import StyledLink from './StyledLink';

// Local Typings
type Slug =
  | 'add-assistant-directors-and-staff'
  | 'communication-walkthrough/#send-emails'
  | 'change-head-director'
  | 'create-a-new-district'
  | 'create-a-new-district-as-a-director' // unlisted
  | 'district-it-checklist'
  | 'manage-user-permissions/#editing-permission-comes-from-the-district-administrator'
  | 'finances-walkthrough/#sync-with-stripe'
  | 'import-inventory-data#troubleshooting'
  | 'import-inventory-data'
  | 'import-student-data#troubleshooting'
  | 'import-student-data'
  | 'inventory-walkthrough/#manage-inventory-items'
  | 'inventory-walkthrough/#manage-library-items'
  | 'inventory-walkthrough/#manage-uniforms'
  | 'manage-classlink-single-sign-on'
  | 'manage-user-permissions'
  | 'members-walkthrough'
  | 'join-as-student-or-parent-from-welcome-email'
  | 'purchase-a-presto-license'
  | 'send-welcome-email-to-all'
  | 'send-welcome-email-to-one-member'
  | 'set-up-online-payments'
  | 'sign-in-with-classlink'
  | 'waive-remove-delete-fees';
interface Props {
  button?: boolean;
  buttonText?: string;
  children?: React.ReactNode;
  isUnlisted?: boolean;
  isVideo?: boolean;
  slug: Slug;
}

/*
 * Used to link to a specific support article.
 *
 */

// Component Definition
const SupportLink = ({
  button,
  buttonText = 'Learn more',
  isUnlisted = false,
  isVideo = false,
  slug,
  ...otherProps
}: Props): JSX.Element => {
  const route = isVideo ? 'videos' : 'articles';

  const routeText = isUnlisted ? 'unlisted' : route;

  return button ? (
    <Button
      endIcon={<OpenInNewIcon />}
      href={`${SUPPORT_SITE_HREF}/${routeText}/${slug}/`}
      size="small"
      target="_blank"
      variant="outlined"
      {...otherProps}
    >
      {buttonText}
    </Button>
  ) : (
    <StyledLink
      href={`${SUPPORT_SITE_HREF}/${routeText}/${slug}`}
      noWrap
      openInNewTab
      {...otherProps}
    >
      {buttonText}
    </StyledLink>
  );
};

export default SupportLink;
