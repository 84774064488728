// External Dependencies
import {
  InventoryRepairIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/inventoryRepair';
import { convertCentsToDollars } from '@presto-assistant/api_types/utils';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import {
  Page,
} from 'components/shared';
import {
  SimpleInventoryItem,
  useGetSimpleInventoryItem,
} from 'gql/queries';
import { addNotification } from 'state/notifications/actions';
import { normalizeInventoryRepairFormPayload } from 'utils/lib/normalize_inventory_repair_form_payload';
import {
  isDistrictAdmin as selectIsDistrictAdmin,
} from 'state/self/selectors';
import {
  useGetInventoryItemRepairList,
  useUpdateInventoryRepair,
} from 'utils/api/inventoryRepair';

// Local Dependencies
import InventoryRepairForm from '../shared/InventoryRepairForm';
import type { EditInventoryRepairFormValues } from './types';

// Component Definition
const InventoryRepairsEdit = (): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, repairId } = useParams();

  const isDistrictAdmin = useSelector(selectIsDistrictAdmin);

  const [
    inventoryItem,
    setInventoryItem,
  ] = useState<SimpleInventoryItem | null>(null);
  const [
    inventoryRepair,
    setInventoryRepair,
  ] = useState<InventoryRepairIndexResponseItem | null>(null);

  const {
    data: initialInventoryItem,
    loading: isInitialInventoryItemLoading,
  } = useGetSimpleInventoryItem(id!);

  const inventoryShowPageBasePath = `/${PATHS.INVENTORY}/${id}`;
  const inventoryShowPagePath = isDistrictAdmin
    ? `/${PATHS.DISTRICT_ADMIN}${inventoryShowPageBasePath}`
    : inventoryShowPageBasePath;

  const inventoryRepairShowPath = `${inventoryShowPagePath}/${PATHS.INVENTORY_REPAIRS}/${repairId}`;

  const navigateToInventoryRepairShow = useCallback(() => {
    navigate(inventoryRepairShowPath);
  }, [inventoryRepairShowPath, navigate]);

  const {
    data: inventoryRepairData,
    isLoading: isLoadingRepairList,
  } = useGetInventoryItemRepairList(id!);

  useEffect(() => {
    if (inventoryRepairData?.data) {
      // Find the current repairId in the list of repairs
      const foundInventoryRepair = inventoryRepairData.data.data.find(
        (repair) => repair.id === repairId,
      );

      if (foundInventoryRepair) {
        setInventoryRepair(foundInventoryRepair);
      }
    }
  }, [inventoryRepairData, repairId, setInventoryRepair]);

  const {
    isLoading: isUpdatingInventoryRepair,
    mutate: updateInventoryRepair,
  } = useUpdateInventoryRepair({
    inventoryItemId: id!,
    inventoryRepairId: inventoryRepair?.id ?? '',
  });

  useEffect(() => {
    if (initialInventoryItem?.inventoryItem) {
      setInventoryItem(initialInventoryItem.inventoryItem);
    }
  }, [initialInventoryItem, setInventoryItem]);

  const editRepairFormIntialValues: EditInventoryRepairFormValues = {
    actualCostInCents: inventoryRepair?.actualCostInCents
      ? convertCentsToDollars(inventoryRepair.actualCostInCents)
      : null,
    actualReturnDate: moment(inventoryRepair?.actualReturnDate).toISOString() ?? null,
    comments: inventoryRepair?.comments ?? null,
    estimatedCostInCents: inventoryRepair?.estimatedCostInCents
      ? convertCentsToDollars(inventoryRepair?.estimatedCostInCents)
      : null,
    estimatedReturnDate: moment(inventoryRepair?.estimatedReturnDate).toISOString() ?? null,
    priority: String(inventoryRepair?.priority) ?? '1',
    sentDate: moment(inventoryRepair?.sentDate).toISOString() ?? null,
    // For now the serviceVendorId will be null
    serviceVendorId: null,
  };

  const handleSubmit = async (values: EditInventoryRepairFormValues) => {
    const normalizedValues = await normalizeInventoryRepairFormPayload({
      actualCostInCents: values.actualCostInCents,
      estimatedCostInCents: values.estimatedCostInCents,
      priority: values.priority,
    });

    const updateRepairValues = {
      ...values,
      ...normalizedValues,
    };

    updateInventoryRepair(
      updateRepairValues,
      {
        onSuccess: () => {
          navigateToInventoryRepairShow();
          dispatch(addNotification('Inventory Repair updated', 'success'));
        },
      },
    );
  };

  if (!inventoryRepair) {
    return (
      <CircularProgress />
    );
  }

  const isLoading = isInitialInventoryItemLoading
    || isUpdatingInventoryRepair
    || isLoadingRepairList;

  return (
    <Page
      backButtonProps={{
        label: 'Inventory Repair',
        path: inventoryRepairShowPath,
      }}
      isLoading={isLoading}
    >
      {inventoryItem && (
        <InventoryRepairForm
          intialValues={editRepairFormIntialValues}
          inventoryItem={inventoryItem}
          inventoryRepair={inventoryRepair}
          onSubmit={handleSubmit}
          title="Edit Inventory Repair"
        />
      )}
    </Page>
  );
};

export default InventoryRepairsEdit;
