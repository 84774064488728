// External Dependencies
import { RemoveFinancialFeeRequest } from '@presto-assistant/api_types/api/v1/financialFee';
import { useCallback } from 'react';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictApolloCache } from 'gql/client';
import { useTanstackMutation } from 'utils/api/index';

export const useRemoveFinancialFee = (options?: {
  suppressErrorNotification?: boolean
}) => {
  const mutationFn = useCallback((payload: {
    body: RemoveFinancialFeeRequest['body'];
    params: RemoveFinancialFeeRequest['params'];
  }) => {
    return apiClient.v1.financialFee.removeFee({
      body: payload.body,
      params: payload.params,
    });
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('financialFee');
      evictApolloCache('financialItemsIndex');
    },
    suppressErrorNotification: options?.suppressErrorNotification,
  });
};
