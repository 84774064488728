// External Dependencies
import * as Sentry from '@sentry/react';
import {
  checkIsCurrentVersionValid,
} from '@presto-assistant/api_types/utils/checkCurrentVersionValid';
import {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import styled from 'styled-components';

// Internal Dependencies
import { FullAppMessageBanner } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { addNotification } from 'state/notifications/actions';
import { addSelfData } from 'state/self/actions';
import {
  bannerAlertItems as bannerAlertItemsSelector,
  hasBannerAlerts as hasBannerAlertsSelector,
} from 'state/bannerAlerts/selectors';
import { getAdminCookie, getToken } from 'utils/cookies';
import { getPageTitle, updateDocumentTitle } from 'utils';
import {
  isDesktopScreenSize,
  isMobileScreenSize,
} from 'state/device/selectors';
import {
  minimumWebAppVersion as minimumWebAppVersionSelector,
} from 'state/self/selectors';
import { onboardingRedirectUser } from 'utils/lib/onboarding_redirect_user';
import { useHelpScoutBeacon } from 'hooks/useHelpScoutBeacon';
import { useIsAliasUser } from 'hooks/useIsAliasUser';
import AlertBanner from 'components/shared/AlertBanner';
import Footer from 'components/shared/Footer';
import UpdatePasswordInterstitial from 'components/shared/Interstitials/UpdatePasswordInterstitial';
import useSelfQuery from 'hooks/useSelfQuery';
import type { ContextData } from 'types/context';

// Local Dependencies
import ReadonlyBanner from './ReadonlyBanner';
import SideNav from './SideNav';
import TitleBar from './TitleBar';
import packageJson from '../../../../package.json';

// Local Typings
interface Props {
  children: React.ReactNode;
}

// Local Variables
const StyledRoot = styled.main(({ theme }) => ({
  '.children-container': {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1.5),
    },
    flex: '1 0 auto',
    overflowY: 'auto',
    padding: theme.spacing(2),
  },
  '.inner-container': {
    display: 'flex',
    margin: '0 auto',
  },
  '.inner-desktop-container': {
    boxSizing: 'border-box',
    flexGrow: 1,
    padding: '16px 16px 4px',
    width: '100%',
    zIndex: 2,
  },
  '.inner-mobile-container': {
    boxSizing: 'border-box',
    flexGrow: 1,
    width: '100%',
  },
  '.private-route-container-paper': {
    borderRadius: 8,
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    width: '100%',
  },
  '.right-panel': {
    backgroundColor: theme.palette.mainContentBackground,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    overflowX: 'auto',
  },
  background: theme.palette.appBackground,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

// Component Definition
const PrivateRouteContainer = ({ children }: Props): JSX.Element | null => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bannerAlertItems = useSelector(bannerAlertItemsSelector);
  const hasBannerAlerts = useSelector(hasBannerAlertsSelector);
  const isMobileScreen = useSelector(isMobileScreenSize);
  const isDesktopScreen = useSelector(isDesktopScreenSize);

  const isAliasUser = useIsAliasUser();

  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const { loading: isLoadingSelf, self } = useSelfQuery();

  const { pathname } = useLocation();

  const minimumWebAppVersion = useSelector(minimumWebAppVersionSelector);

  const pathnameWithoutSlash = window.location.pathname.slice(1);

  const title = getPageTitle(pathnameWithoutSlash);

  const { version } = packageJson;

  const isPrestoAdmin = Boolean(getAdminCookie());

  const isActingAsAnotherUser = isPrestoAdmin || isAliasUser;

  useHelpScoutBeacon({
    isActingAsAnotherUser,
    self,
    version,
  });

  useEffect(() => {
    updateDocumentTitle(title);
  }, [title]);

  useEffect(() => {
    if (self) {
      dispatch(addSelfData(self));

      // Set the member id in Sentry
      Sentry.setUser({ id: self.id });
    } else {
      // Clear the member id in Sentry
      Sentry.setUser(null);
    }

    // Check to see if user should be redirected to onboarding
    // This could happen if the user is signed in
    //  and their data has been changed
    if (self && !self.onboarding.isComplete) {
      onboardingRedirectUser(
        navigate,
        self.id,
        self.currentOrgId,
        self.hasDistrict,
        self.onboarding.stages,
        self.alertFlagId,
        pathname,
      );
    }
  }, [dispatch, navigate, pathname, self]);

  useEffect(() => {
    if (minimumWebAppVersion !== '' && version) {
      const isCurrentVersionValid = checkIsCurrentVersionValid(
        {
          currentVersion: version as `${string}.${string}.${string}`,
          minRequiredVersion: minimumWebAppVersion as `${string}.${string}.${string}`,
        },
      );

      if (!isCurrentVersionValid) {
        dispatch(addNotification('A new version of the web app is available. Please refresh your browser.', 'info', null));
      }
    }
  }, [minimumWebAppVersion, version, dispatch]);

  const isLoggedIn = Boolean(getToken());

  const toggleSideNav = useCallback(() => {
    setIsSideNavOpen((v) => !v);
  }, []);

  if (!isLoggedIn || !self || isLoadingSelf) {
    return null;
  }

  const renderAlerts = () => {
    // We only show the app-level alerts on dashboard and settings pages
    if ([`/${PATHS.DASHBOARD}`, `/${PATHS.SETTINGS}`].includes(window.location.pathname)) {
      return (
        hasBannerAlerts && (
          <>
            {bannerAlertItems.map((alert: ContextData.IAlertDetail) => (
              <AlertBanner
                alert={alert}
                key={alert.id}
              />
            ))}
          </>
        )
      );
    }
    return null;
  };

  const deviceContainerClass = isMobileScreen
    ? 'inner-mobile-container'
    : 'inner-desktop-container';

  return (
    <>
      {!self.hasCustomPassword && !isActingAsAnotherUser && <UpdatePasswordInterstitial />}

      {isActingAsAnotherUser && <ReadonlyBanner isPrestoAdmin={isPrestoAdmin} />}

      <StyledRoot>
        <FullAppMessageBanner />

        <div className={clsx('inner-container', deviceContainerClass)}>
          <Paper
            className="private-route-container-paper"
            variant="outlined"
          >
            {isDesktopScreen ? (
              <SideNav />
            ) : (
              <Drawer
                onClose={toggleSideNav}
                open={isSideNavOpen}
              >
                <SideNav onLinkClick={toggleSideNav} />
              </Drawer>
            )}

            <section className="right-panel">
              <TitleBar
                onClickHamburger={toggleSideNav}
                self={self}
                title={title}
              />

              {renderAlerts()}

              <div className="children-container">
                {children}
              </div>
            </section>
          </Paper>
        </div>

        <Footer hideLinks />
      </StyledRoot>
    </>
  );
};

export default PrivateRouteContainer;
