// External Dependencies
import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import styled from 'styled-components';

// Internal Dependencies
import { getIsMaintenanceMode } from 'utils/lib/get_is_maintenance_mode';
import { getToken } from 'utils/cookies';
import Maintenance from 'pages/Maintenance';

// Local Dependencies
import PrivateRouteContainer from './PrivateRouteContainer';
import SimpleContainer from './SimpleContainer';

// Local Variables
const StyledRoot = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  width: '100%',
});

// Component Definition
const MainContentContainer = (): JSX.Element => {
  const { pathname } = useLocation();

  // If the pathname changes, scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const isMaintenanceMode = getIsMaintenanceMode();

  if (isMaintenanceMode) {
    return (
      <StyledRoot>
        <SimpleContainer>
          <Maintenance />
        </SimpleContainer>
      </StyledRoot>
    );
  }

  const showSimpleContainer = !getToken();

  return (
    <StyledRoot>
      {showSimpleContainer
        ? (
          <SimpleContainer>
            <Outlet />
          </SimpleContainer>
        ) : (
          <PrivateRouteContainer>
            <Outlet />
          </PrivateRouteContainer>
        )}
    </StyledRoot>
  );
};

export default MainContentContainer;
