// External Dependencies
import { InputBaseComponentProps } from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// Local Dependencies
import { useGetInventoryCategories } from 'utils/api/inventoryCategories';
import CustomSelect from '../CustomSelect';

// Local Typings
interface Props {
  inputProps?: InputBaseComponentProps;
  orgTypeId: string;
}

/*
*
* This select should be used in a Formik context
*
*/

// Component Definition
const InventoryCategorySelect = ({
  inputProps,
  orgTypeId,
}: Props): JSX.Element | null => {
  const { data, error, isLoading } = useGetInventoryCategories(orgTypeId);

  if (isLoading) {
    return (
      <Box
        mb={3}
        mt={3}
      >
        <CircularProgress
          size={24}
          thickness={4}
        />
      </Box>
    );
  }

  if (!data) {
    return null;
  }

  if (error) {
    console.log('There was an error loading inventory category data');
  }

  return (
    <CustomSelect
      inputProps={inputProps}
      label="Category"
      name="categoryId"
      options={data.data.data}
      required={false}
    />
  );
};

export default InventoryCategorySelect;
