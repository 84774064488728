// External Dependencies
import { Form, type FormikErrors, type FormikTouched } from 'formik';
import {
  useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import {
  CustomCheckbox,
  CustomInput,
  EnhancedAlert,
  EnhancedCard,
  EnhancedCardContent,
  FormActions,
  Subtitle,
} from 'components/shared';
import { EnhancedStyledLink, StyledStrong } from 'pages/EndOfYear/shared/styles';
import { PATHS } from 'utils/constants/routes';
import { isMobileScreenSize } from 'state/device/selectors';
import {
  open as openPeoplePickerDialog,
} from 'state/ui/peoplePickerDialog/actions';
import { pluralize } from 'utils';
import { tableQueryParams } from 'state/table/selectors';

// Local Typings
interface Props {
  errors: FormikErrors<GQL.ICreateGroupInput>;
  isEditing?: boolean;
  isSubmitting: boolean;
  onSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  rawUserIds: string[];
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  touched: FormikTouched<GQL.ICreateGroupInput>;
  userIds: CustomSetter[];
}

export interface CustomSetter {
  fieldName: string;
  fieldValue: any;
}

// Component Definition
const GroupsFormFormikForm = ({
  errors,
  isEditing,
  isSubmitting,
  onSubmit,
  rawUserIds,
  setFieldValue,
  touched,
  userIds,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobileScreen = useSelector(isMobileScreenSize);
  const groupsShowParams = useSelector(tableQueryParams('groups'));

  const isFormTouched = Object.keys(touched).length > 0;

  const hasSelectedUserIds = rawUserIds.length > 0;

  useEffect(() => {
    // We use a custom setter to add the userIds to the formik values
    userIds.forEach(({ fieldName, fieldValue }) => {
      setFieldValue(fieldName, fieldValue);
    });
  }, [setFieldValue, userIds]);

  const handlePressSelectMembers = useCallback(() => {
    dispatch(openPeoplePickerDialog());
  }, [dispatch]);

  const handlePressCancelOrBackButton = useCallback(() => {
    navigate(`/${PATHS.GROUPS}${groupsShowParams}`);
  }, [groupsShowParams, navigate]);

  const addingMembersText = useMemo(() => (
    <Typography>
      <StyledStrong>
        {rawUserIds.length}
      </StyledStrong>{' '}
      {pluralize(rawUserIds.length, 'member')} selected
    </Typography>
  ), [rawUserIds]);

  return (
    <Form onSubmit={onSubmit}>
      <Box sx={{ marginBottom: 2 }}>
        <Subtitle>Group Details</Subtitle>

        <EnhancedCard>
          <EnhancedCardContent>
            <Box maxWidth="416px">
              <CustomInput
                label="Group Name *"
                name="label"
              />

              <CustomCheckbox
                label="Primary Group"
                name="isPrimary"
                sx={{ marginLeft: 1 }}
              />
            </Box>
          </EnhancedCardContent>
        </EnhancedCard>
      </Box>

      <Box sx={{ marginBottom: 2 }}>
        <Subtitle>Select members</Subtitle>

        <EnhancedCard>
          <CardContent>
            <Collapse in={hasSelectedUserIds}>
              {addingMembersText}

              <Box
                sx={{
                  marginBottom: 1,
                  marginTop: 3,
                }}
              >
                <EnhancedStyledLink onClick={handlePressSelectMembers}>
                  View/Update Members
                </EnhancedStyledLink>
              </Box>
            </Collapse>

            <Collapse in={!hasSelectedUserIds}>
              <Box
                display="flex"
                flexDirection={isMobileScreen ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <Typography>Add any student, parent, or director to the group</Typography>

                <Box sx={{ marginTop: isMobileScreen ? 2 : 0 }}>
                  <Button
                    color="primary"
                    onClick={handlePressSelectMembers}
                    size="small"
                    variant="outlined"
                  >
                    Select Members
                  </Button>
                </Box>
              </Box>
            </Collapse>

            <Collapse in={Boolean(touched.userIds) && Boolean(errors.userIds)}>
              <Box sx={{ marginTop: 2 }}>
                <EnhancedAlert severity="error">
                  {errors.userIds}
                </EnhancedAlert>
              </Box>
            </Collapse>

            <EnhancedAlert sx={{ marginTop: 2 }}>
              Group members will be able to see the group name if they belong to the group.
            </EnhancedAlert>
          </CardContent>
        </EnhancedCard>
      </Box>

      <FormActions
        context="Group"
        isEditing={isEditing}
        isFormTouched={isFormTouched}
        isSubmitting={isSubmitting}
        onPressCancelOrBackButton={handlePressCancelOrBackButton}
      />
    </Form>
  );
};

export default GroupsFormFormikForm;
