// External Dependencies
import {
  ClassLinkSignUpPayload,
  ClassLinkSsoPayload,
  ClassLinkSsoResponse,
  ClassLinkTokenPayload,
} from '@presto-assistant/api_types/api/auth';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// Internal Dependencies
import { AxiosResponse } from 'axios';
import { CustomUseMutationOptions, toggleOrganization, useTanstackMutation } from 'utils/api/index';
import { apiClient } from 'utils/apiClient';
import { getClassLinkCode, setTokenInStorage } from 'utils/cookies';
import { getSearchWithRedirectUrl } from 'utils/lib/redirect_user';
import { parseSearch } from 'utils/index';
import { stringifyQueryParams } from 'utils/lib/stringify_query_params';
import { useParsedSearch } from 'hooks/useParsedSearch';
import { useQuery } from '@tanstack/react-query';

export const getRedirectPath = () => {
  const queryString = `?${getSearchWithRedirectUrl()}`;

  const parsedSearch = parseSearch(queryString);

  if (parsedSearch.redirectUrl && parsedSearch.redirectUrl !== window.location.pathname) {
    return parsedSearch.redirectUrl;
  }

  return '/';
};

export const dfaMemberIdPlaceholder = 'admin';

type ClassLinkSignInMutationFn<Payload> = (payload: Payload) =>
  Promise<AxiosResponse<ClassLinkSsoResponse>>

const useClassLinkSignIn = <Payload>(
  mutationFn: ClassLinkSignInMutationFn<Payload>,
  options?: CustomUseMutationOptions,
) => {
  const dispatch = useDispatch();

  const parsedSearch = useParsedSearch();

  return useTanstackMutation({
    ...options,
    mutationFn,
    onSuccess: async (res) => {
      const token = res.headers['x-access-token'];

      if (token) {
        setTokenInStorage(token);
      }

      let {
        redirectPath = '/',
      } = parsedSearch;

      const {
        memberId,
        ...rest
      } = parsedSearch;

      delete parsedSearch.redirectPath;
      delete parsedSearch.memberId;

      const searchString = stringifyQueryParams(rest);

      redirectPath = `${redirectPath}${searchString ? `?${searchString}` : ''}`;

      if (memberId) {
        await toggleOrganization(
          memberId === dfaMemberIdPlaceholder ? null : memberId,
          dispatch,
          redirectPath,
        );
      } else {
        window.location.href = redirectPath;
      }
    },
  });
};

export const useClassLinkSignUp = () => {
  const mutationFn = useCallback((payload: ClassLinkSignUpPayload) => {
    return apiClient.auth.classLinkSignUp(payload);
  }, []);

  return useClassLinkSignIn<ClassLinkSignUpPayload>(mutationFn);
};

export const useClassLinkToken = (options?: CustomUseMutationOptions) => {
  const mutationFn = useCallback((payload: ClassLinkTokenPayload) => {
    return apiClient.auth.classLinkToken(payload);
  }, []);

  return useClassLinkSignIn<ClassLinkTokenPayload>(mutationFn, options);
};

export const useClassLinkSso = () => {
  const mutationFn = useCallback((payload: ClassLinkSsoPayload) => {
    return apiClient.auth.classLinkSso(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: (res) => {
      window.location.href = res.data.url;
    },
  });
};

export const useClassLinkTokenInfo = () => {
  const code = getClassLinkCode();

  return useQuery({
    enabled: Boolean(code),
    queryFn: () => {
      return apiClient.auth.classLinkTokenInfo({ code });
    },
    queryKey: ['classLinkTokenInfo', code],
  });
};
