// External Dependencies
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { UserRoles } from '@presto-assistant/api_types';
import { useMemo } from 'react';

import { mapEnum } from 'utils/lib/map_enum';

export const useColumns = (
  extraColumns?: GridColDef<GQL.IUser>[],
): GridColDef<GQL.IUser>[] => useMemo(() => {
  const columns: GridColDef<GQL.IUser>[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
    {
      field: 'role',
      headerName: 'Role',
      type: 'singleSelect',
      valueGetter: (params) => params.row.role.label,
      valueOptions: mapEnum(UserRoles).map((option) => ({
        label: option.label,
        value: option.label,
      })),
      width: 80,
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
