import { ClassLinkOrg } from '@presto-assistant/api_types/api/v1/classLink';
import { DataGridColDef } from 'types/dataGrid';
import {
  EnhancedCard, Page, Subtitle, TableDataGrid,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { capitalize } from 'utils';
import { dateTimeColumn } from 'utils/lib/tableColumns';
import { useGetSchools } from 'utils/api/classlink';
import { useMemo } from 'react';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';

const ClassLinkSchools = (): JSX.Element => {
  const {
    data: schoolsData,
    isLoading,
  } = useGetSchools();

  const columns = useMemo<DataGridColDef<ClassLinkOrg>[]>(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
      },
      {
        field: 'type',
        headerName: 'Type',
        valueFormatter: ({ value }) => capitalize(value),
      },
      {
        field: 'status',
        headerName: 'Status',
        valueFormatter: ({ value }) => capitalize(value),
      },
      dateTimeColumn({
        field: 'dateLastModified',
        headerName: 'Last Modified',
      }),
    ];
  }, []);

  return (
    <Page
      backButtonProps={{
        label: 'Settings',
        path: `/${PATHS.DISTRICT_ADMIN}/${PATHS.SETTINGS}`,
      }}
    >
      <Subtitle>
        ClassLink Schools
      </Subtitle>

      <EnhancedCard>
        <DataGridContainer>
          <TableDataGrid
            clickRowTo={(id) => `/${PATHS.DISTRICT_ADMIN}/${PATHS.CLASS_LINK_SCHOOLS}/${id}`}
            columns={columns}
            getRowId={(row: ClassLinkOrg) => row.sourcedId}
            loading={isLoading}
            rows={schoolsData?.data.orgs ?? []}
          />
        </DataGridContainer>
      </EnhancedCard>
    </Page>
  );
};

export default ClassLinkSchools;
