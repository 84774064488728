// External Dependencies
import {
  InventoryRepairIndexResponseItem,
} from '@presto-assistant/api_types/api/v1/inventoryRepair';
import {
  useEffect, useMemo, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ToolsIcon from 'mdi-material-ui/Tools';

// Internal Dependencies
import {
  EnhancedContainer,
  InventorySimpleDataCard,
  Page,
  ShowCard,
  ShowPageDataDisplay,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import {
  SimpleInventoryItem,
  useGetSimpleInventoryItem,
} from 'gql/queries';
import { repairPriorityOptions } from 'pages/InventoryRepairs/shared/InventoryRepairPrioritySelect';
import {
  isDistrictAdmin as selectIsDistrictAdmin,
} from 'state/self/selectors';
import { useGetInventoryItemRepairList } from 'utils/api/inventoryRepair';
import DocumentHub from 'components/shared/DocumentHub';

// Component Definition
const InventoryRepairsShow = (): JSX.Element | null => {
  const { id, repairId } = useParams();

  const isDistrictAdmin = useSelector(selectIsDistrictAdmin);

  const [
    inventoryItem,
    setInventoryItem,
  ] = useState<SimpleInventoryItem | null>(null);
  const [
    inventoryRepair,
    setInventoryRepair,
  ] = useState<InventoryRepairIndexResponseItem | null>(null);

  const {
    data: initialInventoryItem,
    loading: isInitialInventoryItemLoading,
  } = useGetSimpleInventoryItem(id!);

  const inventoryShowPageBasePath = `/${PATHS.INVENTORY}/${id}`;
  const inventoryShowPagePath = isDistrictAdmin
    ? `/${PATHS.DISTRICT_ADMIN}${inventoryShowPageBasePath}`
    : inventoryShowPageBasePath;

  const {
    data: inventoryRepairData,
    isLoading: isLoadingRepairList,
  } = useGetInventoryItemRepairList(id!);

  useEffect(() => {
    if (inventoryRepairData?.data) {
      // Find the current repairId in the list of repairs
      const foundInventoryRepair = inventoryRepairData.data.data.find(
        (repair) => repair.id === repairId,
      );

      if (foundInventoryRepair) {
        setInventoryRepair(foundInventoryRepair);
      }
    }
  }, [inventoryRepairData, repairId, setInventoryRepair]);

  useEffect(() => {
    if (initialInventoryItem?.inventoryItem) {
      setInventoryItem(initialInventoryItem.inventoryItem);
    }
  }, [initialInventoryItem, setInventoryItem]);

  const editRepairPath = useMemo(() => {
    const editRepairBasePath = `/${PATHS.INVENTORY}/${id}/${PATHS.INVENTORY_REPAIRS}/${repairId}/edit`;

    return isDistrictAdmin
      ? `/${PATHS.DISTRICT_ADMIN}${editRepairBasePath}`
      : editRepairBasePath;
  }, [id, isDistrictAdmin, repairId]);

  const inventoryRepairPriority = useMemo(() => {
    if (!inventoryRepair) {
      return null;
    }

    return repairPriorityOptions.find(
      (option) => option.value === inventoryRepair.priority,
    )?.label ?? null;
  }, [inventoryRepair]);

  const metadata = useMemo(() => ({
    inventoryItemId: id ?? '',
  }), [id]);

  if (!inventoryRepair) {
    return (
      <CircularProgress />
    );
  }

  const isLoading = isInitialInventoryItemLoading
    || isLoadingRepairList;

  return (
    <Page
      backButtonProps={{
        label: 'Inventory Item',
        path: inventoryShowPagePath,
      }}
      editButtonPath={editRepairPath}
      isLoading={isLoading}
    >
      {inventoryItem && (
        <>
          <EnhancedContainer>
            <ShowCard
              icon={ToolsIcon}
              title="Inventory Repair"
            >
              <Box marginBottom={4}>
                <InventorySimpleDataCard
                  brand={inventoryItem.brand}
                  label={inventoryItem.label}
                  model={inventoryItem.model}
                  serialNumber={inventoryItem.serialNumber}
                />
              </Box>

              {/* <ShowPageDataDisplay */}
              {/*   label="Service Vendor" */}
              {/*   value={inventoryRepair.serviceVendorLabel} */}
              {/* /> */}

              <ShowPageDataDisplay
                label="Comments"
                value={inventoryRepair.comments}
              />

              <ShowPageDataDisplay
                label="Sent Date"
                type="date"
                value={inventoryRepair.sentDate}
              />

              <ShowPageDataDisplay
                label="Priority"
                value={inventoryRepairPriority}
              />

              <ShowPageDataDisplay
                label="Estimated Return Date"
                type="date"
                value={inventoryRepair.estimatedReturnDate}
              />

              <ShowPageDataDisplay
                label="Actual Return Date"
                type="date"
                value={inventoryRepair.actualReturnDate}
              />

              <ShowPageDataDisplay
                label="Estimated Cost"
                type="currency"
                value={inventoryRepair.estimatedCostInCents}
              />

              <ShowPageDataDisplay
                label="Actual Cost"
                type="currency"
                value={inventoryRepair.actualCostInCents}
              />
            </ShowCard>
          </EnhancedContainer>

          <DocumentHub
            idRef={inventoryRepair.id}
            metadata={metadata}
            sx={{ marginTop: 4 }}
            tableRef="inventory_repairs"
          />
        </>
      )}
    </Page>
  );
};

export default InventoryRepairsShow;
