// External Dependencies
import { useQuery } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';

export const useGetInventoryCategories = (orgTypeId?: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.inventoryCategoryClient.listInventoryCategories({ query: { orgTypeId } });
    },
    // need to pass in the orgTypeId for correct caching,
    // and must default to a value of 0 to work properly
    // (instead of null which is what undefined turns into through tanstack)
    queryKey: ['inventoryCategories', orgTypeId || 0],
  });
};
