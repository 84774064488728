// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { Page } from 'components/shared';
import { useGetFile } from 'gql/queries';
import { useHasPermission } from 'state/self/selectors';
import { useParamsWithId } from 'hooks/useParamsWithId';

// Local Dependencies
import FileShowAssignments from './FileShowAssignments';
import FileShowData from './FileShowData';

// Component Definition
const FilesShow = (): JSX.Element => {
  const {
    directory,
    id,
  } = useParamsWithId<{ directory: string; }>();

  const canEditFiles = useHasPermission('s3Uploads', 'edit');

  const {
    data,
    error,
    loading,
  } = useGetFile(id!);

  const editPath = `/${PATHS.FILES}/${directory}/${id}/edit`;

  return (
    <Page
      backButtonProps={{
        label: `${directory} Files`,
        path: `/${PATHS.FILES}/${directory}`,
      }}
      editButtonPath={editPath}
      error={error}
      isLoading={loading}
      notFound={!data?.file}
    >
      <FileShowData
        canEditFiles={canEditFiles}
        editPath={editPath}
        file={data?.file}
      />
      <FileShowAssignments
        assignments={data?.file?.assignments}
        canEditFiles={canEditFiles}
        editPath={editPath}
      />
    </Page>
  );
};

export default FilesShow;
