// External Dependencies
import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import { APP_NAME } from 'utils/constants';
import { Flex } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useClassLinkTokenInfo } from 'utils/api/auth';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import SignupCard from './SignupCard';
import SignupContainer from './SignupContainer';
import SignupRoleCardsContainer from './SignupRoleCardsContainer';
import StudentParentSignUpCards from './StudentParentSignUpCards';

// Local Variables
const rolesWithMoreChoices: DB.User.OnboardingRole[] = [
  'administrator',
  'director',
];

// Component Definition
const Signup: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const search = useParsedSearch();

  const isTrialSignup = search.trialSignup === true;

  const TRANSITION_DURATION = theme.transitions.duration.standard;

  // Clicking on the CTA will send the user to a search-params-driven url
  const handleClickCreateUser = useCallback((role: DB.User.OnboardingRole) => {
    // Admin and Director roles need to choose a further role
    if (rolesWithMoreChoices.includes(role)) {
      navigate(`/${PATHS.SIGNUP_CHOOSE_ROLE}?onboarding_role=${role}`);
    } else {
      navigate(`/${PATHS.SIGNUP}/${PATHS.CREATE_USER_PROFILE}?onboarding_role=${role}`);
    }
  }, [navigate]);

  const {
    data: classLinkTokenInfoData,
    isFetching: isClassLinkTokenInfoFetching,
  } = useClassLinkTokenInfo();

  const classLinkProfile = classLinkTokenInfoData?.data.myInfo.Profile;

  const isEmployee = classLinkProfile === 'Administrators' || classLinkProfile === 'Teachers';
  const isParent = classLinkProfile === 'Parents';
  const isStudent = classLinkProfile === 'Students';

  // we check negative cases, because if there is no class link data, we want to show the cards
  const showAdminCard = !isParent && !isStudent;
  const showDirectorCard = !isParent && !isStudent;
  const showMemberCard = !isEmployee;

  return (
    <SignupContainer>
      <SignupRoleCardsContainer>
        <Typography
          className="title"
          component="h1"
          gutterBottom
          variant="h4"
        >
          Who are you?
        </Typography>

        <section className="card-container">
          {isClassLinkTokenInfoFetching && <Flex marginTop={8}><CircularProgress /></Flex>}

          {!isClassLinkTokenInfoFetching && (
            <>
              {/* ADMINISTRATOR */}
              {showAdminCard && (
                <SignupCard
                  buttonText="Start for free"
                  listItem1="You're a Fine Arts Administrator or Assistant"
                  listItem2="You need a modern way to track inventory, uniforms, and more across the district"
                  listItem3="You want to start your 30-day free trial"
                  onClickButton={() => handleClickCreateUser('administrator')}
                  subheader={`Set up your ${APP_NAME} district`}
                  title="Administrator"
                  transitionTimeout={TRANSITION_DURATION}
                />
              )}

              {/* DIRECTOR */}
              {showDirectorCard && (
                <SignupCard
                  buttonText="Start for free"
                  listItem1="You're a director for band, choir, dance, guitar, mariachi, orchestra, or theater"
                  listItem2="You need a tool to track inventory, student data,  emails, and more"
                  listItem3="You want to start your 30-day free trial"
                  onClickButton={() => handleClickCreateUser('director')}
                  subheader={`Set up your ${APP_NAME} organization`}
                  title="Director"
                  transitionTimeout={TRANSITION_DURATION * 2}
                />
              )}

              {/* PARENT and STUDENT */}
              {!isTrialSignup && showMemberCard && <StudentParentSignUpCards />}
            </>
          )}
        </section>
      </SignupRoleCardsContainer>
    </SignupContainer>
  );
};

export default Signup;
