// External Dependencies
import {
  FC, MouseEvent, useCallback, useState,
} from 'react';
import {
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import VisiblityIcon from '@mui/icons-material/Visibility';
import VisiblityOffIcon from '@mui/icons-material/VisibilityOff';
import startCase from 'lodash.startcase';
import styled, { useTheme } from 'styled-components';

// Internal Dependencies
import {
  APP_NAME,
  PRIVACY_POLICY_HREF,
  TERMS_OF_SERVICE_HREF,
} from 'utils/constants';
import {
  ConfirmationDialog,
  CustomCheckbox,
  CustomFormButton,
  CustomInput,
  EnhancedAlert,
  EnhancedCard,
  ShowPageDataDisplay,
  StyledLink,
} from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { useClassLinkTokenInfo } from 'utils/api/auth';
import { useIsOpen } from 'hooks/useIsOpen';
import useWindowSize from 'hooks/useWindowSize';

// Local Dependencies
import { CreateValues } from './CreateUserProfile';

// Local Typings
interface Props {
  hasHighGraceNotesValue: string | undefined;
  hasSsoCode: boolean;
  initialValues: CreateValues;
  isSubmitting: boolean;
  onSubmitForm: (
    values: CreateValues,
    setErrors: (errors: FormikErrors<any>) => void,
  ) => void;
  onUpdateEmail: (value: string) => void;
  setHasHighGraceNotesValue: (value: string) => void;
  submitError?: string;
  validate: (values: CreateValues) => void;
}

// Local Variables
const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  padding: theme.spacing(0, 1.5),
  textAlign: 'center',
}));

const StyledForm = styled(Form)(({ theme }) => ({
  '.button': {
    margin: '16px 0 0',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  '.buttonGroupText': {
    fontSize: '1.2em',
    textTransform: 'none',
  },
  '.marginPlus': {
    margin: '12px 0',
  },
  '.section': {
    textAlign: 'left',
  },
}));

const StyledDialogEnhancedCard = styled(EnhancedCard)(({ theme }) => ({
  '.MuiCardContent-root': {
    padding: theme.spacing(1, 2),
  },
  '.showPageLabel': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
  },
  '.showPageValue': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.1rem',
    },
  },
  backgroundColor: theme.palette.appBackground,
}));

const studentOrParentRoles: DB.User.OnboardingRole[] = [
  'parent',
  'student',
];

// Component Definition
const CreateUserProfileForm: FC<Props> = ({
  hasHighGraceNotesValue,
  hasSsoCode,
  initialValues,
  isSubmitting,
  onSubmitForm,
  onUpdateEmail,
  setHasHighGraceNotesValue,
  submitError,
  validate,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const isMediumScreen = useWindowSize().innerWidth < theme.breakpoints.values.md;

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitCreatePayload, setSubmitCreatePayload] = useState<CreateValues | null>(null);
  const [
    formikSetErrors,
    setFormikSetErrors,
  ] = useState<((errors: FormikErrors<CreateValues>) => void) | null>(null);

  const {
    handleClose,
    handleOpen,
    isOpen,
  } = useIsOpen();

  const handleNavigateToSignup = useCallback(() => {
    navigate(`/${PATHS.SIGNUP}`);
  }, [navigate]);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword((state) => !state);
  }, []);

  const handleClickShowConfirmPassword = useCallback(() => {
    setShowConfirmPassword((state) => !state);
  }, []);

  const handleMouseDownPassword = useCallback((
    event: MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  }, []);

  const handleClearPayload = useCallback(() => {
    setSubmitCreatePayload(null);
  }, []);

  const handleSetPayload = useCallback((
    values: CreateValues,
    formikHelpers: FormikHelpers<CreateValues>,
  ) => {
    const { setErrors } = formikHelpers;

    setSubmitCreatePayload(values);
    // Use a callback signature to store a function in local state
    setFormikSetErrors(() => setErrors);
    handleOpen();
  }, [handleOpen]);

  const handleSubmitFormikFormInConfirmationDialog = useCallback(
    () => {
      if (submitCreatePayload && formikSetErrors) {
        onSubmitForm(submitCreatePayload, formikSetErrors);
      }
    },
    [
      formikSetErrors,
      onSubmitForm,
      submitCreatePayload,
    ],
  );

  const {
    data: classLinkTokenInfo,
  } = useClassLinkTokenInfo();

  const classLinkEmail = classLinkTokenInfo?.data?.myInfo.Email;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSetPayload}
      validate={hasSsoCode ? undefined : validate}
    >
      {({
        handleSubmit,
        values,
      }) => {
        const {
          email,
          onboardingRole,
        } = values;

        const prettyRole = onboardingRole.split('_').join(' ').toUpperCase();

        const isStudentOrParent = studentOrParentRoles.includes(onboardingRole);

        return (
          <>
            <Fade in>
              <StyledForm onSubmit={handleSubmit}>
                <Box
                  my={2}
                  textAlign="center"
                >
                  <StyledTypography>
                    You are creating a user profile for{' '}
                    <strong>{prettyRole}</strong>
                  </StyledTypography>

                  <Typography variant="body2">
                    <StyledLink onClick={handleNavigateToSignup}>
                      Choose a different role
                    </StyledLink>
                  </Typography>
                </Box>

                {!hasSsoCode && (
                  <EnhancedAlert sx={{ marginBottom: 1 }}>
                    Use this email address to sign in to {APP_NAME}.
                    {isStudentOrParent && (
                      <Box mt={1}>
                        <Typography variant="body2">
                          Every {onboardingRole} must use their own
                          email address to sign in, not their {onboardingRole === 'student' ? 'parent' : 'student'}&apos;s email address.
                        </Typography>
                      </Box>
                    )}
                  </EnhancedAlert>
                )}

                <section className="section">
                  {!hasSsoCode && (
                    <>
                      <CustomInput
                        autoComplete="email"
                        label="Email"
                        name="email"
                        onChangeValue={onUpdateEmail}
                        rootStyles="marginPlus"
                      />

                      <CustomInput
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="large"
                              >
                                {showPassword ? <VisiblityIcon /> : <VisiblityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="new-password"
                        label="Password"
                        name="password"
                        rootStyles="marginPlus"
                        type={showPassword ? 'text' : 'password'}
                      />
                      <CustomInput
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="large"
                              >
                                {showConfirmPassword
                                  ? <VisiblityIcon />
                                  : <VisiblityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="new-password"
                        label="Confirm password"
                        name="confirmPassword"
                        rootStyles="marginPlus"
                        type={showConfirmPassword ? 'text' : 'password'}
                      />
                    </>
                  )}

                  <Box mx={1}>
                    <CustomCheckbox
                      label={(
                        <>
                          I have read and agree to the {APP_NAME}{' '}
                          <StyledLink
                            href={PRIVACY_POLICY_HREF}
                            openInNewTab
                          >
                            Privacy Policy
                          </StyledLink>{' '}
                          and{' '}
                          <StyledLink
                            href={TERMS_OF_SERVICE_HREF}
                            openInNewTab
                          >
                            Terms of Service
                          </StyledLink>.
                        </>
                      )}
                      name="hasAcceptedTerms"
                    />
                  </Box>

                  <div hidden>
                    <label htmlFor="hasHighGraceNotes">
                      sign-up-hasHighGraceNotes
                      <input
                        id="hasHighGraceNotes"
                        onChange={(e) => setHasHighGraceNotesValue(e.target.value)}
                        value={hasHighGraceNotesValue}
                      />
                    </label>
                  </div>
                </section>

                <Collapse in={Boolean(submitError)}>
                  <EnhancedAlert
                    severity="error"
                    sx={{ marginY: 1.5 }}
                  >
                    {submitError}
                  </EnhancedAlert>
                </Collapse>

                <Box
                  marginTop={1}
                  textAlign="center"
                >
                  <CustomFormButton
                    buttonText="Create User Profile"
                    className="button"
                    disabled={isSubmitting}
                    key="signup-register-button"
                    labelClasses="buttonGroupText"
                    size="large"
                  />
                </Box>
              </StyledForm>
            </Fade>

            <ConfirmationDialog
              buttonSize={isMediumScreen ? 'large' : 'medium'}
              confirmButtonAction={handleSubmitFormikFormInConfirmationDialog}
              confirmButtonText="Yes, Create"
              declineButtonAction={handleClose}
              description={(
                <>
                  <Typography
                    color="textSecondary"
                    paragraph
                  >
                    Looking good! Please make sure your info is correct:{' '}
                  </Typography>

                  <Box marginBottom={2}>
                    <StyledDialogEnhancedCard>
                      <CardContent>
                        <ShowPageDataDisplay
                          label="Role"
                          value={prettyRole}
                        />
                        <ShowPageDataDisplay
                          label="Email"
                          value={(classLinkEmail || email).toLowerCase()}
                        />
                      </CardContent>
                    </StyledDialogEnhancedCard>
                  </Box>

                  <Typography color="textSecondary">
                    If this all looks right to you, let&apos;s create
                    your user profile and finish setting it up.
                  </Typography>
                </>
              )}
              fullScreen={isMediumScreen}
              handleClose={handleClose}
              isSubmitting={isSubmitting}
              maxWidth="xs"
              onExited={handleClearPayload}
              open={isOpen}
              title={`Create ${startCase(prettyRole.toLowerCase())} User Profile?`}
              useCustomText
            />
          </>
        );
      }}
    </Formik>
  );
};

export default CreateUserProfileForm;
