// External Dependencies
import Box from '@mui/material/Box';

// Local Dependencies
import SignupAppBar from './SignupAppBar';

// Local Typings
interface Props {
  children: React.ReactNode;
}

// Component Definition
const SignupContainer = ({ children }: Props): JSX.Element => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      width="100%"
    >
      <SignupAppBar />

      {children}
    </Box>
  );
};

export default SignupContainer;
