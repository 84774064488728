// External Dependencies
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import FolderIcon from '@mui/icons-material/Folder';

// Internal Dependencies
import { Flex, TableDataGrid } from 'components/shared';
import { PATHS } from 'utils/constants/routes';
import { hasPermission } from 'state/self/selectors';
import { useGetFilesDirectoriesIndex } from 'gql/queries';
import DataGridContainer from 'components/shared/TableDataGrid/DataGridContainer';
import TableDataGridZeroState from 'components/shared/TableDataGrid/TableDataGridZeroState';
import type { DataGridColDef } from 'types/dataGrid';

// Component Definition
const FileDirectoriesTable = (): JSX.Element => {
  const theme = useTheme();

  const canWriteFiles = useSelector(hasPermission('s3Uploads', 'write'));

  const {
    data,
    isLoading,
  } = useGetFilesDirectoriesIndex();

  // Render the directory cell with an icon
  const directoryAccessor = ({
    directory,
  }: GQL.IFileDirectoryIndexItem) => (
    <Flex>
      <FolderIcon
        fontSize="small"
        htmlColor={theme.palette.grey[500]}
        sx={{ mr: 1.5 }}
      />

      {directory}
    </Flex>
  );

  const columns: DataGridColDef<GQL.IFileDirectoryIndexItem>[] = [
    {
      field: 'directory',
      flex: 1,
      headerName: 'Directory',
      renderCell: (params) => directoryAccessor(params.row),
      sortable: true,
    },
    {
      field: 'fileCount',
      headerName: 'File Count',
      sortable: true,
      width: 150,
    },
  ];

  // Conditionally show the "Files" add button if the user can write files
  const addButtonProps = canWriteFiles
    ? {
      label: 'Files',
      to: `/${PATHS.FILES}/new`,
    }
    : null;

  const handleRowClick = (row: string) =>
    `/${PATHS.FILES}/${row}`;

  return (
    <DataGridContainer>
      <TableDataGrid
        addButtonProps={addButtonProps}
        clickRowTo={handleRowClick}
        columns={columns}
        components={{ NoRowsOverlay: TableDataGridZeroState }}
        loading={isLoading}
        rows={data ?? []}
        tableResource="fileDirectories"
        withSearch
      />
    </DataGridContainer>
  );
};

export default FileDirectoriesTable;
