// External Dependencies
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DialogContentText from '@mui/material/DialogContentText';

// Internal Dependencies
import { PATHS } from 'utils/constants/routes';
import { SHORT_APP_NAME } from 'utils/constants';
import { StyledLink } from 'components/shared/index';
import { isDirector } from 'state/self/selectors';

// Local Dependencies
import ConfirmationDialog from './ConfirmationDialog';

// Local Typings
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

// Component Definition
const DialogDistrictRequired = ({
  isOpen,
  onClose,
}: Props): JSX.Element => {
  const isUserDirector = useSelector(isDirector);

  const districtRequiredDescription = useMemo(() => (
    <>
      <DialogContentText paragraph>
        Your organization is not connected to a {SHORT_APP_NAME} district.
      </DialogContentText>

      {isUserDirector && (
        <DialogContentText paragraph>
          Please
          {' '}
          <StyledLink
            href={`/${PATHS.SETTINGS}#invite-district-admin-form`}
          >
            invite your district administrator
          </StyledLink>
          {' '}
          to Presto to enable this feature.
        </DialogContentText>
      )}

      {!isUserDirector && (
        <DialogContentText paragraph>
          Please contact a director to assist.
          {' '}
          A director can invite an administrator from the settings page.
        </DialogContentText>
      )}
    </>
  ), [isUserDirector]);

  return (
    <ConfirmationDialog
      confirmButtonAction={onClose}
      description={districtRequiredDescription}
      handleClose={onClose}
      hideDeclineButton
      open={isOpen}
      title="District Required"
      useCustomText
    />
  );
};

export default DialogDistrictRequired;
