import { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';

import { initializeHelpScout } from 'utils/helpScout';
import { selectIsParentWithNoPermissions } from 'state/self/selectors';
import { useGetUserOrgRole } from 'hooks/useGetUserOrgRole';

// Local Typings
interface Params {
  isActingAsAnotherUser: boolean;
  self: GQL.ISelf | null;
  version: string;
}

export const useHelpScoutBeacon = ({
  isActingAsAnotherUser,
  self,
  version,
}: Params) => {
  const userOrgRole = useGetUserOrgRole();

  const isParentWithNoPermissions = useSelector(selectIsParentWithNoPermissions);

  const canViewHelpScoutBeacon = self && userOrgRole
    && userOrgRole !== 'Student'
    && !isParentWithNoPermissions
    && !isActingAsAnotherUser;

  // Show the HelpScout Beacon to authenticated users but not alias users.
  // Also, exclude students from seeing the Beacon.
  useLayoutEffect(() => {
    if (canViewHelpScoutBeacon && !window.Beacon) {
      initializeHelpScout(self, userOrgRole);
    }
  }, [canViewHelpScoutBeacon, self, userOrgRole]);

  useLayoutEffect(() => {
    if (canViewHelpScoutBeacon && window.Beacon) {
      // Log the beacon info to help us debug beacon issues while signed in as a user
      console.log('window.Beacon(\'info\')', window.Beacon('info'));

      // Adjust the layout for the HelpScout Beacon widget to
      //  avoid covering up UI in the bottom-right corner
      window.Beacon('config', {
        display: {
          horizontalMobileOffset: 8,
          horizontalOffset: 16,
          verticalMobileOffset: 8,
          verticalOffset: 16,
        },
      });

      // The session data is additional metadata used to provide more information
      // https://developer.helpscout.com/beacon-2/web/javascript-api/#beacon-session-data
      window.Beacon('session-data', {
        'Web app version': version,
      });
    }
  // We need to check if window.Beacon exists because
  //  it is injected by the HelpScout script asynchronously.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewHelpScoutBeacon, self, userOrgRole, window.Beacon]);
};
