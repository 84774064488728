// Internal Dependencies
import { Page } from 'components/shared';
import { useParsedSearch } from 'hooks/useParsedSearch';

// Local Dependencies
import ClassLinkImportClasses from './Classes';
import ClassLinkImportSchools from './Schools';
import ClassLinkImportStudentsForClass from './StudentsForClass';

const ClassLinkImport = (): JSX.Element => {
  const {
    classId,
    schoolId,
  } = useParsedSearch();

  return (
    <Page noMargin>
      {!schoolId && <ClassLinkImportSchools />}
      {schoolId && !classId && <ClassLinkImportClasses schoolId={schoolId} />}
      {schoolId && classId && (
        <ClassLinkImportStudentsForClass
          classId={classId}
          schoolId={schoolId}
        />
      )}
    </Page>
  );
};

export default ClassLinkImport;
