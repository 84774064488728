// External Dependencies
import { CreateDocumentUploadRequest } from '@presto-assistant/api_types/api/v1/documentUpload';
import {
  PropsWithChildren, useCallback, useMemo, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { DialogForm } from 'components/shared/index';
import { addNotification } from 'state/notifications/actions';
import { useCreateDocumentUpload } from 'utils/api/documentUploads';
import CreateDocumentUploadDialogContent
  from 'components/shared/DocumentHub/CreateDocumentUploadDialogContent';

// Local Typings
interface Props {
  idRef: string;
  isOpen: boolean;
  metadata: CreateDocumentUploadRequest['body']['metadata'];
  onClose: () => void;
  tableRef: 'inventory_repairs';
}

// Component Definition
const CreateDocumentUploadDialog = ({
  idRef,
  isOpen,
  metadata,
  onClose,
  tableRef,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();

  const queryClient = useQueryClient();

  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const {
    isLoading: isCreatingDocumentUpload,
    mutate: createDocumentUpload,
  } = useCreateDocumentUpload();

  const initialValues = useMemo<CreateDocumentUploadRequest['body']>(() => {
    return {
      comments: '',
      description: '',
      filename: '',
      idRef,
      metadata,
      s3Url: '',
      tableRef,
    };
  }, [
    idRef,
    metadata,
    tableRef,
  ]);

  const handleSubmit = useCallback(async (values: CreateDocumentUploadRequest['body']) => {
    createDocumentUpload(values, {
      onSuccess: () => {
        dispatch(addNotification('Document uploaded successfully!', 'success'));
        onClose();
        queryClient.invalidateQueries(['documentUploads', tableRef, idRef]);
      },
    });
  }, [
    createDocumentUpload,
    dispatch,
    idRef,
    onClose,
    queryClient,
    tableRef,
  ]);

  return (
    <DialogForm<CreateDocumentUploadRequest['body']>
      dialogProps={{
        fullWidth: true,
        maxWidth: 'md',
        open: isOpen,
      }}
      initialValues={initialValues}
      isSubmitting={isCreatingDocumentUpload || isUploadingFile}
      nonRequiredValues={['comments', 'description']}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Create Document Upload"
    >
      <CreateDocumentUploadDialogContent
        idRef={idRef}
        isUploadingFile={isUploadingFile}
        setIsUploadingFile={setIsUploadingFile}
        tableRef={tableRef}
      />
    </DialogForm>
  );
};

export default CreateDocumentUploadDialog;
