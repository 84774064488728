// External Dependencies
import { FC, useState } from 'react';
import {
  Form,
  Formik,
} from 'formik';
import { Genders } from '@presto-assistant/api_types';
import { updateOnboardingProfileSchema } from '@presto-assistant/api_types/schemas/self';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

// Internal Dependencies
import {
  Alert,
  CustomFormButton,
  CustomInput,
  DatePickerField,
  GenderSelect,
  OnboardingStepTitle,
  SpokenLanguageSelect,
} from 'components/shared';
import { GET_SELF } from 'gql/queries';
import { ROLES } from 'utils/constants';
import { UPDATE_ONBOARDING_PROFILE } from 'gql/mutations';
import { getDefaultErrorMessage } from 'utils/lib/graphql_errors';
import { trimValues } from 'utils/lib/trimValues';
import { useMutationEnhanced } from 'utils/lib/graphql';
import useSelfQuery from 'hooks/useSelfQuery';

// Local Dependencies
import { useClassLinkTokenInfo } from 'utils/api/auth';
import OnboardingProgress from './OnboardingProgress';

// Local Typings
interface OnboardingProfileValues {
  dateOfBirth?: string;
  firstName: string;
  genderId?: string;
  lastName: string;
  middleName?: string;
  otherGenderLabel: string;
  primarySpokenLanguageId?: string;
  roleId: string; // this is used to validate the DOB in the yup schema. not rendered in UI
}

// Local Variables
const StyledCardContent = styled(CardContent)(({ theme }) => ({
  '&:last-child': {
    paddingBottom: theme.spacing(1.5),
  },
  '.button': {
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
    },
    margin: '24px 0 0',
  },
  '.buttonText': {
    [theme.breakpoints.down('md')]: {
      fontSize: '1em',
    },
    fontSize: '1.1em',
    textTransform: 'none',
  },
  '.description': {
    [theme.breakpoints.down('md')]: {
      fontSize: '0.9em',
      margin: theme.spacing(1.5),
    },
    margin: theme.spacing(2),
  },
  '.marginPlus': {
    margin: '12px 0',
  },
  '.section': {
    textAlign: 'left',
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
  },
}));

// Component Definition
const OnboardingUserProfileInfo: FC = () => {
  const [submitError, setSubmitError] = useState('');

  const { loading, self } = useSelfQuery();

  const isStudent = self?.onboarding.onboardingRole === ROLES.STUDENT.toLowerCase();

  const {
    data: classLinkTokenInfoData,
  } = useClassLinkTokenInfo();

  const [
    updateOnboardingProfile,
    {
      loading: isSubmittingUpdateSelf,
    },
  ] = useMutationEnhanced(
    UPDATE_ONBOARDING_PROFILE,
    {
      onError: (apolloError) => {
        const errorMessage = getDefaultErrorMessage(
          apolloError,
          'There was an error updating profile data. Please try again.',
        );

        setSubmitError(errorMessage);
      },
    },
  );

  if (loading) {
    return <OnboardingProgress />;
  }

  const onboardingProfileInitialValues: OnboardingProfileValues = {
    dateOfBirth: self?.dateOfBirth || '',
    firstName: self?.firstName || classLinkTokenInfoData?.data.myInfo.FirstName || '',
    genderId: self?.gender?.id || '',
    lastName: self?.lastName || classLinkTokenInfoData?.data.myInfo.LastName || '',
    middleName: self?.middleName || classLinkTokenInfoData?.data.userInfo?.middleName || '',
    otherGenderLabel: self?.otherGenderLabel || '',
    primarySpokenLanguageId: self?.primarySpokenLanguage?.id || '1' /* English */,
    roleId: self?.role?.id || '',
  };

  const handleSubmitForm = async (values: OnboardingProfileValues) => {
    const updatedValues = {
      ...values,
      primarySpokenLanguageId: values.primarySpokenLanguageId
        ? parseInt(values.primarySpokenLanguageId, 10)
        : null,
    };

    if (!updatedValues.genderId) {
      delete updatedValues.genderId;
    }
    if (!updatedValues.dateOfBirth) {
      delete updatedValues.dateOfBirth;
    }
    updateOnboardingProfile({
      refetchQueries: [{ query: GET_SELF }],
      variables: { input: trimValues(updatedValues) as GQL.IUpdateSelfInput },
    });
  };

  return (
    <>
      <OnboardingStepTitle title="Profile Info" />

      <Formik
        enableReinitialize
        initialValues={onboardingProfileInitialValues}
        onSubmit={handleSubmitForm}
        validationSchema={updateOnboardingProfileSchema}
      >
        {(formikProps) => {
          const { handleSubmit, isSubmitting, values } = formikProps;

          return (
            <Form onSubmit={handleSubmit}>
              <Grow in={!loading}>
                <StyledCardContent>
                  <Typography className="description">
                    Help us complete your profile information
                  </Typography>

                  <section className="section">

                    <CustomInput
                      label="First Name"
                      name="firstName"
                      required
                    />

                    <CustomInput
                      label="Middle Name"
                      name="middleName"
                    />

                    <CustomInput
                      label="Last Name"
                      name="lastName"
                      required
                    />

                    {!isStudent && (
                      <CustomInput
                        label="Title"
                        name="title"
                        placeholder="Director"
                      />
                    )}

                    <GenderSelect required={isStudent} />

                    <Collapse in={values.genderId === Genders.Other.toString()}>
                      <CustomInput
                        label="Other Gender Label"
                        name="otherGenderLabel"
                      />
                    </Collapse>

                    <SpokenLanguageSelect />

                    <DatePickerField
                      label="Date of Birth"
                      name="dateOfBirth"
                      required={isStudent}
                    />
                  </section>

                  {Boolean(submitError) && <Alert mode="error">{submitError}</Alert>}

                  {isSubmitting || isSubmittingUpdateSelf ? (
                    <CircularProgress
                      className="button"
                      size={24}
                      thickness={6}
                    />
                  ) : (
                    <CustomFormButton
                      buttonText="Update Profile"
                      className="button"
                      disabled={isSubmitting || isSubmittingUpdateSelf}
                      key="onboarding-profile-submit-button"
                      labelClasses="buttonText"
                      size="large"
                    />
                  )}
                </StyledCardContent>
              </Grow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default OnboardingUserProfileInfo;
