// External Dependencies
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { useMemo } from 'react';

// Internal Dependencies
import { displayCell } from 'components/shared/TableV2';

export const useColumns = (
  extraColumns?: GridColDef<GQL.IGroupIndexItem>[],
): GridColDef<GQL.IGroupIndexItem>[] => useMemo(() => {
  const columns: GridColDef<GQL.IGroupIndexItem>[] = [
    {
      field: 'label',
      headerName: 'Name',
      renderCell: (row) => displayCell(row.value),
    },
    {
      field: 'isPrimary',
      headerName: 'Primary',
      type: 'boolean',
    },
    {
      field: 'memberCount',
      headerName: 'Size',
      renderCell: (row) => displayCell(row.value),
    },
    ...(extraColumns ?? []),
  ];

  return columns;
}, [extraColumns]);
